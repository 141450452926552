/* Common Imports */

import React from "react";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";

/* Component Imports */

import { Badge, IconButton, Popover, useTheme } from "@mui/material";
import NotificationContent from "./custom/NotificationContent";

/* Icon Imports */

import { NotificationsNoneOutlined } from "@mui/icons-material";
import { useRouter } from "next/router";
import { fetchNotificationPageThunk, fetchRealtimeNotificationThunk } from "../../../redux-magic/store";

const Notifications = ({
	notification_data,
	profile_context,
	session,
	dispatch,
	new_notification,
}: {
	notification_data: StateInterface["notifications_page"];
	profile_context: StateInterface["profile_context"];
	session: any;
	dispatch: Function;
	new_notification: StateInterface["notification"] | null;
}) => {
	const theme = useTheme();
	const router = useRouter();

	const [loading, setLoading] = React.useState<boolean>(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const handleClick = async (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	React.useEffect(() => {
		if (profile_context !== undefined && profile_context.user_id !== "") {
			const executerFunction = async () => {
				setLoading(true);
				await dispatch(
					fetchNotificationPageThunk({
						sessionId: session ? (session.session_id ? session.session_id : "") : "",
						profile_context_is_business_profile: profile_context
							? profile_context.is_business_profile !== undefined
								? profile_context.is_business_profile
								: false
							: false,
						profile_context_user_id: profile_context ? (profile_context.user_id ? profile_context.user_id : "") : "",
						profile_context_business_profile_id: profile_context
							? profile_context.business_profile_id
								? profile_context.business_profile_id
								: ""
							: "",
						// profile_context_is_business_profile:
						//   profile_context?.is_business_profile,
						// profile_context_user_id: profile_context?.user_id,
						// profile_context_business_profile_id:
						//   profile_context?.business_profile_id,
					}),
				);
				setLoading(false);
			};

			executerFunction();
		}

		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [profile_context]);

	React.useEffect(() => {
		const notificationExists = notification_data.all_notifications?.filter(
			(item: any) => item._id === new_notification?._id,
		);

		if (
			new_notification !== null &&
			notificationExists.length === 0 &&
			new_notification.receiver.is_business_profile === profile_context?.is_business_profile &&
			(new_notification.receiver.is_business_profile
				? new_notification.receiver.business_profile_id === profile_context?.business_profile_id
				: new_notification.receiver.user_id === profile_context?.user_id)
		) {
			dispatch(
				fetchRealtimeNotificationThunk({
					sessionId: session ? (session.session_id ? session.session_id : "") : "",
					notification_id: new_notification._id,
					profile_context_is_business_profile: profile_context
						? profile_context.is_business_profile !== undefined
							? profile_context.is_business_profile
							: false
						: false,
					profile_context_user_id: profile_context ? (profile_context.user_id ? profile_context.user_id : "") : "",
					profile_context_business_profile_id: profile_context
						? profile_context.business_profile_id
							? profile_context.business_profile_id
							: ""
						: "",
				}),
			);
		}

		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [new_notification]);

	return (
		<React.Fragment>
			<IconButton
				id={
					(router.asPath === "/"
						? "home"
						: router.asPath.includes("/search")
							? "search"
							: router.asPath.split("/").filter(Boolean)[0] || "unknown_page") + "_layout_header_Notifications"
				}
				sx={{
					paddingLeft: "0.1rem",
				}}
				color="primary"
				onClick={handleClick}
			>
				<Badge
					badgeContent={
						loading
							? 0
							: notification_data.unread_notifications_count >= 10
								? "9+"
								: notification_data.unread_notifications_count
					}
					color="primary"
				>
					<NotificationsNoneOutlined />
				</Badge>
			</IconButton>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				disableScrollLock={true}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: 265,
				}}
				slotProps={{
					paper: {
						sx: {
							width: "20rem",
							height: "auto",
							borderRadius: "16px",
							margin: "0.5rem 0rem 0rem 0rem",
							backgroundColor: theme.palette.background.paper,
							backgroundImage: "none",
							direction: router.locale === "ar-AE" ? "rtl" : "ltr",
						},
					},
				}}
			>
				<NotificationContent
					notification_data={notification_data.all_notifications}
					dispatch={dispatch}
					loading={loading}
					profile_context={profile_context}
					locale={router.locale ? router.locale : "en-IN"}
				/>
			</Popover>
		</React.Fragment>
	);
};
export default Notifications;
