/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import {
	setCreateNewServiceMaxPriceThunk,
	setCreateNewServiceMinPriceThunk,
	setCreateNewServiceOtherSubTypeThunk,
	setCreateNewServiceShowPriceThunk,
	setCreateNewServiceSubTypeThunk,
	setCreateNewServiceTypeThunk,
} from "../../../../../redux-magic/store";
import StateInterface from "../../../../../redux-magic/state-interface";
import { SearchFilterOptionText } from "../../../../../redux-magic/sub-interfaces/search-types/search-filter-option";

/* Component Imports */

import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	useTheme,
} from "@mui/material";
import router from "next/router";

/* Function Imports */

import CurrencyToNumberPipe from "../../../../../lib/CurrencyToNumberPipe";
import NumberToCurrencyPipe from "../../../../../lib/NumberToCurrencyPipe";
import PreparePriceString from "../../../../../lib/PreparePriceString";

/* Styled Components */

const PaperContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	borderRadius: "8px",
	gap: "1rem",
	overflow: "auto",
	paddingTop: "1rem",
	scrollbarWidth: "none", // Hide the scrollbar for firefox
	"&::-webkit-scrollbar": {
		display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
	},
	"&-ms-overflow-style:": {
		display: "none", // Hide the scrollbar for IE
	},
	[theme.breakpoints.down("sm")]: {
		width: "100%",
	},
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	gap: "0.6rem",
	width: "100%",
	// padding: "0.25rem 0rem 0.25rem 0rem",
}));

const ShowPriceDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	color: theme.palette.text.secondary,
}));

const ServiceDetails = ({
	newServiceData,
	dispatch,
	serviceTypeError,
	setServiceTypeError,
	serviceSubTypeError,
	setServiceSubTypeError,
	serviceMinPriceError,
	setServiceMinPriceError,
	serviceMaxPriceError,
	setServiceMaxPriceError,
	serviceProviderNameError,
	setServiceProviderNameError,
	setServiceOtherSubTypeError,
	serviceOtherSubTypeError,
}: {
	newServiceData: StateInterface["new_service_data"];
	dispatch: Function;
	serviceTypeError: boolean;
	setServiceTypeError: Function;
	serviceSubTypeError: boolean;
	setServiceSubTypeError: Function;
	serviceMinPriceError: boolean;
	setServiceMinPriceError: Function;
	serviceMaxPriceError: boolean;
	setServiceMaxPriceError: Function;
	serviceProviderNameError: boolean;
	setServiceProviderNameError: Function;
	setServiceOtherSubTypeError: Function;
	serviceOtherSubTypeError: boolean;
}) => {
	const theme = useTheme();

	const [errorOtherSubtype, setErrorOtherSubtype] = React.useState(serviceOtherSubTypeError);

	const [errorMinPrice, setErrorMinPrice] = React.useState<boolean>(false);

	const [errorMaxPrice, setErrorMaxPrice] = React.useState<boolean>(false);

	/* Functions */

	const subtype_options: Array<SearchFilterOptionText> = [];

	const construction_options: Array<SearchFilterOptionText> = [
		{
			id: "civil_contractor",
			title: router.locale === "kn-IN" ? "ಸಿವಿಲ್ ಕಾನ್‌ಟ್ರಾಕ್ಟರ್" : "Civil Contractor",
			value: "civil_contractor",
		},
		{
			id: "masonry_and_concrete",
			title: router.locale === "kn-IN" ? "ಮೇಸನ್ ಮತ್ತು ಕಾಂಕ್ರೀಟ್" : "Masonry and Concrete",
			value: "masonry_and_concrete",
		},
		{
			id: "earth_moving",
			title: router.locale === "kn-IN" ? "ಅರ್ಥ್ ಮೊವೆರ್ಸ್" : "Earth Moving",
			value: "earth_moving",
		},
		{
			id: "demolition",
			title: router.locale === "kn-IN" ? "ಕಟ್ಟಡ ಧ್ವಂಸ" : "Demolition",
			value: "demolition",
		},
		{
			id: "fabrication",
			title: router.locale === "kn-IN" ? "ಫ್ಯಾಬ್ರಿಕೇಶನ್" : "Fabrication",
			value: "fabrication",
		},
		{
			id: "hvac_installation",
			title: router.locale === "kn-IN" ? "HVAC ಸ್ಥಾಪನೆ" : "HVAC Installation",
			value: "hvac_installation",
		},
		{
			id: "electrical",
			title: router.locale === "kn-IN" ? "ವಿದ್ಯುತ್" : "Electrical",
			value: "electrical",
		},
		{
			id: "plumbing",
			title: router.locale === "kn-IN" ? "ಪ್ಲಂಬಿಂಗ್" : "Plumbing",
			value: "plumbing",
		},
		{
			id: "solar_panel_installation",
			title: router.locale === "kn-IN" ? "ಸೋಲಾರ್ ಪ್ಯಾನೆಲ್ ಸ್ಥಾಪನೆ" : "Solar Panel Installation",
			value: "solar_panel_installation",
		},
		{
			id: "borewell_drilling",
			title: router.locale === "kn-IN" ? "ಬೋರ್‌ವೆಲ್ ಡ್ರಿಲ್ಲಿಂಗ್" : "Borewell Drilling",
			value: "borewell_drilling",
		},
		{
			id: "rainwater_harvesting",
			title: router.locale === "kn-IN" ? "ಮಳೆನೀರು ಸಂಗ್ರಹಣೆ" : "Rainwater Harvesting",
			value: "rainwater_harvesting",
		},
		{
			id: "fire_safety",
			title: router.locale === "kn-IN" ? "ಅಗ್ನಿ ಸುರಕ್ಷತೆ" : "Fire Safety",
			value: "fire_safety",
		},
		{
			id: "structural_engineering",
			title: router.locale === "kn-IN" ? "ಸಂರಚನಾ ಇಂಜಿನಿಯರಿಂಗ್" : "Structural Engineering",
			value: "structural_engineering",
		},
		{
			id: "architecture",
			title: router.locale === "kn-IN" ? "ವಾಸ್ತುಶಿಲ್ಪ" : "Architecture",
			value: "architecture",
		},
		{
			id: "painting",
			title: router.locale === "kn-IN" ? "ಚಿತ್ರಕಲೆ" : "Painting",
			value: "painting",
		},
		{
			id: "carpentry_or_woodwork",
			title: router.locale === "kn-IN" ? "ಕಾರುಪೆಂಟ್ರಿ / ವುಡ್‌ವರ್ಕ್" : "Carpentry / Woodwork",
			value: "carpentry_or_woodwork",
		},
		{
			id: "facade_and_exterior_panels",
			title: router.locale === "kn-IN" ? "ಫಸಾಡ್ ಮತ್ತು ಎಕ್ಸ್‌ಟೀರಿಯರ್ ಪ್ಯಾನೆಲ್‌ಗಳು" : "Facade and Exterior Panels",
			value: "facade_and_exterior_panels",
		},
		{
			id: "vaastu_consultant",
			title: router.locale === "kn-IN" ? "ವಾಸ್ತು ಸಲಹೆಗಾರ" : "Vaastu Consultant",
			value: "vaastu_consultant",
		},
		{
			id: "material_supply",
			title: router.locale === "kn-IN" ? "ವಸ್ತು ಪೂರೈಕೆ" : "Material Supply",
			value: "material_supply",
		},
		{
			id: "landscaping",
			title: router.locale === "kn-IN" ? "ಲ್ಯಾಂಡ್‌ಸ್ಕೇಪಿಂಗ್" : "Landscaping",
			value: "landscaping",
		},
		{
			id: "pool_consultants",
			title: router.locale === "kn-IN" ? "ಪೂಲ್ ಸಲಹೆಗಾರರು" : "Pool Consultants",
			value: "pool_consultants",
		},
		{
			id: "sewage_treatment_plant",
			title: router.locale === "kn-IN" ? "ಮಲಿನಜಲ ಶುದ್ಧೀಕರಣ ಘಟಕ" : "Sewage Treatment Plant",
			value: "sewage_treatment_plant",
		},
		{
			id: "lift_installation",
			title: router.locale === "kn-IN" ? "ಲಿಫ್ಟ್ ಸ್ಥಾಪನೆ" : "Lift Installation",
			value: "lift_installation",
		},
		{
			id: "dg_installation",
			title: router.locale === "kn-IN" ? "ಡಿಜಿ ಸ್ಥಾಪನೆ" : "DG Installation",
			value: "dg_installation",
		},
		{
			id: "other",
			title: router.locale === "kn-IN" ? "ಇತರೆ" : "Other",
			value: "other",
		},
	];

	const cleaning_and_maintenance_options: Array<SearchFilterOptionText> = [
		{
			id: "deep_cleaning",
			title: router.locale === "kn-IN" ? "ಆಳವಾದ ಸ್ವಚ್ಛತೆ" : "Deep Cleaning",
			value: "deep_cleaning",
		},
		{
			id: "kitchen_and_bathroom_cleaning",
			title: router.locale === "kn-IN" ? "ಅಡಿಗೆಮನೆ ಮತ್ತು ಬಾತ್‌ರೂಮ್ ಸ್ವಚ್ಛತೆ" : "Kitchen and Bathroom Cleaning",
			value: "kitchen_and_bathroom_cleaning",
		},
		{
			id: "pest_control",
			title: router.locale === "kn-IN" ? "ಹುಳುಹುಪ್ಪಟೆ ನಿಯಂತ್ರಣ" : "Pest Control",
			value: "pest_control",
		},
		{
			id: "laundry_and_dry_cleaning",
			title: router.locale === "kn-IN" ? "ಲಾಂಡ್ರಿ ಮತ್ತು ಡ್ರೈ ಕ್ಲೀನಿಂಗ್" : "Laundry and Dry Cleaning",
			value: "laundry_and_dry_cleaning",
		},
		{
			id: "exterior_cleaning",
			title: router.locale === "kn-IN" ? "ಹೊರಾಂಗಣ ಸ್ವಚ್ಛತೆ" : "Exterior Cleaning",
			value: "exterior_cleaning",
		},
		{
			id: "sofa_cleaning",
			title: router.locale === "kn-IN" ? "ಸೋಫಾ ಸ್ವಚ್ಛತೆ" : "Sofa Cleaning",
			value: "sofa_cleaning",
		},
		{
			id: "car_wash",
			title: router.locale === "kn-IN" ? "ಕಾರ್ ವಾಶ್" : "Car Wash",
			value: "car_wash",
		},
		{
			id: "sneaker_care",
			title: router.locale === "kn-IN" ? "ಸ್ನೀಕರ್ ಕೇರ್" : "Sneaker Care",
			value: "sneaker_care",
		},
		{
			id: "pool_maintenance",
			title: router.locale === "kn-IN" ? "ಪೂಲ್ ನಿರ್ವಹಣೆ" : "Pool Maintenance",
			value: "pool_maintenance",
		},
		{
			id: "gardening",
			title: router.locale === "kn-IN" ? "ತೋಟಗಾರಿಕೆ" : "Gardening",
			value: "gardening",
		},
		{
			id: "carpet_cleaning",
			title: router.locale === "kn-IN" ? "ಕಾರ್ಪೆಟ್ ಸ್ವಚ್ಛತೆ" : "Carpet Cleaning",
			value: "carpet_cleaning",
		},
		{
			id: "water_sump_and_tank_cleaning",
			title: router.locale === "kn-IN" ? "ನೀರು ಸಂಪ್ ಮತ್ತು ಟ್ಯಾಂಕ್ ಸ್ವಚ್ಛತೆ" : "Water Sump and Tank Cleaning",
			value: "water_sump_and_tank_cleaning",
		},
		{
			id: "granite_polish",
			title: router.locale === "kn-IN" ? "ಗ್ರಾನೈಟ್ ಪಾಲಿಶ್" : "Granite Polish",
			value: "granite_polish",
		},
		{
			id: "tiles_acid_wash",
			title: router.locale === "kn-IN" ? "ಟೈಲ್ಸ್ ಆಮ್ಲ ವಾಶ್" : "Tiles Acid Wash",
			value: "tiles_acid_wash",
		},
		{
			id: "wood_polish",
			title: router.locale === "kn-IN" ? "ಮರದ ಪಾಲಿಶ್" : "Wood Polish",
			value: "wood_polish",
		},
		{
			id: "acp_sheet_cleaning",
			title: router.locale === "kn-IN" ? "ಎಸಿಪಿ ಶೀಟ್ ಸ್ವಚ್ಛತೆ" : "ACP Sheet Cleaning",
			value: "acp_sheet_cleaning",
		},
		{
			id: "other",
			title: router.locale === "kn-IN" ? "ಇತರೆ" : "Other",
			value: "other",
		},
	];

	const interiors_and_decor_options: Array<SearchFilterOptionText> = [
		{
			id: "interior_design",
			title: router.locale === "kn-IN" ? "ಆಂತರಿಕ ವಿನ್ಯಾಸ" : "Interior Design",
			value: "interior_design",
		},
		{
			id: "interior_painting",
			title: router.locale === "kn-IN" ? "ಆಂತರಿಕ ಚಿತ್ರಕಲೆ" : "Interior Painting",
			value: "interior_painting",
		},
		{
			id: "wallpaper_installation",
			title: router.locale === "kn-IN" ? "ವಾಲ್‌ಪೇಪರ್ ಸ್ಥಾಪನೆ" : "Wallpaper Installation",
			value: "wallpaper_installation",
		},
		{
			id: "flooring",
			title: router.locale === "kn-IN" ? "ನೆಲಹಾಸು" : "Flooring",
			value: "flooring",
		},
		{
			id: "home_automation",
			title: router.locale === "kn-IN" ? "ಮನೆ ಸ್ವಯಂಚಾಲಿತ" : "Home Automation",
			value: "home_automation",
		},
		{
			id: "furniture",
			title: router.locale === "kn-IN" ? "ಫರ್ನಿಚರ್" : "Furniture",
			value: "furniture",
		},
		{
			id: "false_ceiling",
			title: router.locale === "kn-IN" ? "ತಪ್ಪು ಸೀಲಿಂಗ್" : "False Ceiling",
			value: "false_ceiling",
		},
		{
			id: "electrical_fixtures",
			title: router.locale === "kn-IN" ? "ವಿದ್ಯುತ್ ಫಿಕ್ಸ್ಚರ್‌ಗಳು" : "Electrical Fixtures",
			value: "electrical_fixtures",
		},
		{
			id: "lighting",
			title: router.locale === "kn-IN" ? "ಲೈಟಿಂಗ್" : "Lighting",
			value: "lighting",
		},
		{
			id: "bathroom_interiors",
			title: router.locale === "kn-IN" ? "ಬಾತ್‌ರೂಮ್ ಆಂತರಿಕ" : "Bathroom Interiors",
			value: "bathroom_interiors",
		},
		{
			id: "partitions",
			title: router.locale === "kn-IN" ? "ವಿಭಾಗಗಳು" : "Partitions",
			value: "partitions",
		},
		{
			id: "blinds_and_curtains",
			title: router.locale === "kn-IN" ? "ಬ್ಲೈಂಡ್ಸ್ ಮತ್ತು ಪರದೆಗಳು" : "Blinds and Curtains",
			value: "blinds_and_curtains",
		},
		{
			id: "carpentry_or_woodwork",
			title: router.locale === "kn-IN" ? "ಕಾರುಪೆಂಟ್ರಿ / ವುಡ್‌ವರ್ಕ್" : "Carpentry / Woodwork",
			value: "carpentry_or_woodwork",
		},
		{
			id: "wardrobe",
			title: router.locale === "kn-IN" ? "ಅಲಮಾರಿಗಳು" : "Wardrobe",
			value: "wardrobe",
		},
		{
			id: "fabrication",
			title: router.locale === "kn-IN" ? "ಫ್ಯಾಬ್ರಿಕೇಶನ್" : "Fabrication",
			value: "fabrication",
		},
		{
			id: "modular_kitchen",
			title: router.locale === "kn-IN" ? "ಮಾಡ್ಯುಲರ್ ಕಿಚನ್" : "Modular Kitchen",
			value: "modular_kitchen",
		},
		{
			id: "photo_and_art_framing",
			title: router.locale === "kn-IN" ? "ಫೋಟೋ ಮತ್ತು ಕಲೆ ಫ್ರೇಮಿಂಗ್" : "Photo and Art Framing",
			value: "photo_and_art_framing",
		},
		{
			id: "other",
			title: router.locale === "kn-IN" ? "ಇತರೆ" : "Other",
			value: "other",
		},
	];

	const home_utilities_options: Array<SearchFilterOptionText> = [
		{
			id: "lpg",
			title: router.locale === "kn-IN" ? "ಎಲ್‌ಪಿಜಿ" : "LPG",
			value: "lpg",
		},
		{
			id: "internet_and_networking",
			title: router.locale === "kn-IN" ? "ಇಂಟರ್ನೆಟ್ ಮತ್ತು ನೆಟ್ವರ್ಕಿಂಗ್" : "Internet and Networking",
			value: "internet_and_networking",
		},
		{
			id: "water_supply",
			title: router.locale === "kn-IN" ? "ನೀರಿನ ಪೂರೈಕೆ" : "Water Supply",
			value: "water_supply",
		},
		{
			id: "dish_and_cable",
			title: router.locale === "kn-IN" ? "ಡಿಶ್ ಮತ್ತು ಕೇಬಲ್" : "Dish and Cable",
			value: "dish_and_cable",
		},
		{
			id: "other",
			title: router.locale === "kn-IN" ? "ಇತರೆ" : "Other",
			value: "other",
		},
	];

	const daily_home_care_and_security_options: Array<SearchFilterOptionText> = [
		{
			id: "cook",
			title: router.locale === "kn-IN" ? "ಅಡುಗೆ" : "Cook",
			value: "cook",
		},
		{
			id: "maid_or_housekeeping",
			title: router.locale === "kn-IN" ? "ಮಾಲಿನ್ಯ / ಗೃಹಪಾಲನೆ" : "Maid / Housekeeping",
			value: "maid_or_housekeeping",
		},
		{
			id: "groceries_delivery",
			title: router.locale === "kn-IN" ? "ಸರಕುಗಳ ವಿತರಣೆ" : "Groceries Delivery",
			value: "groceries_delivery",
		},
		{
			id: "newspaper_and_magazine_delivery",
			title: router.locale === "kn-IN" ? "ವಾರ್ತಾಪತ್ರಿಕೆ ಮತ್ತು ಮಾಸಪತ್ರಿಕೆ ವಿತರಣೆ" : "Newspaper and Magazine Delivery",
			value: "newspaper_and_magazine_delivery",
		},
		{
			id: "security_services",
			title: router.locale === "kn-IN" ? "ಭದ್ರತಾ ಸೇವೆಗಳು" : "Security Services",
			value: "security_services",
		},
		{
			id: "cctv",
			title: router.locale === "kn-IN" ? "ಸಿಸಿಟಿವಿ" : "CCTV",
			value: "cctv",
		},
		{
			id: "nanny",
			title: router.locale === "kn-IN" ? "ನ್ಯಾನಿ" : "Nanny",
			value: "nanny",
		},
		{
			id: "gardening_and_landscaping",
			title: router.locale === "kn-IN" ? "ತೋಟಗಾರಿಕೆ ಮತ್ತು ಲ್ಯಾಂಡ್‌ಸ್ಕೇಪಿಂಗ್" : "Gardening and Landscaping",
			value: "gardening_and_landscaping",
		},
		{
			id: "other",
			title: router.locale === "kn-IN" ? "ಇತರೆ" : "Other",
			value: "other",
		},
	];

	const home_and_appliance_repair_options: Array<SearchFilterOptionText> = [
		{
			id: "ac_repair",
			title: router.locale === "kn-IN" ? "ಎಸಿ ರಿಪೇರಿ" : "AC Repair",
			value: "ac_repair",
		},
		{
			id: "refrigerator_repair",
			title: router.locale === "kn-IN" ? "ಫ್ರಿಜ್ ರಿಪೇರಿ" : "Refrigerator Repair",
			value: "refrigerator_repair",
		},
		{
			id: "washing_machine_repair",
			title: router.locale === "kn-IN" ? "ವಾಷಿಂಗ್ ಮೆಷಿನ್ ರಿಪೇರಿ" : "Washing Machine Repair",
			value: "washing_machine_repair",
		},
		{
			id: "stove_repair",
			title: router.locale === "kn-IN" ? "ಸ್ಟೋವ್ ರಿಪೇರಿ" : "Stove Repair",
			value: "stove_repair",
		},
		{
			id: "microwave_repair",
			title: router.locale === "kn-IN" ? "ಮೈಕ್ರೋವೇವ್ ರಿಪೇರಿ" : "Microwave Repair",
			value: "microwave_repair",
		},
		{
			id: "tv_repair",
			title: router.locale === "kn-IN" ? "ಟಿವಿ ರಿಪೇರಿ" : "TV Repair",
			value: "tv_repair",
		},
		{
			id: "water_purifier_repair",
			title: router.locale === "kn-IN" ? "ನೀರು ಶುದ್ಧೀಕರಕ ರಿಪೇರಿ" : "Water Purifier Repair",
			value: "water_purifier_repair",
		},
		{
			id: "geyser_repair",
			title: router.locale === "kn-IN" ? "ಗೀಸರ್ ರಿಪೇರಿ" : "Geyser Repair",
			value: "geyser_repair",
		},
		{
			id: "computer_repair",
			title: router.locale === "kn-IN" ? "ಕಂಪ್ಯೂಟರ್ ರಿಪೇರಿ" : "Computer Repair",
			value: "computer_repair",
		},
		{
			id: "mobile_repair",
			title: router.locale === "kn-IN" ? "ಮೊಬೈಲ್ ರಿಪೇರಿ" : "Mobile Repair",
			value: "mobile_repair",
		},
		{
			id: "watch_repair",
			title: router.locale === "kn-IN" ? "ಗಡಿಯಾರ ರಿಪೇರಿ" : "Watch Repair",
			value: "watch_repair",
		},
		{
			id: "chimney_repair",
			title: router.locale === "kn-IN" ? "ಚಿಮ್ನಿ ರಿಪೇರಿ" : "Chimney Repair",
			value: "chimney_repair",
		},
		{
			id: "mixer_and_grinder_repair",
			title: router.locale === "kn-IN" ? "ಮಿಕ್ಸರ್ ಮತ್ತು ಗ್ರೈಂಡರ್ ರಿಪೇರಿ" : "Mixer and Grinder Repair",
			value: "mixer_and_grinder_repair",
		},
		{
			id: "lift_repair",
			title: router.locale === "kn-IN" ? "ಲಿಫ್ಟ್ ರಿಪೇರಿ" : "Lift Repair",
			value: "lift_repair",
		},
		{
			id: "solar_panel_repair",
			title: router.locale === "kn-IN" ? "ಸೋಲಾರ್ ಪ್ಯಾನೆಲ್ ರಿಪೇರಿ" : "Solar Panel Repair",
			value: "solar_panel_repair",
		},
		{
			id: "dg_repair",
			title: router.locale === "kn-IN" ? "ಡಿಜಿ ರಿಪೇರಿ" : "DG Repair",
			value: "dg_repair",
		},
		{
			id: "borewell_and_motor_repair",
			title: router.locale === "kn-IN" ? "ಬೋರ್‌ವೆಲ್ ಮತ್ತು ಮೋಟಾರ್ ರಿಪೇರಿ" : "Borewell and Motor Repair",
			value: "borewell_and_motor_repair",
		},
		{
			id: "electrical_repair",
			title: router.locale === "kn-IN" ? "ವಿದ್ಯುತ್ ರಿಪೇರಿ" : "Electrical Repair",
			value: "electrical_repair",
		},
		{
			id: "plumbing_repair",
			title: router.locale === "kn-IN" ? "ಪ್ಲಂಬಿಂಗ್ ರಿಪೇರಿ" : "Plumbing Repair",
			value: "plumbing_repair",
		},
		{
			id: "carpentry_or_woodwork_repair",
			title: router.locale === "kn-IN" ? "ಕಾರುಪೆಂಟ್ರಿ / ವುಡ್‌ವರ್ಕ್ ರಿಪೇರಿ" : "Carpentry / Woodwork Repair",
			value: "carpentry_or_woodwork_repair",
		},
		{
			id: "roof_repair",
			title: router.locale === "kn-IN" ? "ಮೂಡು ರಿಪೇರಿ" : "Roof Repair",
			value: "roof_repair",
		},
		{
			id: "window_repair",
			title: router.locale === "kn-IN" ? "ಕಿಟಕಿ ರಿಪೇರಿ" : "Window Repair",
			value: "window_repair",
		},
		{
			id: "electronics_repair",
			title: router.locale === "kn-IN" ? "ಎಲೆಕ್ಟ್ರಾನಿಕ್ಸ್ ರಿಪೇರಿ" : "Electronics Repair",
			value: "electronics_repair",
		},
		{
			id: "knife_and_scissors_sharpening",
			title: router.locale === "kn-IN" ? "ಚಾಕು ಮತ್ತು ಕತ್ತರಿ ಹಾಸು" : "Knife and Scissors Sharpening",
			value: "knife_and_scissors_sharpening",
		},
		{
			id: "jewellery_repair",
			title: router.locale === "kn-IN" ? "ಆಭರಣ ರಿಪೇರಿ" : "Jewellery Repair",
			value: "jewellery_repair",
		},
		{
			id: "other",
			title: router.locale === "kn-IN" ? "ಇತರೆ" : "Other",
			value: "other",
		},
	];

	const transport_options: Array<SearchFilterOptionText> = [
		{
			id: "packers_and_movers",
			title: router.locale === "kn-IN" ? "ಪ್ಯಾಕರ್ಸ್ ಮತ್ತು ಮೂವರ್ಸ್" : "Packers and Movers",
			value: "packers_and_movers",
		},
		{
			id: "tempo_and_truck_hire",
			title: router.locale === "kn-IN" ? "ಟೆಂಪೋ ಮತ್ತು ಟ್ರಕ್ ಹೈರ್" : "Tempo and Truck Hire",
			value: "tempo_and_truck_hire",
		},
		{
			id: "cab_or_taxi",
			title: router.locale === "kn-IN" ? "ಕ್ಯಾಬ್ / ಟ್ಯಾಕ್ಸಿ" : "Cab / Taxi",
			value: "cab_or_taxi",
		},
		{
			id: "car_or_bike_rental",
			title: router.locale === "kn-IN" ? "ಕಾರ್ / ಬೈಕ್ ಬಾಡಿಗೆ" : "Car / Bike Rental",
			value: "car_or_bike_rental",
		},
		{
			id: "car_or_bike_repair",
			title: router.locale === "kn-IN" ? "ಕಾರ್ / ಬೈಕ್ ರಿಪೇರಿ" : "Car / Bike Repair",
			value: "car_or_bike_repair",
		},
		{
			id: "driver",
			title: router.locale === "kn-IN" ? "ಡ್ರೈವರ್" : "Driver",
			value: "driver",
		},
		{
			id: "towing",
			title: router.locale === "kn-IN" ? "ಟೋಯಿಂಗ್" : "Towing",
			value: "towing",
		},
		{
			id: "courier_and_cargo",
			title: router.locale === "kn-IN" ? "ಕೂರಿಯರ್ ಮತ್ತು ಕಾರ್ಗೋ" : "Courier and Cargo",
			value: "courier_and_cargo",
		},
		{
			id: "travel_and_tourism",
			title: router.locale === "kn-IN" ? "ಪ್ರಯಾಣ ಮತ್ತು ಪ್ರವಾಸೋದ್ಯಮ" : "Travel and Tourism",
			value: "travel_and_tourism",
		},
		{
			id: "shipping",
			title: router.locale === "kn-IN" ? "ಶಿಪ್ಪಿಂಗ್" : "Shipping",
			value: "shipping",
		},
		{
			id: "train",
			title: router.locale === "kn-IN" ? "ರೈಲು" : "Train",
			value: "train",
		},
		{
			id: "air_cargo",
			title: router.locale === "kn-IN" ? "ಏರ್ ಕಾರ್ಗೋ" : "Air Cargo",
			value: "air_cargo",
		},
		{
			id: "crane",
			title: router.locale === "kn-IN" ? "ಕ್ರೇನ್" : "Crane",
			value: "crane",
		},
		{
			id: "helicopter",
			title: router.locale === "kn-IN" ? "ಹೆಲಿಕಾಪ್ಟರ್" : "Helicopter",
			value: "helicopter",
		},
		{
			id: "private_jet",
			title: router.locale === "kn-IN" ? "ಪ್ರೈವೇಟ್ ಜೆಟ್" : "Private Jet",
			value: "private_jet",
		},
		{
			id: "bus_rental",
			title: router.locale === "kn-IN" ? "ಬಸ್ ಬಾಡಿಗೆ" : "Bus Rental",
			value: "bus_rental",
		},
		{
			id: "caravan_rental",
			title: router.locale === "kn-IN" ? "ಕಾರವಾನ್ ಬಾಡಿಗೆ" : "Caravan Rental",
			value: "caravan_rental",
		},
		{
			id: "tyre_maintenance",
			title: router.locale === "kn-IN" ? "ಟೈರ್ ನಿರ್ವಹಣೆ" : "Tyre Maintenance",
			value: "tyre_maintenance",
		},
		{
			id: "other",
			title: router.locale === "kn-IN" ? "ಇತರೆ" : "Other",
			value: "other",
		},
	];

	const legal_and_finance_options: Array<SearchFilterOptionText> = [
		{
			id: "legal_consultation",
			title: router.locale === "kn-IN" ? "ಕಾನೂನು ಸಲಹೆ" : "Legal Consultation",
			value: "legal_consultation",
		},
		{
			id: "documentation",
			title: router.locale === "kn-IN" ? "ದಾಖಲೆ" : "Documentation",
			value: "documentation",
		},
		{
			id: "accounting_and_bookkeeping",
			title: router.locale === "kn-IN" ? "ಹಿಸಾಬು ಮತ್ತು ಪುಸ್ತಕದ ಖಾತೆ" : "Accounting and Bookkeeping",
			value: "accounting_and_bookkeeping",
		},
		{
			id: "financial_services",
			title: router.locale === "kn-IN" ? "ಆರ್ಥಿಕ ಸೇವೆಗಳು" : "Financial Services",
			value: "financial_services",
		},
		{
			id: "insurance",
			title: router.locale === "kn-IN" ? "ವಿಮೆ" : "Insurance",
			value: "insurance",
		},
		{
			id: "loan_and_funding",
			title: router.locale === "kn-IN" ? "ಸಾಲ ಮತ್ತು ಹಣಕಾಸು" : "Loan and Funding",
			value: "loan_and_funding",
		},
		{
			id: "tax_filing_and_consultation",
			title: router.locale === "kn-IN" ? "ತೆರಿಗೆ ಸಲ್ಲಿಕೆ ಮತ್ತು ಸಲಹೆ" : "Tax Filing and Consultation",
			value: "tax_filing_and_consultation",
		},
		{
			id: "investment_advisory",
			title: router.locale === "kn-IN" ? "ಹೂಡಿಕೆ ಸಲಹೆ" : "Investment Advisory",
			value: "investment_advisory",
		},
		{
			id: "other",
			title: router.locale === "kn-IN" ? "ಇತರೆ" : "Other",
			value: "other",
		},
	];

	const grooming_and_other_personal_care_options: Array<SearchFilterOptionText> = [
		{
			id: "salon",
			title: router.locale === "kn-IN" ? "ಸಲೂನ್" : "Salon",
			value: "salon",
		},
		{
			id: "spa",
			title: router.locale === "kn-IN" ? "ಸ್ಪಾ" : "Spa",
			value: "spa",
		},
		{
			id: "tailor",
			title: router.locale === "kn-IN" ? "ಟೈಲರ್" : "Tailor",
			value: "tailor",
		},
		{
			id: "optician",
			title: router.locale === "kn-IN" ? "ಆಪ್ಟಿಷಿಯನ್" : "Optician",
			value: "optician",
		},
		{
			id: "stylist",
			title: router.locale === "kn-IN" ? "ಸ್ಟೈಲಿಸ್ಟ್" : "Stylist",
			value: "stylist",
		},
		{
			id: "other",
			title: router.locale === "kn-IN" ? "ಇತರೆ" : "Other",
			value: "other",
		},
	];

	const liaisoning_options: Array<SearchFilterOptionText> = [
		{
			id: "liaisoning",
			title: router.locale === "kn-IN" ? "ಲೈಸನಿಂಗ್" : "Liaisoning",
			value: "liaisoning",
		},
		{
			id: "other",
			title: router.locale === "kn-IN" ? "ಇತರೆ" : "Other",
			value: "other",
		},
	];

	const social_service_options: Array<SearchFilterOptionText> = [
		{
			id: "social_service",
			title: router.locale === "kn-IN" ? "ಸಾಮಾಜಿಕ ಸೇವೆ" : "Social Service",
			value: "social_service",
		},
		{
			id: "other",
			title: router.locale === "kn-IN" ? "ಇತರೆ" : "Other",
			value: "other",
		},
	];

	const food_options: Array<SearchFilterOptionText> = [
		{
			id: "restaurant",
			title: router.locale === "kn-IN" ? "ರೆಸ್ಟೋರೆಂಟ್" : "Restaurant",
			value: "restaurant",
		},
		{
			id: "catering",
			title: router.locale === "kn-IN" ? "ಕೇಟರಿಂಗ್" : "Catering",
			value: "catering",
		},
		{
			id: "other",
			title: router.locale === "kn-IN" ? "ಇತರೆ" : "Other",
			value: "other",
		},
	];

	const hospitality_options: Array<SearchFilterOptionText> = [
		{
			id: "hotels_and_resorts",
			title: router.locale === "kn-IN" ? "ಹೋಟೆಲ್ ಮತ್ತು ರೆಸಾರ್ಟ್ಸ್" : "Hotel and Resorts",
			value: "hotels_and_resorts",
		},
		{
			id: "service_apartments",
			title: router.locale === "kn-IN" ? "ಸರ್ವಿಸ್ ಅಪಾರ್ಟ್ಮೆಂಟ್ಸ್" : "Service Apartments",
			value: "service_apartments",
		},
		{
			id: "home_rentals_long_term",
			title: router.locale === "kn-IN" ? "ಮನೆ ಬಾಡಿಗೆ (ದೀರ್ಘಾವಧಿ)" : "Home Rentals (Long Term)",
			value: "home_rentals_long_term",
		},
		{
			id: "home_rentals_short_term",
			title: router.locale === "kn-IN" ? "ಮನೆ ಬಾಡಿಗೆ (ಕಿರು ಅವಧಿ)" : "Home Rentals (Short Term)",
			value: "home_rentals_short_term",
		},
		{
			id: "other",
			title: router.locale === "kn-IN" ? "ಇತರೆ" : "Other",
			value: "other",
		},
	];

	const other_options: Array<SearchFilterOptionText> = [
		{
			id: "pet_care",
			title: router.locale === "kn-IN" ? "ಪಾಲುಹಚ್ಚುವ ಪ್ರಾಣಿ ಆರೈಕೆ" : "Pet Care",
			value: "pet_care",
		},
		{
			id: "photography_or_videography",
			title: router.locale === "kn-IN" ? "ಫೋಟೋಗ್ರಫಿ / ವೀಡಿಯೋಗ್ರಫಿ" : "Photography / Videography",
			value: "photography_or_videography",
		},
		{
			id: "information_technology_open_parenthesis_it_close_parenthesis",
			title: router.locale === "kn-IN" ? "ಮಾಹಿತಿ ತಂತ್ರಜ್ಞಾನ (IT)" : "Information Technology (IT)",
			value: "information_technology_open_parenthesis_it_close_parenthesis",
		},
		{
			id: "sports_and_fitness",
			title: router.locale === "kn-IN" ? "ಕ್ರೀಡೆ ಮತ್ತು ಫಿಟ್ನೆಸ್" : "Sports and Fitness",
			value: "sports_and_fitness",
		},
		{
			id: "drone_service",
			title: router.locale === "kn-IN" ? "ಡ್ರೋನ್ ಸೇವೆ" : "Drone Service",
			value: "drone_service",
		},
		{
			id: "storage_space",
			title: router.locale === "kn-IN" ? "ಸಂಗ್ರಹಣಾ ಸ್ಥಳ" : "Storage Space",
			value: "storage_space",
		},
		{
			id: "dentist",
			title: router.locale === "kn-IN" ? "ದಂತ ವೈದ್ಯ" : "Dentist",
			value: "dentist",
		},
		{
			id: "tattoo",
			title: router.locale === "kn-IN" ? "ಟ್ಯಾಟೂ" : "Tattoo",
			value: "tattoo",
		},
		{
			id: "event_management",
			title: router.locale === "kn-IN" ? "ಈವೆಂಟ್ ಮ್ಯಾನೇಜ್ಮೆಂಟ್" : "Event Management",
			value: "event_management",
		},
		{
			id: "tuition",
			title: router.locale === "kn-IN" ? "ಟ್ಯೂಷನ್" : "Tuition",
			value: "tuition",
		},
		{
			id: "personal_coaching",
			title: router.locale === "kn-IN" ? "ವೈಯಕ್ತಿಕ ತರಬೇತಿ" : "Personal Coaching",
			value: "personal_coaching",
		},
		{
			id: "cobbler",
			title: router.locale === "kn-IN" ? "ಚರ್ಮಗಾರ" : "Cobbler",
			value: "cobbler",
		},
		{
			id: "tree_trimming_and_maintenance",
			title: router.locale === "kn-IN" ? "ಮರ ಕತ್ತರಿಸುವುದು ಮತ್ತು ನಿರ್ವಹಣೆ" : "Tree Trimming and Maintenance",
			value: "tree_trimming_and_maintenance",
		},
		{
			id: "printing_services",
			title: router.locale === "kn-IN" ? "ಮುದ್ರಣ ಸೇವೆಗಳು" : "Printing Services",
			value: "printing_services",
		},
		{
			id: "astrology",
			title: router.locale === "kn-IN" ? "ಜ್ಯೋತಿಷ್ಯ" : "Astrology",
			value: "astrology",
		},
		{
			id: "religious_services",
			title: router.locale === "kn-IN" ? "ಧಾರ್ಮಿಕ ಸೇವೆಗಳು" : "Religious Services",
			value: "religious_services",
		},
		{
			id: "labour_supply",
			title: router.locale === "kn-IN" ? "ಕಾರ್ಮಿಕ ಪೂರೈಕೆ" : "Labour Supply",
			value: "labour_supply",
		},
		{
			id: "water_proofing",
			title: router.locale === "kn-IN" ? "ನೀರಿನ ನಿರೋಧಕ" : "Water Proofing",
			value: "water_proofing",
		},
		{
			id: "other",
			title: router.locale === "kn-IN" ? "ಇತರೆ" : "Other",
			value: "other",
		},
	];

	/* Service subtype options */

	switch (newServiceData?.service_type[0]) {
		case "building_and_construction":
			subtype_options.push(...construction_options);
			break;
		case "cleaning_and_maintenance":
			subtype_options.push(...cleaning_and_maintenance_options);
			break;
		case "interiors_and_decor":
			subtype_options.push(...interiors_and_decor_options);
			break;
		case "home_utilies":
			subtype_options.push(...home_utilities_options);
			break;
		case "daily_homecare_and_security":
			subtype_options.push(...daily_home_care_and_security_options);
			break;
		case "home_and_appliance_repair":
			subtype_options.push(...home_and_appliance_repair_options);
			break;

		case "transport":
			subtype_options.push(...transport_options);
			break;
		case "legal_and_finance":
			subtype_options.push(...legal_and_finance_options);
			break;
		case "grooming_and_personal_care":
			subtype_options.push(...grooming_and_other_personal_care_options);
			break;
		case "liaisoning":
			subtype_options.push(...liaisoning_options);
			break;
		case "social_service":
			subtype_options.push(...social_service_options);
			break;
		case "food":
			subtype_options.push(...food_options);
			break;
		case "hospitality":
			subtype_options.push(...hospitality_options);
			break;
		case "other":
			subtype_options.push(...other_options);
			break;
		default:
			subtype_options.push();
			break;
	}

	return (
		<PaperContainer id="service-details">
			<TextContainer>
				{/* <TextField
					value={newServiceData.service_provider.name}
					sx={{
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
					onChange={(event: any) => {
						dispatch(setCreateNewServiceProviderNameThunk(event.target.value));
						setServiceProviderNameError(false);
					}}
					label={router.locale === "kn-IN" ? "ಸೇವಾ ಒದಗಿಸುವವರ ಹೆಸರು*" : "Service provider name*"}
					autoComplete="off"
					error={serviceProviderNameError}
					helperText={serviceProviderNameError ? "Please enter a service provider name" : ""}
				/> */}
				<FormControl
					sx={{
						width: "100%",
						margin: "0.5rem 0rem 0rem 0rem",
						[theme.breakpoints.down("sm")]: {
							margin: "0.5rem 0rem 0rem 0rem",
						},
					}}
					error={serviceTypeError}
				>
					<InputLabel id="demo-simple-select-helper-label">
						{router.locale === "kn-IN" ? "ಸೇವೆಯ ಪ್ರಕಾರ*" : "Service type*"}
					</InputLabel>
					<Select
						labelId="demo-simple-select-helper-label"
						id="demo-simple-select-helper"
						value={newServiceData.service_type}
						sx={{
							width: "100%",
							height: "3.5rem",
							[theme.breakpoints.down("sm")]: {
								width: "100%",
							},
						}}
						label="Service type*"
						onChange={(event: any) => {
							const selectedValue =
								typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value;
							dispatch(setCreateNewServiceTypeThunk(selectedValue));
							dispatch(setCreateNewServiceSubTypeThunk([]));
							dispatch(setCreateNewServiceOtherSubTypeThunk(""));
							dispatch(setCreateNewServiceMinPriceThunk(0));
							dispatch(setCreateNewServiceMaxPriceThunk(0));
							setServiceTypeError(false);
						}}
					>
						<MenuItem value="building_and_construction">
							{router.locale === "kn-IN" ? "ಕಟ್ಟಡ ಮತ್ತು ನಿರ್ಮಾಣ" : "Building and construction"}
						</MenuItem>
						<MenuItem value="cleaning_and_maintenance">
							{router.locale === "kn-IN" ? "ಕ್ಲೀನಿಂಗ್ ಮತ್ತು ನಿರ್ವಹಣೆ" : "Cleaning and maintenance"}
						</MenuItem>
						<MenuItem value="interiors_and_decor">
							{router.locale === "kn-IN" ? "ಆಂತರಿಕ ಮತ್ತು ಅಲಂಕಾರ" : "Interiors and decor"}
						</MenuItem>
						<MenuItem value="home_utilies">{router.locale === "kn-IN" ? "ಮನೆ ಉಪಯೋಗಗಳು" : "Home utilities"}</MenuItem>
						<MenuItem value="daily_homecare_and_security">
							{router.locale === "kn-IN" ? "ದೈನಂದಿನ ಮನೆ ಆರೈಕೆ ಮತ್ತು ಭದ್ರತೆ" : "Daily home care and security"}
						</MenuItem>
						<MenuItem value="home_and_appliance_repair">
							{router.locale === "kn-IN" ? "ಮನೆ ಮತ್ತು ಸಾಧನ ರಿಪೇರಿ" : "Home and appliance repair"}
						</MenuItem>
						<MenuItem value="transport">{router.locale === "kn-IN" ? "ಸಾರಿಗೆ" : "Transport"}</MenuItem>
						<MenuItem value="legal_and_finance">
							{router.locale === "kn-IN" ? "ಕಾನೂನು ಮತ್ತು ಹಣಕಾಸು" : "Legal and finance"}
						</MenuItem>
						<MenuItem value="grooming_and_personal_care">
							{router.locale === "kn-IN" ? "ಗ್ರೂಮಿಂಗ್ ಮತ್ತು ವೈಯಕ್ತಿಕ ಆರೈಕೆ" : "Grooming and personal care"}
						</MenuItem>
						<MenuItem value="liaisoning">{router.locale === "kn-IN" ? "ಲೈಸನಿಂಗ್" : "Liaisoning"}</MenuItem>
						<MenuItem value="social_service">{router.locale === "kn-IN" ? "ಸಾಮಾಜಿಕ ಸೇವೆ" : "Social service"}</MenuItem>
						<MenuItem value="food">{router.locale === "kn-IN" ? "ಆಹಾರ" : "Food"}</MenuItem>
						<MenuItem value="hospitality">{router.locale === "kn-IN" ? "ಆತಿಥ್ಯ" : "Hospitality"}</MenuItem>
						<MenuItem value="other">{router.locale === "kn-IN" ? "ಇತರೆ" : "Other"}</MenuItem>
					</Select>
					<FormHelperText>{serviceTypeError ? "Please select a service type" : ""}</FormHelperText>
				</FormControl>

				{/* Service Subtype */}

				{newServiceData.service_type.length > 0 ? (
					<TextContainer>
						<TextContainer
							sx={{
								width: "100%",
								justifyContent: "space-between",
								alignItems: "center",
								margin: "0.5rem 0rem 0rem 0rem",
								[theme.breakpoints.down("sm")]: {
									flexDirection: "column",
									gap: "1rem",
								},
							}}
						>
							<FormControl
								sx={{
									width: "100%",
									[theme.breakpoints.down("sm")]: {
										width: "100%",
									},
								}}
								error={serviceSubTypeError}
							>
								<InputLabel id="demo-simple-select-helper-label">
									{router.locale === "kn-IN" ? "ಸೇವೆಯ ಉಪಪ್ರಕಾರ*" : "Service subtype*"}
								</InputLabel>
								<Select
									labelId="demo-simple-select-helper-label"
									id="demo-simple-select-helper"
									value={newServiceData.service_subtype}
									sx={{
										width: "100%",
										height: "3.5rem",
										[theme.breakpoints.down("sm")]: {
											width: "100%",
										},
									}}
									label={router.locale === "kn-IN" ? "ಸೇವೆಯ ಉಪಪ್ರಕಾರ*" : "Service subtype*"}
									onChange={(event: any) => {
										dispatch(
											setCreateNewServiceSubTypeThunk(
												typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value,
											),
										);
										setServiceSubTypeError(false);
									}}
								>
									{subtype_options?.map((subtype: any, index: number) => {
										return (
											<MenuItem
												key={index}
												value={subtype.value}
											>
												{subtype.title}
											</MenuItem>
										);
									})}
								</Select>
								<FormHelperText>{serviceSubTypeError ? "Please select a service subtype" : ""}</FormHelperText>
							</FormControl>

							{newServiceData.service_subtype.includes("other") ? (
								<TextField
									value={newServiceData.other_service_subtype}
									sx={{
										width: "100%",
										"& .MuiOutlinedInput-root": {
											borderRadius: "0.75rem",
										},
									}}
									onChange={(event: any) => {
										dispatch(setCreateNewServiceOtherSubTypeThunk(event.target.value));
										if (newServiceData.service_subtype.includes("other") && event.target.value === "") {
											setErrorOtherSubtype(true);
										} else {
											setErrorOtherSubtype(false);
										}
									}}
									label={router.locale === "kn-IN" ? "ಇತರೆ ಸೇವೆಯ ಉಪಪ್ರಕಾರ*" : "Other service subtype*"}
									placeholder={router.locale === "kn-IN" ? "ಸೇವೆಯ ಉಪಪ್ರಕಾರವನ್ನು ನಮೂದಿಸಿ" : "Enter service subtype"}
									autoComplete="off"
									error={errorOtherSubtype}
									helperText={errorOtherSubtype ? "Please select a service subtype" : ""}
								/>
							) : null}
						</TextContainer>
					</TextContainer>
				) : null}

				{/* Show Price */}

				{newServiceData.service_type.length !== 0 && newServiceData.service_subtype.length !== 0 ? (
					<TextContainer>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										sx={{ margin: "0rem 0.25rem 0rem 0rem" }}
										checked={newServiceData.price.show_price}
										onChange={() => dispatch(setCreateNewServiceShowPriceThunk(!newServiceData.price.show_price))}
									/>
								}
								label={router.locale === "kn-IN" ? "ಬೆಲೆ ತೋರಿಸಿ" : "Show Price"}
							/>
						</FormGroup>
					</TextContainer>
				) : null}

				{/* Price */}

				{newServiceData.service_type.length !== 0 &&
				newServiceData.service_subtype.length !== 0 &&
				newServiceData.price.show_price ? (
					<TextContainer>
						<ShowPriceDiv>
							{newServiceData.price.min === newServiceData.price.max
								? "₹ " + PreparePriceString(newServiceData.price.max)
								: "₹ " +
									PreparePriceString(newServiceData.price.min) +
									" - " +
									PreparePriceString(newServiceData.price.max)}
						</ShowPriceDiv>

						<TextContainer
							sx={{
								display: "flex",
								flexDirection: "row",
								width: "100%",
								justifyContent: "space-between",
								alignItems: "flex-start",
								margin: "0.5rem 0rem 0rem 0rem",
								[theme.breakpoints.down("sm")]: {
									gap: "0.5rem",
								},
							}}
						>
							<TextField
								value={newServiceData.price.min === 0 ? "" : NumberToCurrencyPipe(newServiceData.price.min)}
								slotProps={{
									htmlInput: {
										min: 0,
										inputMode: "numeric",
										pattern: "[0-9]*",
									},
								}}
								onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
									if (event.key === "Enter" || event.key === "Next") {
										(event.target as HTMLInputElement).blur();
									}
								}}
								sx={{
									width: "50%",
									// [theme.breakpoints.down("sm")]: {
									// 	width: "100%",
									// },
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								onChange={(event: any) => {
									dispatch(setCreateNewServiceMinPriceThunk(CurrencyToNumberPipe(event.target.value)));
									if (
										(newServiceData.price.max !== 0 &&
											CurrencyToNumberPipe(event.target.value) > newServiceData.price.max) ||
										isNaN(CurrencyToNumberPipe(event.target.value))
									) {
										setErrorMinPrice(true);
										setErrorMaxPrice(true);
									} else {
										setErrorMinPrice(false);
										setErrorMaxPrice(false);
										setServiceMinPriceError(false);
									}
								}}
								label={
									newServiceData.price.show_price
										? router.locale === "kn-IN"
											? "ಕನಿಷ್ಠ ಬೆಲೆ*"
											: "Minimum Price*"
										: router.locale === "kn-IN"
											? "ಕನಿಷ್ಠ ಬೆಲೆ"
											: "Minimum Price"
								}
								error={errorMinPrice || serviceMinPriceError}
								helperText={
									errorMinPrice || serviceMinPriceError
										? router.locale === "kn-IN"
											? "ಮೌಲ್ಯ ಗರಿಷ್ಠ ಬೆಲೆಯಿಗಿಂತ ಹೆಚ್ಚು"
											: "Value is more than max price"
										: newServiceData.price.min > 0
											? PreparePriceString(newServiceData.price.min)
											: ""
								}
								autoComplete="off"
							/>

							<TextField
								value={newServiceData.price.max === 0 ? "" : NumberToCurrencyPipe(newServiceData.price.max)}
								slotProps={{
									htmlInput: {
										min: 0,
										inputMode: "numeric",
										pattern: "[0-9]*",
									},
								}}
								onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
									if (event.key === "Enter" || event.key === "Next") {
										(event.target as HTMLInputElement).blur();
									}
								}}
								autoComplete="off"
								sx={{
									width: "50%",
									// [theme.breakpoints.down("sm")]: {
									// 	width: "100%",
									// },
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								onChange={(event: any) => {
									dispatch(setCreateNewServiceMaxPriceThunk(CurrencyToNumberPipe(event.target.value)));
									if (
										(newServiceData.price.min !== 0 &&
											CurrencyToNumberPipe(event.target.value) < newServiceData.price.min) ||
										isNaN(CurrencyToNumberPipe(event.target.value))
									) {
										setErrorMinPrice(true);
										setErrorMaxPrice(true);
									} else {
										setErrorMinPrice(false);
										setErrorMaxPrice(false);
										setServiceMaxPriceError(false);
									}
								}}
								label={
									newServiceData.price.show_price
										? router.locale === "kn-IN"
											? "ಗರಿಷ್ಠ ಬೆಲೆ*"
											: "Maximum Price*"
										: router.locale === "kn-IN"
											? "ಗರಿಷ್ಠ ಬೆಲೆ"
											: "Maximum Price"
								}
								error={errorMaxPrice || serviceMaxPriceError}
								helperText={
									errorMaxPrice || serviceMaxPriceError
										? router.locale === "kn-IN"
											? "ಮೌಲ್ಯ ಕನಿಷ್ಠ ಬೆಲೆಯಿಗಿಂತ ಕಡಿಮೆ"
											: "Value is less than min price"
										: newServiceData.price.max > 0
											? PreparePriceString(newServiceData.price.max)
											: ""
								}
							/>
						</TextContainer>
					</TextContainer>
				) : null}
			</TextContainer>
		</PaperContainer>
	);
};

export default ServiceDetails;
