/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import { Area, BHK } from "../../../../../redux-magic/sub-interfaces/property-interface";

/* Component Imports */

import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography, useTheme } from "@mui/material";

/* Function Imports */

import CapitalizeFirstLetter from "../../../../../lib/CapitalizeFirstLetter";
import PreparePriceString from "../../../../../lib/PreparePriceString";

/* Icon Imports */

import { ExpandMore, LocationOnOutlined } from "@mui/icons-material";
import { useRouter } from "next/router";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	gap: "0.5rem",
	padding: "1.5rem 0rem 1rem 0rem",
	scrollbarWidth: "none", // Hide the scrollbar for firefox
	"&::-webkit-scrollbar": {
		display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
	},
	"&-ms-overflow-style:": {
		display: "none", // Hide the scrollbar for IE
	},
}));

const PropertyTitle = styled(Typography)(({ theme }) => ({
	fontSize: "1.25rem",
	fontWeight: 500,
	color: theme.palette.text.primary,
	width: "100%",
	padding: "0rem 0rem 0rem 0.25rem",
}));

const PropertyLocationRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.25rem",
	width: "100%",
}));

const PropertyLocation = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: 400,
	color: theme.palette.text.primary,
}));

const AccordionsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "1rem",
	width: "100%",
	margin: "0.5rem 0rem 0rem 0rem",
}));

const CustomAccordion = styled(Accordion)(({ theme }) => ({
	width: "100%",
	boxShadow: "none",
	background: "transparent",
	borderRadius: "0.75rem",
	"&:before": {
		display: "none",
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	width: "100%",
	background: "#fff1e5",
	color: "#6e3037",
	borderRadius: "0.75rem 0.75rem 0.75rem 0.75rem",
	height: "3.5rem",
	"&.Mui-focused": {
		backgroundColor: "inherit",
	},
	"&.Mui-expanded": {
		minHeight: "3.5rem",
		background: "#fff1e5",
		borderRadius: "0.75rem 0.75rem 0rem 0rem",
	},
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	width: "100%",
	boxShadow: "none",
	borderRadius: "0rem 0rem 0.75rem 0.75rem",
	background: theme.palette.background.paper,
	padding: "1rem 1rem 1rem 1rem",
	gap: "1rem",
}));

const PreviewRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "1rem",
	width: "100%",
}));

const DetailTitle = styled(Typography)(({ theme }) => ({
	width: "50%",
	fontSize: "0.875rem",
	fontWeight: 400,
	color: theme.palette.text.secondary,
}));

const DetailValue = styled(Typography)(({ theme }) => ({
	width: "50%",
	fontSize: "0.875rem",
	fontWeight: 400,
	color: theme.palette.text.primary,
}));

const CustomDivider = styled(Divider)(({ theme }) => ({
	width: "100%",
	color: theme.palette.text.disabled,
	margin: "0.5rem 0rem 0.5rem 0rem",
}));

const PropertyPreview = ({
	property_title,
	property_location,
	property_overview,
	property_transaction_type,
	property_category,
	property_type,
	property_min_price,
	property_max_price,
	property_security_deposit,
	property_availability,
	property_possession_date,
	property_unit_facing,
	property_age,
	property_furnishing_status,
	property_plot_dimensions,
	property_area,
	property_bhk,
	property_bathroom,
	property_balconies,
	property_floors,
	property_no_of_units,
	property_no_of_seats,
}: {
	property_title: string;
	property_location: string;
	property_overview: string;
	property_transaction_type: string;
	property_category: string;
	property_type: Array<string>;
	property_min_price: number;
	property_max_price: number;
	property_security_deposit: number;
	property_availability: Array<string>;
	property_possession_date: string | Date;
	property_unit_facing: Array<string>;
	property_age: number;
	property_furnishing_status: Array<string>;
	property_plot_dimensions: Array<string>;
	property_area: Area;
	property_bhk: BHK;
	property_bathroom: BHK;
	property_balconies: BHK;
	property_floors: BHK;
	property_no_of_units: BHK;
	property_no_of_seats: BHK;
}) => {
	const router = React.useRef(useRouter()).current;

	const theme = useTheme();

	const localLang = {
		property_details: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸ್ವಂತ ವಿವರಗಳು" : "Property details",
		configuration_and_area:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸಂರಚನೆ ಮತ್ತು ಪ್ರದೇಶ" : "Configuration & area",
		overview: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಅವಲೋಕನ" : "Overview",
		transaction_type:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ವ್ಯವಹಾರ ಪ್ರಕಾರ" : "Transaction type",
		property_category:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸ್ವಂತ ವಿವರಗಳು" : "Property category",
		property_type: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸ್ವಂತ ವಿವರಗಳು" : "Property type",
		property_price: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಬೆಲೆ" : "Price",
		monthly_rent: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸಾಲಿಗೆ ಬೆಲೆ" : "Monthly rent",
		property_security_deposit:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸುರಕ್ಷಿತ ಡಿಪಾಜಿಟ್" : "Security deposit",
		property_status:
			router.locale === "kn-IN"
				? property_availability.includes("Under construction") ||
					property_availability.includes("under_construction") ||
					property_availability.includes("Select date") ||
					property_availability.includes("select_date")
					? property_transaction_type === "rent"
						? "ಲಭ್ಯತೆ"
						: "ಸಂಪತ್ತಿನ ಸ್ಥಿತಿ"
					: property_transaction_type === "rent"
						? "ಲಭ್ಯತೆ"
						: "ಸಂಪತ್ತಿನ ಸ್ಥಿತಿ"
				: router.locale === "ar-AE"
					? property_availability.includes("Under construction") ||
						property_availability.includes("under_construction") ||
						property_availability.includes("Select date") ||
						property_availability.includes("select_date")
						? property_transaction_type === "rent"
							? "متاح من"
							: "تاريخ الاستلام"
						: property_transaction_type === "rent"
							? "التوافر"
							: "حالة العقار"
					: property_availability.includes("Under construction") ||
						  property_availability.includes("under_construction") ||
						  property_availability.includes("Select date") ||
						  property_availability.includes("select_date")
						? property_transaction_type === "rent"
							? "Available from"
							: "Possession date"
						: property_transaction_type === "rent"
							? "Availability"
							: "Property status",
		property_age: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸ್ವಂತ ವಿವರಗಳು" : "Property age",
		years: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ವರ್ಷಗಳು" : "years",
		property_facing: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸ್ವಂತ ವಿವರಗಳು" : "Facing",
		furnishing_status:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸ್ವಂತ ವಿವರಗಳು" : "Furnishing status",
		furnished: router.locale === "kn-IN" ? "ಫರ್ನಿಶ್ಡ್" : router.locale === "ar-AE" ? "مفروشة" : "Furnished",
		semi_furnished:
			router.locale === "kn-IN" ? "ಸೆಮಿ ಫರ್ನಿಶ್ಡ್" : router.locale === "ar-AE" ? "شبه مفروشة" : "Semi Furnished",
		unfurnished: router.locale === "kn-IN" ? "ಅನ್ಫರ್ನಿಶ್ಡ್" : router.locale === "ar-AE" ? "غير مفروشة" : "Unfurnished",
		bhk: router.locale === "kn-IN" ? "ಬಿಎಚ್ಕೆ" : router.locale === "ar-AE" ? "عدد الغرف" : "BHK",
		bedrooms: router.locale === "kn-IN" ? "ಬೆಡ್ರೂಮ್ಗಳು" : router.locale === "ar-AE" ? "عدد الغرف" : "Bedrooms",
		bathrooms: router.locale === "kn-IN" ? "ಬಾತ್ ರೂಮ್ಸ್" : router.locale === "ar-AE" ? "عدد الحمامات" : "Bathrooms",
		balconies: router.locale === "kn-IN" ? "ಬಾಲ್ಕನಿಗಳು" : router.locale === "ar-AE" ? "عدد الشرفات" : "Balconies",
		noOfSeats: router.locale === "kn-IN" ? "ಸೀಟುಗಳ ಸಂಖ್ಯೆ" : router.locale === "ar-AE" ? "عدد المقاعد" : "No of seats",
		totalNoOfUnits:
			router.locale === "kn-IN"
				? "ಯುನಿಟ್ಗಳ ಸಂಖ್ಯೆ"
				: router.locale === "ar-AE"
					? "إجمالي عدد الوحدات"
					: "Total No of units",
		totalNoOfFloors:
			router.locale === "kn-IN"
				? "ಒಟ್ಟು ಮಹಡಿಗಳ ಸಂಖ್ಯೆ"
				: router.locale === "ar-AE"
					? "إجمالي عدد الطوابق"
					: "Total No of floors",
		PlotDimensions:
			router.locale === "kn-IN"
				? "ಪ್ಲಾಟ್ ಡೈಮೆನ್ಷನ್ಸ್"
				: router.locale === "ar-AE"
					? "أبعاد قطعة الأرض"
					: "Plot dimensions",
		totalLandArea:
			router.locale === "kn-IN"
				? "ಒಟ್ಟು ಭೂಮಿಯ ವಿಸ್ತೀರ್ಣ"
				: router.locale === "ar-AE"
					? "إجمالي مساحة الأرض"
					: "Total land area",
		plotArea:
			router.locale === "kn-IN"
				? property_type.includes("Plot") ||
					property_type.includes("Farm Plot") ||
					property_type.includes("Plot (Commercial)")
					? "ಪ್ಲಾಟ್ ಏರಿಯಾ"
					: "ಎಸ್.ಬಿ.ಎ"
				: router.locale === "ar-AE"
					? property_type.includes("Plot") ||
						property_type.includes("Farm Plot") ||
						property_type.includes("Plot (Commercial)")
						? "مساحة قطعة الأرض"
						: "مساحة البناء الإجمالية"
					: property_type.includes("Plot") ||
						  property_type.includes("Farm Plot") ||
						  property_type.includes("Plot (Commercial)")
						? "Plot area"
						: "Super built up area",
		sqft: router.locale === "kn-IN" ? "ಚದರ ಅಡಿ" : router.locale === "ar-AE" ? "قدم مربع" : "sqft",
		acre: router.locale === "kn-IN" ? "ಎಕರೆ" : router.locale === "ar-AE" ? "فدان" : "acre",
		sqm: router.locale === "kn-IN" ? "ಚದರ ಮೀಟರ್" : router.locale === "ar-AE" ? "متر مربع" : "sqm",
		sale: router.locale === "kn-IN" ? "ಮಾರಾಟ" : router.locale === "ar-AE" ? "بيع" : "Sale",
	};

	return (
		<MainContainer>
			{/* Property Title */}

			<PropertyTitle variant="body1">{property_title ? property_title : ""}</PropertyTitle>

			{/* Property Location */}

			<PropertyLocationRow>
				<LocationOnOutlined
					fontSize="small"
					sx={{
						fontSize: "1.25rem",
						color: theme.palette.primary.main,
					}}
				/>
				<PropertyLocation variant="body1">{property_location ? property_location : ""}</PropertyLocation>
			</PropertyLocationRow>

			<AccordionsContainer>
				{/* Property Details */}

				<CustomAccordion
					disableGutters
					elevation={0}
					defaultExpanded
				>
					<CustomAccordionSummary
						expandIcon={
							<ExpandMore
								sx={{
									color: "#6e3037",
								}}
							/>
						}
						aria-controls="property-details"
						id="property-details"
					>
						<Typography variant="body1">{localLang.property_details}</Typography>
					</CustomAccordionSummary>
					<CustomAccordionDetails>
						{/* Transaction Type */}

						{property_transaction_type !== "ofb" ? (
							<>
								<PreviewRow>
									<DetailTitle variant="body1">{localLang.transaction_type}</DetailTitle>
									<DetailValue variant="body1">
										{property_transaction_type === "buy"
											? localLang.sale
											: CapitalizeFirstLetter(property_transaction_type)}
									</DetailValue>
								</PreviewRow>
								<CustomDivider />
							</>
						) : null}

						{/* Property Category */}

						{property_transaction_type !== "" ? (
							<>
								<PreviewRow>
									<DetailTitle variant="body1">{localLang.property_category}</DetailTitle>
									<DetailValue variant="body1">{CapitalizeFirstLetter(property_category)}</DetailValue>
								</PreviewRow>
								<CustomDivider />
							</>
						) : null}

						{/* Property Type */}

						{property_transaction_type !== "" &&
						property_category !== "" &&
						property_category !== "public infrastructure" &&
						property_category !== "joint development" &&
						property_category !== "joint venture" &&
						property_category !== "warehousing" ? (
							<>
								<PreviewRow>
									<DetailTitle variant="body1">{localLang.property_type}</DetailTitle>
									<DetailValue variant="body1">{property_type?.join(", ")}</DetailValue>
								</PreviewRow>
								<CustomDivider />
							</>
						) : null}

						{/* Price */}

						{property_transaction_type !== "ofb" &&
						property_transaction_type !== "" &&
						property_category !== "" &&
						property_type.length !== 0 ? (
							<>
								<PreviewRow>
									<DetailTitle variant="body1">
										{property_transaction_type === "rent" ? localLang.monthly_rent : localLang.property_price}
									</DetailTitle>
									<DetailValue variant="body1">
										{property_min_price === property_max_price
											? "₹" + PreparePriceString(property_min_price)
											: "₹" + PreparePriceString(property_min_price) + " - " + PreparePriceString(property_max_price)}
									</DetailValue>
								</PreviewRow>
								<CustomDivider />
							</>
						) : null}

						{/* Security Deposit */}

						{property_transaction_type == "rent" && property_category !== "" && property_type.length !== 0 ? (
							<>
								<PreviewRow>
									<DetailTitle variant="body1">{localLang.property_security_deposit}</DetailTitle>
									<DetailValue variant="body1">₹{PreparePriceString(property_security_deposit)}</DetailValue>
								</PreviewRow>
								<CustomDivider />
							</>
						) : null}

						{/* Property Status / Availability */}

						{(property_transaction_type !== "ofb" &&
							property_transaction_type !== "" &&
							property_category !== "" &&
							property_type.length !== 0 &&
							!property_type.includes("Land (Residential)") &&
							!property_type.includes("Land (Commercial)") &&
							!property_type.includes("Land (Agricultural)") &&
							!property_type.includes("Land (Industrial)") &&
							!property_type.includes("Mining")) ||
						(property_transaction_type == "rent" &&
							property_category !== "" &&
							(property_type.includes("Land (Residential)") ||
								property_type.includes("Land (Commercial)") ||
								property_type.includes("Land (Agricultural)") ||
								property_type.includes("Land (Industrial)") ||
								property_type.includes("Mining"))) ? (
							<>
								<PreviewRow>
									<DetailTitle variant="body1">{localLang.property_status}</DetailTitle>
									<DetailValue variant="body1">
										{property_transaction_type === "buy" &&
										(property_type.includes("Land (Residential)") ||
											property_type.includes("Land (Commercial)") ||
											property_type.includes("Land (Agricultural)") ||
											property_type.includes("Land (Industrial)") ||
											property_type.includes("Mining"))
											? router.locale === "kn-IN"
												? ""
												: router.locale === "ar-AE"
													? "جاهز للتسجيل"
													: "Ready for registration"
											: property_availability.includes("Under construction") ||
												  property_availability.includes("under_construction") ||
												  property_availability.includes("Select date") ||
												  property_availability.includes("select_date")
												? new Date(property_possession_date).toLocaleDateString("en-IN")
												: property_availability.map(
														(element: string) => element.charAt(0).toUpperCase() + element.slice(1).replace(/_/g, " "),
													)}
									</DetailValue>
								</PreviewRow>
								<CustomDivider />
							</>
						) : null}

						{/* Property Age */}

						{property_transaction_type == "resale" &&
						property_category !== "" &&
						property_type.length !== 0 &&
						!property_type.includes("Land (Residential)") &&
						!property_type.includes("Land (Commercial)") &&
						!property_type.includes("Land (Agricultural)") &&
						!property_type.includes("Land (Industrial)") &&
						!property_type?.includes("Plot") &&
						!property_type?.includes("Farm Plot") &&
						!property_type?.includes("Plot (Commercial)") &&
						!property_type.includes("Mining") &&
						!property_availability.includes("Under construction") &&
						!property_availability.includes("under_construction") &&
						!property_availability.includes("Select date") &&
						!property_availability.includes("select_date") ? (
							<>
								<PreviewRow>
									<DetailTitle variant="body1">{localLang.property_age}</DetailTitle>
									<DetailValue variant="body1">{property_age + " " + localLang.years}</DetailValue>
								</PreviewRow>
								<CustomDivider />
							</>
						) : null}

						{/* Unit Facing */}

						{(property_transaction_type !== "" &&
							property_category !== "" &&
							property_type.length !== 0 &&
							router.locale !== "ar-AE" &&
							!property_type.includes("Land (Residential)") &&
							!property_type.includes("Land (Commercial)") &&
							!property_type.includes("Land (Agricultural)") &&
							!property_type.includes("Land (Industrial)") &&
							!property_type.includes("Warehouse") &&
							!property_type.includes("Factory") &&
							!property_type.includes("Mining") &&
							!property_type.includes("Office Space") &&
							!property_type.includes("Co-working")) ||
						(property_transaction_type == "buy" &&
							property_category !== "" &&
							property_type.length !== 0 &&
							router.locale !== "ar-AE" &&
							!property_type.includes("Land (Residential)") &&
							!property_type.includes("Land (Commercial)") &&
							!property_type.includes("Land (Agricultural)") &&
							!property_type.includes("Land (Industrial)") &&
							!property_type.includes("Warehouse") &&
							!property_type.includes("Factory") &&
							!property_type.includes("Mining")) ||
						(property_transaction_type == "resale" &&
							property_category !== "" &&
							property_type.length !== 0 &&
							router.locale !== "ar-AE" &&
							!property_type.includes("Land (Residential)") &&
							!property_type.includes("Land (Commercial)") &&
							!property_type.includes("Land (Agricultural)") &&
							!property_type.includes("Land (Industrial)") &&
							!property_type.includes("Warehouse") &&
							!property_type.includes("Factory") &&
							!property_type.includes("Mining")) ||
						(property_transaction_type == "rent" &&
							router.locale !== "ar-AE" &&
							property_category !== "" &&
							property_type.length !== 0 &&
							property_type.includes("Office Space")) ? (
							<>
								<PreviewRow>
									<DetailTitle variant="body1">{localLang.property_facing}</DetailTitle>
									<DetailValue variant="body1">{property_unit_facing?.join(", ")}</DetailValue>
								</PreviewRow>
							</>
						) : null}
					</CustomAccordionDetails>
				</CustomAccordion>

				{/* Configuration & Area */}

				<CustomAccordion
					disableGutters
					elevation={0}
					defaultExpanded
				>
					<CustomAccordionSummary
						expandIcon={
							<ExpandMore
								sx={{
									color: "#6e3037",
								}}
							/>
						}
						aria-controls="property-configuration"
						id="property-configuration"
					>
						<Typography variant="body1">{localLang.configuration_and_area}</Typography>
					</CustomAccordionSummary>
					<CustomAccordionDetails>
						{/* Furnishing Status */}

						{property_transaction_type !== "ofb" &&
						property_transaction_type !== "" &&
						property_category !== "" &&
						property_type.length !== 0 &&
						!property_type.includes("Plot") &&
						!property_type.includes("Farm Plot") &&
						!property_type.includes("Plot (Commercial)") &&
						!property_type.includes("Land (Residential)") &&
						!property_type.includes("Land (Commercial)") &&
						!property_type.includes("Land (Agricultural)") &&
						!property_type.includes("Land (Industrial)") &&
						!property_type.includes("Co-working") &&
						!property_type.includes("Warehouse") &&
						!property_type.includes("Factory") &&
						!property_type.includes("Mining") ? (
							<>
								<PreviewRow>
									<DetailTitle variant="body1">{localLang.furnishing_status}</DetailTitle>
									<DetailValue variant="body1">
										{property_furnishing_status
											? property_furnishing_status
													.map((furnish: any) => furnish.charAt(0).toUpperCase() + furnish.slice(1).replace(/_/g, " "))
													.join(", ")
											: ""}
									</DetailValue>
								</PreviewRow>
								<CustomDivider />
							</>
						) : null}

						{/* Bedrooms */}

						{property_transaction_type !== "ofb" &&
						property_transaction_type !== "" &&
						property_category !== "" &&
						property_type.length !== 0 &&
						!property_type.includes("Plot") &&
						!property_type.includes("Farm Plot") &&
						!property_type.includes("Plot (Commercial)") &&
						!property_type.includes("Land (Residential)") &&
						!property_type.includes("Land (Commercial)") &&
						!property_type.includes("Land (Agricultural)") &&
						!property_type.includes("Land (Industrial)") &&
						!property_type.includes("Studio Apartment") &&
						!property_type.includes("Building") &&
						!property_type.includes("Shop") &&
						!property_type.includes("Office Space") &&
						!property_type.includes("Co-working") &&
						!property_type.includes("Warehouse") &&
						!property_type.includes("Factory") &&
						!property_type.includes("Mining") ? (
							<>
								<PreviewRow>
									<DetailTitle variant="body1">{localLang.bedrooms + " (" + localLang.bhk + ")"}</DetailTitle>
									<DetailValue variant="body1">
										{property_bhk.min === property_bhk.max
											? property_bhk.max > 0
												? property_bhk.max
												: ""
											: (property_bhk.min > 0 ? property_bhk.min : "") +
												"-" +
												(property_bhk.max > 0 ? property_bhk.max : "")}
									</DetailValue>
								</PreviewRow>
								<CustomDivider />
							</>
						) : null}

						{/* Bathrooms */}

						{property_transaction_type !== "ofb" &&
						property_transaction_type !== "" &&
						property_category !== "" &&
						property_type.length !== 0 &&
						!property_type.includes("Co-living/PG") &&
						!property_type.includes("Plot") &&
						!property_type.includes("Farm Plot") &&
						!property_type.includes("Plot (Commercial)") &&
						!property_type.includes("Land (Residential)") &&
						!property_type.includes("Land (Commercial)") &&
						!property_type.includes("Land (Agricultural)") &&
						!property_type.includes("Land (Industrial)") &&
						!property_type.includes("Studio Apartment") &&
						!property_type.includes("Building") &&
						!property_type.includes("Shop") &&
						!property_type.includes("Office Space") &&
						!property_type.includes("Co-working") &&
						!property_type.includes("Warehouse") &&
						!property_type.includes("Factory") &&
						!property_type.includes("Mining") ? (
							<>
								<PreviewRow>
									<DetailTitle variant="body1">{localLang.bathrooms}</DetailTitle>
									<DetailValue variant="body1">
										{property_bathroom.min === property_bathroom.max
											? property_bathroom.max > 0
												? property_bathroom.max
												: ""
											: (property_bathroom.min > 0 ? property_bathroom.min : "") +
												"-" +
												(property_bathroom.max > 0 ? property_bathroom.max : "")}
									</DetailValue>
								</PreviewRow>
								<CustomDivider />
							</>
						) : null}

						{/* Balconies */}

						{property_transaction_type !== "ofb" &&
						property_transaction_type !== "" &&
						property_category !== "" &&
						property_type.length !== 0 &&
						!property_type.includes("Co-living/PG") &&
						!property_type.includes("Plot") &&
						!property_type.includes("Farm Plot") &&
						!property_type.includes("Plot (Commercial)") &&
						!property_type.includes("Land (Residential)") &&
						!property_type.includes("Land (Commercial)") &&
						!property_type.includes("Land (Agricultural)") &&
						!property_type.includes("Land (Industrial)") &&
						!property_type.includes("Building") &&
						!property_type.includes("Shop") &&
						!property_type.includes("Office Space") &&
						!property_type.includes("Co-working") &&
						!property_type.includes("Warehouse") &&
						!property_type.includes("Factory") &&
						!property_type.includes("Mining") ? (
							<>
								<PreviewRow>
									<DetailTitle variant="body1">{localLang.balconies}</DetailTitle>
									<DetailValue variant="body1">
										{property_balconies.min === property_balconies.max
											? property_balconies.max > 0
												? property_balconies.max
												: ""
											: (property_balconies.min > 0 ? property_balconies.min : "") +
												"-" +
												(property_balconies.max > 0 ? property_balconies.max : "")}
									</DetailValue>
								</PreviewRow>
								<CustomDivider />
							</>
						) : null}

						{/* No of Seats */}

						{property_transaction_type !== "ofb" &&
						property_transaction_type !== "" &&
						property_category !== "" &&
						property_type.length !== 0 &&
						property_type.includes("Co-working") ? (
							<>
								<PreviewRow>
									<DetailTitle variant="body1">{localLang.noOfSeats}</DetailTitle>
									<DetailValue variant="body1">
										{property_no_of_seats.max > 0 ? property_no_of_seats.max : ""}
									</DetailValue>
								</PreviewRow>
								<CustomDivider />
							</>
						) : null}

						{/* No of Units */}

						{property_transaction_type !== "" &&
						property_category !== "" &&
						property_type.length !== 0 &&
						property_transaction_type == "buy" &&
						(property_type.includes("Apartment") ||
							property_type.includes("Plot") ||
							property_type.includes("Farm Plot") ||
							property_type.includes("Plot (Commercial)") ||
							property_type.includes("Villa") ||
							property_type.includes("Row House") ||
							property_type.includes("Builder Floor Apartment") ||
							property_type.includes("Studio Apartment") ||
							property_type.includes("Penthouse")) ? (
							<>
								<PreviewRow>
									<DetailTitle variant="body1">{localLang.totalNoOfUnits}</DetailTitle>
									<DetailValue variant="body1">
										{property_no_of_units.max > 0 ? property_no_of_units.max : ""}
									</DetailValue>
								</PreviewRow>
								<CustomDivider />
							</>
						) : null}

						{/* No of Floors */}

						{property_transaction_type !== "ofb" &&
						property_transaction_type !== "" &&
						property_category !== "" &&
						property_type.length !== 0 &&
						!property_type.includes("Plot") &&
						!property_type.includes("Farm Plot") &&
						!property_type.includes("Plot (Commercial)") &&
						!property_type.includes("Land (Residential)") &&
						!property_type.includes("Land (Commercial)") &&
						!property_type.includes("Land (Agricultural)") &&
						!property_type.includes("Land (Industrial)") &&
						!property_type.includes("Warehouse") &&
						!property_type.includes("Factory") &&
						!property_type.includes("Mining") ? (
							<>
								<PreviewRow>
									<DetailTitle variant="body1">{localLang.totalNoOfFloors}</DetailTitle>
									<DetailValue variant="body1">{property_floors.max > 0 ? property_floors.max : ""}</DetailValue>
								</PreviewRow>
								<CustomDivider />
							</>
						) : null}

						{/* Plot Dimensions */}

						{property_transaction_type !== "ofb" &&
						property_transaction_type !== "" &&
						property_category !== "" &&
						property_type.length !== 0 &&
						(property_type.includes("Plot") ||
							property_type.includes("Farm Plot") ||
							property_type.includes("Plot (Commercial)")) ? (
							<>
								<PreviewRow>
									<DetailTitle variant="body1">{localLang.PlotDimensions}</DetailTitle>
									<DetailValue variant="body1">
										{property_plot_dimensions
											.filter((dimension: any) => dimension !== "Other")
											.map((dimension: any) => dimension)
											.join(", ")}
									</DetailValue>
								</PreviewRow>
								<CustomDivider />
							</>
						) : null}

						{/* Total land Area */}

						{property_transaction_type !== "" &&
						property_category !== "" &&
						property_type.length !== 0 &&
						((property_transaction_type == "rent" &&
							(property_type.includes("Land (Residential)") ||
								property_type.includes("Land (Commercial)") ||
								property_type.includes("Land (Agricultural)") ||
								property_type.includes("Land (Industrial)") ||
								property_type.includes("Mining"))) ||
							property_transaction_type === "buy") ? (
							<>
								<PreviewRow>
									<DetailTitle variant="body1">{localLang.totalLandArea}</DetailTitle>
									<DetailValue variant="body1">
										{property_area.land_area.max.acre <= 0.5
											? property_area.land_area.max.ft.toFixed(0) + " " + localLang.sqft
											: property_area.land_area.max.acre % 1 === 0
												? property_area.land_area.max.acre.toFixed(0) + " " + localLang.acre
												: property_area.land_area.max.acre.toFixed(2) + " " + localLang.acre}
									</DetailValue>
								</PreviewRow>
								<CustomDivider />
							</>
						) : null}

						{/* Super Built Up Area */}

						{property_transaction_type !== "ofb" &&
						property_transaction_type !== "" &&
						property_category !== "" &&
						property_type.length !== 0 &&
						!property_type.includes("Land (Residential)") &&
						!property_type.includes("Land (Commercial)") &&
						!property_type.includes("Land (Agricultural)") &&
						!property_type.includes("Land (Industrial)") &&
						!property_type.includes("Mining") ? (
							<>
								<PreviewRow>
									<DetailTitle variant="body1">{localLang.plotArea}</DetailTitle>
									<DetailValue variant="body1">
										{property_area.super_built_up_area.min.ft === property_area.super_built_up_area.max.ft
											? property_area.super_built_up_area.max.ft > 0
												? property_area.super_built_up_area.max.ft.toFixed(0) + " " + localLang.sqft
												: ""
											: (property_area.super_built_up_area.min.ft > 0
													? property_area.super_built_up_area.min.ft.toFixed(0) + " " + localLang.sqft + " - "
													: "") +
												(property_area.super_built_up_area.max.ft > 0
													? property_area.super_built_up_area.max.ft.toFixed(0) + " " + localLang.sqft
													: "")}
									</DetailValue>
								</PreviewRow>
							</>
						) : null}
					</CustomAccordionDetails>
				</CustomAccordion>

				{/* Overview */}

				{property_overview ? (
					<CustomAccordion
						disableGutters
						elevation={1}
						defaultExpanded
					>
						<CustomAccordionSummary
							expandIcon={
								<ExpandMore
									sx={{
										color: "#6e3037",
									}}
								/>
							}
							aria-controls="property-overview"
							id="property-overview"
						>
							<Typography variant="body1">{localLang.overview}</Typography>
						</CustomAccordionSummary>
						<CustomAccordionDetails>
							<Typography variant="body2">{property_overview}</Typography>
						</CustomAccordionDetails>
					</CustomAccordion>
				) : null}
			</AccordionsContainer>
		</MainContainer>
	);
};

export default PropertyPreview;
