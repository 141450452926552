/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Component Imports */

import { Divider, Typography } from "@mui/material";
import { useRouter } from "next/router";

/* Styled Components */

const FooterCopyrightDesktop = styled(Typography)(({ theme }) => ({
	[theme.breakpoints.down("sm")]: {
		display: "none",
	},
}));
4;
const FooterCopyrightMobile = styled(Typography)(({ theme }) => ({
	[theme.breakpoints.up("sm")]: {
		display: "none",
	},
	fontSize: "0.75rem",
	margin: "0.5rem 0rem 4rem 0rem",
	textAlign: "center",
}));

const MobileDivider = styled(Divider)(({ theme }) => ({
	[theme.breakpoints.up("sm")]: {
		display: "none",
	},
	width: "100%",
	color: "#212121",
}));

const FooterCopyrightInfo = () => {
	const router = useRouter();

	const currentYear = new Date().getFullYear();
	return (
		<React.Fragment>
			{/* Desktop */}

			<FooterCopyrightDesktop variant="body2">
				{"© " +
					currentYear +
					" " +
					(router.locale === "ar-AE"
						? "شركة بيغرو الخاصة المحدودة. جميع الحقوق محفوظة."
						: router.locale === "kn-IN"
							? "ಬೀಗ್ರು ಪ್ರೈವೇಟ್ ಲಿಮಿಟೆಡ್.ಎಲ್ಲಾ ಹಕ್ಕುಗಳನ್ನು ಕಾಯ್ದಿರಿಸಲಾಗಿದೆ."
							: " Beegru Pte Ltd. All Rights Reserved.")}
			</FooterCopyrightDesktop>

			{/* Mobile */}

			<MobileDivider />

			<FooterCopyrightMobile variant="body2">
				{"© " +
					currentYear +
					" " +
					(router.locale === "ar-AE"
						? "شركة بيغرو الخاصة المحدودة. جميع الحقوق محفوظة."
						: router.locale === "kn-IN"
							? "ಬೀಗ್ರು ಪ್ರೈವೇಟ್ ಲಿಮಿಟೆಡ್.ಎಲ್ಲಾ ಹಕ್ಕುಗಳನ್ನು ಕಾಯ್ದಿರಿಸಲಾಗಿದೆ."
							: "Beegru Pte Ltd. All Rights Reserved.")}
			</FooterCopyrightMobile>
		</React.Fragment>
	);
};

export default FooterCopyrightInfo;
