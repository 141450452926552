/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

/* Component Imports */

import { BottomNavigation, useTheme } from "@mui/material";
import { useRouter } from "next/router";
import BeegruButton from "../common-components/buttons/BeegruButton";

/* Icon Imports */

import { LocalPhoneOutlined, WhatsApp } from "@mui/icons-material";

/* Styled Components */

const CustomBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-around",
	alignItems: "center",
	height: "4rem",
	position: "fixed",
	bottom: 0,
	right: 0,
	left: 0,
	zIndex: 5,
	background: theme.palette.mode == "dark" ? "#212121" : "#FFFFFF",
	opacity: 1,
	width: "100%",
	gap: "0.25rem",
	padding: "0rem 0.25rem 0rem 0.25rem",
	// borderRadius: "0.75rem 0.75rem 0rem 0rem",
	boxShadow:
		"rgba(0, 0, 0, 0.2) 0px 3px 3px 2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
	[theme.breakpoints.up("md")]: {
		display: "none",
	},
	"& .Mui-selected": {
		"& .MuiBottomNavigationAction-label": {
			fontSize: "0.75rem",
			lineHeight: "20px",
		},
	},
}));

const ButtonsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	gap: "0.25rem",
	padding: "0rem 0.25rem 0rem 0.25rem",
}));

const BottomNavBusinessProfileUserView = ({ business_phone_number }: { business_phone_number: string }) => {
	const theme = useTheme();

	const router = React.useRef(useRouter()).current;

	const localLang = {
		callNow: router.locale === "ar-AE" ? "اتصل الآن" : router.locale === "kn-IN" ? "ಈಗ ಕರೆ ಮಾಡಿ" : "Call",
		whatsapp: router.locale === "ar-AE" ? "واتساب" : router.locale === "kn-IN" ? "ವಾಟ್ಸಪ್" : "WhatsApp",
	};

	return (
		<React.Fragment>
			{/* Bottom Navigation */}

			<CustomBottomNavigation>
				{/* WhatsApp */}

				<BeegruButton
					flavor="success"
					variant="outlined"
					sx={{
						width: "48%",
						fontSize: "0.75rem",
						/* galaxy S8+ - 360 */
						[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
							fontSize: "0.875rem",
						},
					}}
					href={`https://wa.me/${business_phone_number.replace(" ", "").replace("+", "")}`}
				>
					<WhatsApp
						sx={{
							fontSize: "1rem",
							margin: "0rem 0.25rem 0rem 0rem",
							/* galaxy S8+ - 360 */
							[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
								fontSize: "1.25rem",
							},
						}}
					/>
					{localLang.whatsapp}
				</BeegruButton>

				{/* Call Now */}

				<BeegruButton
					flavor="success"
					variant="contained"
					sx={{
						width: "48%",
						fontSize: "0.75rem",
						/* galaxy S8+ - 360 */
						[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
							fontSize: "0.875rem",
						},
					}}
					href={"tel:" + business_phone_number}
				>
					<LocalPhoneOutlined
						sx={{
							fontSize: "1rem",
							margin: "0rem 0.25rem 0rem 0rem",
							/* galaxy S8+ - 360 */
							[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
								fontSize: "1.25rem",
							},
						}}
					/>
					{localLang.callNow}
				</BeegruButton>
			</CustomBottomNavigation>
		</React.Fragment>
	);
};

export default BottomNavBusinessProfileUserView;
