/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

/* Component Imports */

import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography, useTheme } from "@mui/material";

/* Function Imports */

/* Icon Imports */

import { ExpandMore, LocationOnOutlined } from "@mui/icons-material";
import { useRouter } from "next/router";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	gap: "0.5rem",
	padding: "1.5rem 0rem 1rem 0rem",
	scrollbarWidth: "none", // Hide the scrollbar for firefox
	"&::-webkit-scrollbar": {
		display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
	},
	"&-ms-overflow-style:": {
		display: "none", // Hide the scrollbar for IE
	},
}));

const ServiceLocation = styled(Typography)(({ theme }) => ({
	fontSize: "1.25rem",
	fontWeight: 500,
	color: theme.palette.text.primary,
	width: "100%",
	padding: "0rem 0rem 0rem 0.25rem",
}));

const ServiceLocationRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.25rem",
	width: "100%",
}));

const PropertyLocation = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: 400,
	color: theme.palette.text.primary,
}));

const AccordionsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "1rem",
	width: "100%",
	margin: "0.5rem 0rem 0rem 0rem",
}));

const CustomAccordion = styled(Accordion)(({ theme }) => ({
	width: "100%",
	boxShadow: "none",
	background: "transparent",
	borderRadius: "0.75rem",
	"&:before": {
		display: "none",
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	width: "100%",
	background: "#fff1e5",
	color: "#6e3037",
	borderRadius: "0.75rem 0.75rem 0.75rem 0.75rem",
	height: "3.5rem",
	"&.Mui-focused": {
		backgroundColor: "inherit",
	},
	"&.Mui-expanded": {
		minHeight: "3.5rem",
		background: "#fff1e5",
		borderRadius: "0.75rem 0.75rem 0rem 0rem",
	},
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	width: "100%",
	boxShadow: "none",
	borderRadius: "0rem 0rem 0.75rem 0.75rem",
	background: "#FFFFFF",
	padding: "1rem 1rem 1rem 1rem",
	gap: "1rem",
}));

const PreviewRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "1rem",
	width: "100%",
}));

const DetailTitle = styled(Typography)(({ theme }) => ({
	width: "50%",
	fontSize: "0.875rem",
	fontWeight: 400,
	color: theme.palette.text.secondary,
}));

const DetailValue = styled(Typography)(({ theme }) => ({
	width: "50%",
	fontSize: "0.875rem",
	fontWeight: 400,
	color: theme.palette.text.primary,
}));

const CustomDivider = styled(Divider)(({ theme }) => ({
	width: "100%",
	color: theme.palette.text.disabled,
	margin: "0.5rem 0rem 0.5rem 0rem",
}));

const ServicewPreview = ({
	service_title,
	service_location,
	service_type,
	service_subtype,
	service_min_price,
	service_max_price,
	service_provider_name,
}: {
	service_title: string;
	service_location: string;
	service_type: Array<string>;
	service_subtype: Array<string>;
	service_min_price: number;
	service_max_price: number;
	service_provider_name: string;
}) => {
	const router = React.useRef(useRouter()).current;

	const theme = useTheme();

	const localLang = {
		service_details: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸೇವೆಯ ವಿವರಗಳು" : "Service details",
		service_type: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸೇವೆಯ ಪ್ರಕಾರ" : "Service Type",
		service_subtype: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸೇವೆಯ ಉಪವಿಧ" : "Service Subtype",
		service_min_price:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸೇವೆಯ ಕನಿಷ್ಠ ಬೆಲೆ" : "Service Min Price",
		service_max_price:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸೇವೆಯ ಗರಿಷ್ಠ ಬೆಲೆ" : "Service Max Price",
		service_provider_name:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸೇವೆ ಒದಗಿಸುವವರ ಹೆಸರು" : "Service Provider Name",
	};

	return (
		<MainContainer>
			{/* Service Title */}

			<ServiceLocation variant="body1">{service_title ? service_title : ""}</ServiceLocation>

			{/* Service Location */}

			<ServiceLocationRow>
				<LocationOnOutlined
					fontSize="small"
					sx={{
						fontSize: "1.25rem",
						color: theme.palette.primary.main,
					}}
				/>
				<PropertyLocation variant="body1">{service_location ? service_location : ""}</PropertyLocation>
			</ServiceLocationRow>

			<AccordionsContainer>
				{/* Service Details */}

				<CustomAccordion
					disableGutters
					elevation={0}
					defaultExpanded
				>
					<CustomAccordionSummary
						expandIcon={
							<ExpandMore
								sx={{
									color: "#6e3037",
								}}
							/>
						}
						aria-controls="service-details"
						id="service-details"
					>
						<Typography variant="body1">{localLang.service_details}</Typography>
					</CustomAccordionSummary>
					<CustomAccordionDetails>
						{/* Service Type */}

						<PreviewRow>
							<DetailTitle variant="body2">{localLang.service_type}</DetailTitle>
							<DetailValue variant="body2">
								{Array.isArray(service_type)
									? service_type.map((type) => type.replace(/_/g, " ").toUpperCase()).join(", ")
									: ""}
							</DetailValue>
						</PreviewRow>

						<CustomDivider />

						{/* Service Subtype */}

						<PreviewRow>
							<DetailTitle variant="body2">{localLang.service_subtype}</DetailTitle>
							<DetailValue variant="body2">
								{Array.isArray(service_subtype)
									? service_subtype.map((subtype) => subtype.replace(/_/g, " ").toUpperCase()).join(", ")
									: ""}
							</DetailValue>
						</PreviewRow>
						<CustomDivider />
					</CustomAccordionDetails>
				</CustomAccordion>
			</AccordionsContainer>
		</MainContainer>
	);
};

export default ServicewPreview;
