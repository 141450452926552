/* Common Imports */

import { styled, useMediaQuery } from "@mui/system";
import React from "react";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";
import {
	CreateNewServiceWithUploadToolThunk,
	setCreateNewServiceDefaultLocationThunk,
	setCreateNewServiceMaxPriceThunk,
	setCreateNewServiceMinPriceThunk,
	setCreateNewServiceOtherSubTypeThunk,
	setCreateNewServiceProviderNameThunk,
	setCreateNewServiceShowPriceThunk,
	setCreateNewServiceSubTypeThunk,
	setCreateNewServiceTypeThunk,
	setCreateNewServiceUploadImagesThunk,
} from "../../../redux-magic/store";

/* Component Imports */

import BeegruButton from "../../common-components/buttons/BeegruButton";
import ServiceDetails from "./sub-components/CreateServiceSubComponents/ServiceDetails";
import ServiceLocation from "./sub-components/CreateServiceSubComponents/ServiceLocation";
import ServicePreview from "./sub-components/CreateServiceSubComponents/ServicePreview";
import UploadImages from "./sub-components/CreateServiceSubComponents/UploadImages";

/* mui imports */

import {
	Alert,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormHelperText,
	IconButton,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	Slide,
	Snackbar,
	TextField,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";

/* Icon Imports */

import { ArrowBack, Close, InfoOutlined } from "@mui/icons-material";
import { TransitionProps } from "@mui/material/transitions";
import { useRouter } from "next/router";
import { DbUserPage } from "../../../redux-magic/sub-interfaces/user-interface";

/* Styled Components */

const CustomDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialog-paper": {
		[theme.breakpoints.down("sm")]: {
			borderRadius: "0rem",
			minHeight: "100%",
		},
		minHeight: "700px",
		maxHeight: "100%",
		borderRadius: "1rem",
		background: theme.palette.background.paper,
	},
}));

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	fontSize: "1.25rem",
	fontWeight: 500,
	lineHeight: "106%",
	background: theme.palette.background.paper,
	gap: "0rem",
	padding: "1rem ",
	borderRadius: "1rem 1rem 0rem 0rem",
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
	padding: "1rem",
	background: theme.palette.background.paper,
	borderRadius: "0rem 0rem 1rem 1rem",
	overflow: "scroll",
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	gap: "0.25rem",
}));

const CustomDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const ServiceNameContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	gap: "2rem",
}));

const ProgressContainer = styled("div")(({ theme }) => ({
	width: "100%",
	padding: "0.75rem 0rem 0rem 0rem ",
}));

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
	width: "100%",
	height: "0.25rem",
	borderRadius: "1rem",
}));

const IconTitleRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0rem",
	height: "auto",
}));

const Circle = styled(Typography)(({ theme }) => ({
	"@keyframes fadeIn": {
		"0%": {
			opacity: 0,
			// content: `${text[0]}`,
		},
		"25%": {
			opacity: 1,
		},
		"90%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0,
		},
	},
	animation: "fadeIn 2s infinite",
}));

const Circle1 = styled(Typography)(({ theme }) => ({
	"@keyframes fadeIn1": {
		"0%": {
			opacity: 0,
			// content: `${text[2]}`,
		},
		"50%": {
			opacity: 1,
			// content: `${text[0]}`,
		},
		"90%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0,
		},
	},
	animation: "fadeIn1 2s infinite",
}));

const Circle2 = styled(Typography)(({ theme }) => ({
	"@keyframes fadeIn2": {
		"0%": {
			opacity: 0,
			// content: `${text[0]}`,
		},

		"75%": {
			opacity: 1,
		},
		"90%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0,
		},
	},
	animation: "fadeIn2 2s infinite",
}));

const Snack = styled(Snackbar)(({ theme }) => ({
	// [theme.breakpoints.down("md")]: {
	// 	bottom: "5rem",
	// 	borderRadius: "0.5rem",
	// },
}));

/* Dialog Transition */

const DialogTransition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>,
) {
	return (
		<Slide
			direction="up"
			ref={ref}
			{...props}
		/>
	);
});

const CreateServiceWithUploadTool = ({
	open,
	handleClose,
	dispatch,
	new_listing_url,
	session,
	newServiceData,
	pathname,
}: {
	open: boolean;
	handleClose: any;
	dispatch: Function;
	new_listing_url: StateInterface["new_listing_url"];
	is_business_profile?: boolean;
	session: StateInterface["redux_session"];
	newServiceData: StateInterface["new_service_data"];
	pathname?: any;
}) => {
	const theme = useTheme();

	const router = React.useRef(useRouter()).current;

	/* User business profiles */

	let businessProfiles: DbUserPage | null = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.pages
					? session.user.dbUser.pages[0]
					: null
				: null
			: null
		: null;

	// let isSubscriptionActive: boolean = businessProfiles
	// 	? businessProfiles.subscription_information
	// 		? businessProfiles.subscription_information.active
	// 			? true
	// 			: false
	// 		: false
	// 	: false;

	const businessProfilesCount = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.business_profiles_count
					? session.user.dbUser.business_profiles_count
					: 0
				: 0
			: 0
		: 0;

	const smDevice: boolean = useMediaQuery(theme.breakpoints.down("sm"));

	const [activeStep, setActiveStep] = React.useState(0);

	const [serviceTitle, setServiceTitle] = React.useState<string>("");

	const [businessProfileName, setBusinessProfileName] = React.useState<string>("");

	const [businessProfileType, setBusinessProfileType] = React.useState<string>("");

	const [createBusinessProfile, setcreateBusinessProfile] = React.useState<boolean>(false);

	const [loading, setLoading] = React.useState(false);

	const [newListing, setNewListing] = React.useState<boolean>(false);

	const [loadNext, setLoadNext] = React.useState<boolean>(false);

	const [alertModal, setAlertModal] = React.useState<boolean>(false);

	const [openSnackbar, setOpenSnackbar] = React.useState(false);

	const [imgPreview, setImgPreview] = React.useState<string>("");

	const [serviceNameError, setServiceNameError] = React.useState<boolean>(false);

	const [serviceProviderNameError, setServiceProviderNameError] = React.useState<boolean>(false);

	const [businessProfileNameError, setBusinessProfileNameError] = React.useState<boolean>(false);

	const [businessProfileTypeError, setBusinessProfileTypeError] = React.useState<boolean>(false);

	const [serviceTypeError, setServiceTypeError] = React.useState<boolean>(false);

	const [serviceSubTypeError, setServiceSubTypeError] = React.useState<boolean>(false);

	const [serviceOtherSubTypeError, setServiceOtherSubTypeError] = React.useState<boolean>(false);

	const [serviceMinPriceError, setServiceMinPriceError] = React.useState<boolean>(false);

	const [serviceMaxPriceError, setServiceMaxPriceError] = React.useState<boolean>(false);

	// const [serviceCount, setServiceCount] = React.useState<number>(0);

	// React.useEffect(() => {
	// 	const fetchData = async () => {
	// 		const reqUrl: string = `/api/fetch-business-profile-property-count?is_business_profile=true&business_profile_id=${businessProfiles ? (businessProfiles.page_id as string) : ""}`;
	// 		const res = await fetch(reqUrl, {
	// 			method: "GET",
	// 			headers: {
	// 				"Content-Type": "application/json",
	// 			},
	// 		}).then((res) => res.json());
	// 		setServiceCount(res.service_count);
	// 	};
	// 	fetchData();
	// }, [open]);

	// lets select the image with priority 0 if 0 not existing then select the first image
	React.useEffect(() => {
		if (newServiceData?.images.length > 0) {
			const image = newServiceData.images.find((img) => img.priority === 0);
			if (image) {
				setImgPreview(image.caption);
			} else {
				setImgPreview(newServiceData.images[0].caption);
			}
		}
	}, [newServiceData?.images]);

	// lets check if the 0th step is complete
	const stepZeroComplete: boolean =
		(activeStep != 0 && createBusinessProfile && (businessProfileName === "" || businessProfileType === "")) ||
		(businessProfiles === null && (businessProfileName === "" || businessProfileType === "")) ||
		serviceTitle === "" ||
		loading;

	// lets check if the 1st step is complete
	// Extract current location values
	const currentLatitude = newServiceData?.search_metrics.location_coordinates[0];
	const currentLongitude = newServiceData?.search_metrics.location_coordinates[1];

	// Extract previous location values (assuming location_data is available in scope)
	const previousLatitude = 1;
	const previousLongitude = 1;

	// Check if location value has changed

	const didLocationChange = currentLatitude !== previousLatitude || currentLongitude !== previousLongitude;

	const stepOneComplete: boolean = activeStep === 1 && !didLocationChange;

	const stepThreeComplete: boolean =
		activeStep === 3 && newServiceData?.images.length > 0 && !newServiceData?.image_upload_completed;

	// lets check if the 2nd step is complete

	const stepTwoComplete: boolean =
		activeStep === 2 &&
		(newServiceData.service_type.length === 0 ||
			newServiceData.service_subtype.length === 0 ||
			(newServiceData.service_subtype.includes("other") && newServiceData.other_service_subtype === "") ||
			(newServiceData.price.show_price &&
				(newServiceData.price.min === 0 || newServiceData.price.min === null) &&
				(newServiceData.price.max === 0 || newServiceData.price.max === null)));

	// lets check if the 3rd step is complete

	const stepThreeCompelte: boolean = activeStep === 3 && newServiceData?.images.length === 0;

	// lets create functions to handle the stepper
	const handleNext = () => {
		if (activeStep === 0) {
			if (
				(createBusinessProfile && (businessProfileName === "" || businessProfileType === "")) ||
				(businessProfiles === null && (businessProfileName === "" || businessProfileType === "")) ||
				serviceTitle === ""
			) {
				if (createBusinessProfile && businessProfileName === "") {
					setBusinessProfileNameError(true);
				}
				if (createBusinessProfile && businessProfileType === "") {
					setBusinessProfileTypeError(true);
				}
				if (serviceTitle === "") {
					setServiceNameError(true);
				}
			} else {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		} else if (activeStep === 1) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		} else if (activeStep === 2) {
			if (
				// newServiceData.service_provider.name === "" ||
				newServiceData.service_type.length === 0 ||
				newServiceData.service_subtype.length === 0 ||
				(newServiceData.service_subtype.includes("other") && newServiceData.other_service_subtype === "") ||
				(newServiceData.price.show_price &&
					(newServiceData.price.min === 0 || newServiceData.price.min === null) &&
					(newServiceData.price.max === 0 || newServiceData.price.max === null))
			) {
				// if (newServiceData.service_provider.name === "") {
				// 	setServiceProviderNameError(true);
				// }
				if (newServiceData.service_type.length === 0) {
					setServiceTypeError(true);
				}
				if (newServiceData.service_subtype.length === 0) {
					setServiceSubTypeError(true);
				}
				if (newServiceData.service_subtype.includes("other") && newServiceData.other_service_subtype === "") {
					setServiceOtherSubTypeError(true);
				}
				if (
					newServiceData.price.show_price &&
					(newServiceData.price.min === 0 || newServiceData.price.min === null) &&
					(newServiceData.price.max === 0 || newServiceData.price.max === null)
				) {
					if (newServiceData.price.min === 0 || newServiceData.price.min === null) {
						setServiceMinPriceError(true);
					}
					if (newServiceData.price.max === 0 || newServiceData.price.max === null) {
						setServiceMaxPriceError(true);
					}
				}
			} else {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	/* Create new service function */

	const handleClickCreateNewService = async () => {
		setLoading(true);
		setNewListing(true), setLoadNext(true);
		await dispatch(
			CreateNewServiceWithUploadToolThunk({
				sessionId: session ? (session.session_id ? session.session_id : "") : "",
				createNewBusinessProfile: createBusinessProfile,
				businessProfileId: createBusinessProfile ? "" : businessProfiles?.page_id || "",
				businessProfileName: createBusinessProfile ? businessProfileName : businessProfiles?.page_title || "",
				businessProfileType: createBusinessProfile
					? businessProfileType.toLowerCase()
					: businessProfiles?.page_type || "",
				userId: session?.user?.dbUser?._id || "",
				phoneNumber: session?.user?.dbUser?.phone || "",
				countryCode: session?.user?.dbUser?.country_code || "",
				serviceName: serviceTitle,
				serviceLocationName: newServiceData?.location.title,
				serviceLocationCoordinates: newServiceData?.search_metrics.location_coordinates,
				servicProviderName: newServiceData?.service_provider.name,
				serviceType: newServiceData?.service_type,
				serviceSubType: newServiceData?.service_subtype,
				serviceMinPrice: newServiceData?.price.min,
				serviceMaxPrice: newServiceData?.price.max,
				serviceImages: newServiceData?.images,
			}),
		);
		// if (newServiceData.currentPage === "navbar") {
		// 	const businessType: string = createBusinessProfile
		// 		? businessProfileType.toLowerCase()
		// 		: businessProfiles?.page_type;
		// 	window.open(`/${businessType}s/${new_listing_url}`, "_self");
		// } else {
		handleModalClose();
		// }

		(pathname && pathname.query && pathname.query.agent_post_service_button === "true") ||
		(pathname && pathname.query && pathname.query.builder_post_service_button === "true") ||
		(pathname && pathname.query && pathname.query.landowner_post_service_button === "true") ||
		(pathname && pathname.query && pathname.query.professional_post_service_button === "true")
			? router.push((process.env.PRODUCTION_URL || " ") + "/intro")
			: "";
	};

	React.useEffect(() => {
		const businessType: string = createBusinessProfile
			? businessProfileType.toLowerCase()
			: businessProfiles?.page_type || "";
		if (
			new_listing_url !== undefined &&
			new_listing_url !== "" &&
			newListing &&
			newServiceData.currentPage === "navbar"
		) {
			window.location.replace(`/${businessType}s/${new_listing_url}/?screen=listings`);
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [new_listing_url]);

	React.useEffect(() => {
		if (newServiceData?.snack_value) {
			setOpenSnackbar(true);
		}
	}, [newServiceData?.snack_value]);

	React.useEffect(() => {
		const businessProfilesCount = session
			? session.user
				? session.user.dbUser
					? session.user.dbUser.business_profiles_count
						? session.user.dbUser.business_profiles_count
						: 0
					: 0
				: 0
			: 0;

		if (businessProfilesCount === 0) {
			setcreateBusinessProfile(true);
		} else {
			setcreateBusinessProfile(false);
		}
	}, [session]);

	const handleModalClose = () => {
		setcreateBusinessProfile(false);
		setBusinessProfileName("");
		setBusinessProfileType("");
		setServiceTitle("");
		setLoading(false);
		handleClose();
		setActiveStep(0);
		dispatch(
			setCreateNewServiceDefaultLocationThunk({
				location_coordinates: [1, 1],
				address: "",
			}),
		);
		dispatch(setCreateNewServiceTypeThunk(""));
		dispatch(setCreateNewServiceSubTypeThunk([]));
		dispatch(setCreateNewServiceOtherSubTypeThunk(""));
		dispatch(setCreateNewServiceShowPriceThunk(false));
		dispatch(setCreateNewServiceMinPriceThunk(0));
		dispatch(setCreateNewServiceMaxPriceThunk(0));
		dispatch(setCreateNewServiceProviderNameThunk(""));
		dispatch(setCreateNewServiceUploadImagesThunk([]));
		setAlertModal(false);
		setLoadNext(false);
	};

	const stepperContent = (step: number) => {
		switch (step) {
			case 0:
				return (
					<CustomDiv
						sx={{
							flexDirection: "column",
							gap: "1rem",
							paddingTop: "1rem",
							direction: router.locale === "ar-AE" ? "rtl" : "ltr",
						}}
					>
						<ServiceNameContainer>
							{businessProfiles === undefined ? (
								<TextContainer sx={{ gap: "0rem" }}>
									<TextContainer sx={{ flexDirection: "row", justifyContent: "flrx-start", alignItems: "center" }}>
										<Typography variant="body1">
											{router.locale === "kn-IN"
												? "ನಿಮ್ಮ ಸೇವೆಯನ್ನು ಪೋಸ್ಟ್ ಮಾಡಲು ಹೊಸ ವ್ಯವಹಾರ ಪ್ರೊಫೈಲ್ ರಚಿಸೋಣ"
												: "Let's create a new business profile to post your service"}{" "}
										</Typography>
										<Tooltip
											title={
												router.locale === "kn-IN"
													? "ಒಮ್ಮೆ ರಚಿಸಿದ ನಂತರ, ನೀವು ಸೇವೆಯ ಹೆಸರನ್ನು ಬದಲಾಯಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ"
													: "Once created, you cannot change the service name"
											}
										>
											<InfoOutlined
												sx={{
													color: theme.palette.mode == "dark" ? "#FFFFFF" : "#0000008A",
													height: "1.25rem",
													width: "1.25rem",
												}}
											/>
										</Tooltip>
									</TextContainer>
									<TextField
										label={router.locale === "kn-IN" ? "ವ್ಯವಹಾರದ ಹೆಸರು*" : "Business Name*"}
										required={true}
										disabled={loading}
										value={businessProfileName}
										autoComplete="off"
										variant="outlined"
										onChange={(event: any) => {
											setBusinessProfileName(event.target.value);
											setBusinessProfileNameError(false);
										}}
										sx={{
											width: "100%",
											marginTop: "1.5rem",
											"& .MuiOutlinedInput-root": {
												borderRadius: "0.75rem",
											},
										}}
										error={businessProfileNameError}
										helperText={businessProfileNameError ? "Business name is required" : ""}
									/>

									<FormControl
										variant="outlined"
										fullWidth
										sx={{
											marginTop: "1rem",
											borderRadius: "0.75rem",
											"& .MuiOutlinedInput-root": {
												borderRadius: "0.75rem",
											},
										}}
										error={businessProfileTypeError}
									>
										<InputLabel
											id="profile-type"
											sx={{ padding: "0rem" }}
										>
											{router.locale === "kn-IN" ? "ವ್ಯವಹಾರ ಪ್ರೊಫೈಲ್ ಪ್ರಕಾರ*" : "Business profile type*"}
										</InputLabel>
										<Select
											labelId="profile-type"
											label={router.locale === "kn-IN" ? "ವ್ಯವಹಾರ ಪ್ರೊಫೈಲ್ ಪ್ರಕಾರ*" : "Business profile type*"}
											required={true}
											disabled={loading}
											value={businessProfileType}
											sx={{
												width: "100%",
												borderRadius: "0.75rem",
												"& .MuiOutlinedInput-root": {
													borderRadius: "0.75rem",
												},
											}}
											onChange={(e) => {
												setBusinessProfileType(e.target.value);
												setBusinessProfileTypeError(false);
											}}
										>
											<MenuItem value="agent">{router.locale === "kn-IN" ? "ಏಜೆಂಟ್" : "Agent"}</MenuItem>
											<MenuItem value="developer">{router.locale === "kn-IN" ? "ಡೆವೆಲಪರ್" : "Developer"}</MenuItem>
											<MenuItem value="landowner">{router.locale === "kn-IN" ? "ಲ್ಯಾಂಡೋನೇರ್" : "Landowner"}</MenuItem>
											<MenuItem value="professional">
												{router.locale === "kn-IN" ? "ಸರ್ವಿಸ್ ಪ್ರೊಫೆಶನಲ್ಸ್" : "Service Professional"}
											</MenuItem>
										</Select>
										<FormHelperText>
											{businessProfileTypeError ? "Business profile type is required" : ""}
										</FormHelperText>
									</FormControl>
								</TextContainer>
							) : null}

							<TextContainer sx={{ gap: "1rem" }}>
								<TextContainer sx={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
									<Typography sx={{ fontSize: "1rem", fontWeight: 400, lineHeight: "150%" }}>
										{router.locale === "kn-IN" ? "ನಿಮ್ಮ ಸೇವೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ" : "Enter the name of your service"}
									</Typography>
									{/* <Tooltip
										title={
											router.locale === "kn-IN"
												? "ಒಮ್ಮೆ ರಚಿಸಿದ ನಂತರ, ನೀವು ಸೇವೆಯ ಹೆಸರನ್ನು ಬದಲಾಯಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ"
												: "Once created, you cannot change the service name"
										}
									>
										<InfoOutlined
											sx={{
												color: theme.palette.mode == "dark" ? "#FFFFFF" : "#0000008A",
												height: "1.25rem",
												width: "1.25rem",
											}}
										/>
									</Tooltip> */}
								</TextContainer>
								<TextField
									label={router.locale === "kn-IN" ? "ಸೇವೆಯ ಹೆಸರು*" : "Service Name*"}
									placeholder={router.locale === "kn-IN" ? "ಸೇವೆಯ ಹೆಸರು*" : "Enter service name"}
									required={true}
									disabled={
										loading ||
										(businessProfiles === undefined && (businessProfileName === "" || businessProfileType === ""))
									}
									value={serviceTitle}
									autoComplete="off"
									variant="outlined"
									onChange={(event: any) => {
										setServiceTitle(event.target.value);
										setServiceNameError(false);
									}}
									sx={{
										width: "100%",
										"& .MuiOutlinedInput-root": {
											borderRadius: "0.75rem",
										},
									}}
									error={serviceNameError}
									helperText={serviceNameError ? "Service name is required" : ""}
								/>
								{/* {businessProfiles === undefined ? null : (
									<Typography
										sx={{
											fontSize: "1rem",
											fontWeight: 400,
											lineHeight: "150%",
										}}
									>
										{router.locale === "kn-IN" ? " ಪೋಸ್ಟ್ ಮಾಡಲಾಗುತ್ತಿದೆ :" : "Posting as :"}{" "}
										{businessProfiles === undefined
											? businessProfileName
											: businessProfiles
												? businessProfiles.page_title
													? businessProfiles.page_title
													: ""
												: ""}
									</Typography>
								)} */}
							</TextContainer>
						</ServiceNameContainer>
					</CustomDiv>
				);
			case 1:
				return (
					<ServiceLocation
						dispatch={dispatch}
						newServiceDataLocationCoordinates={newServiceData?.search_metrics.location_coordinates}
						showMap={newServiceData ? (newServiceData.location.title.length > 0 ? true : false) : false}
						locationAddress={newServiceData?.location.title}
					/>
				);
			case 2:
				return (
					<ServiceDetails
						newServiceData={newServiceData}
						dispatch={dispatch}
						serviceTypeError={serviceTypeError}
						serviceSubTypeError={serviceSubTypeError}
						serviceMinPriceError={serviceMinPriceError}
						serviceMaxPriceError={serviceMaxPriceError}
						setServiceTypeError={setServiceTypeError}
						setServiceSubTypeError={setServiceSubTypeError}
						setServiceMinPriceError={setServiceMinPriceError}
						setServiceMaxPriceError={setServiceMaxPriceError}
						serviceProviderNameError={serviceProviderNameError}
						setServiceProviderNameError={setServiceProviderNameError}
						setServiceOtherSubTypeError={setServiceOtherSubTypeError}
						serviceOtherSubTypeError={serviceOtherSubTypeError}
					/>
				);
			case 3:
				return (
					<UploadImages
						newServiceData={newServiceData}
						dispatch={dispatch}
						session={session ? session : null}
						businessProfiles={businessProfiles as DbUserPage}
						isBusinessProfile={true}
						isNewBusinessProfile={createBusinessProfile}
					/>
				);
			case 4:
				return (
					<ServicePreview
						service_title={serviceTitle ? serviceTitle : ""}
						service_location={newServiceData?.location.title?.split(",")[0]}
						service_type={newServiceData?.service_type}
						service_subtype={newServiceData?.service_subtype}
						service_min_price={newServiceData?.price.min}
						service_max_price={newServiceData?.price.max}
						service_provider_name={newServiceData?.service_provider.name}
					/>
				);
		}
	};

	return (
		<React.Fragment>
			{/* alert dialog */}
			<Snack
				open={openSnackbar}
				autoHideDuration={3000}
				onClose={() => setOpenSnackbar(false)}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert
					onClose={() => setOpenSnackbar(false)}
					severity={newServiceData?.snack_text === "Error while creating service" ? "error" : "success"}
					sx={{ width: "100%", borderRadius: "0.75rem" }}
					variant="filled"
				>
					{newServiceData?.snack_text}
				</Alert>
			</Snack>
			<Dialog
				open={alertModal}
				maxWidth="sm"
				fullWidth={true}
				onClose={() => {
					setAlertModal(false);
				}}
			>
				<DialogTitle sx={{ fontSize: "1.25rem", fontWeight: 500, lineHeight: "160%" }}>
					{router.locale === "kn-IN" ? "ಸೇವೆ ಅಪ್ಲೋಡ್ ಮಾಡುವುದನ್ನು ನಿಲ್ಲಿಸಿ?" : "Close service upload ?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{router.locale === "kn-IN"
							? "ನೀವು ನಮೂದಿಸಿದ ಎಲ್ಲಾ ಉಳಿಸದ ಡೇಟಾ ತೆಗೆದುಹಾಕಲಾಗುತ್ತದೆ"
							: "All the unsaved data you entered will be removed"}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<BeegruButton
						onClick={handleModalClose}
						color="primary"
						variant="outlined"
					>
						{router.locale === "kn-IN" ? "ಹೌದು" : "Yes"}
					</BeegruButton>
					<BeegruButton
						onClick={() => {
							setAlertModal(false);
						}}
						color="primary"
						variant="contained"
					>
						{router.locale === "kn-IN" ? "ಇಲ್ಲ" : "No"}
					</BeegruButton>
				</DialogActions>
			</Dialog>

			<CustomDialog
				open={open}
				maxWidth="xs"
				fullWidth={true}
				fullScreen={smDevice}
				TransitionComponent={DialogTransition}
			>
				<CustomDialogTitle>
					<TextContainer sx={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
						<IconTitleRow>
							{!loading && activeStep > 0 ? (
								<IconButton
									aria-label="back"
									sx={{
										paddingLeft: "0rem",
									}}
									onClick={handleBack}
								>
									<ArrowBack fontSize="small" />
								</IconButton>
							) : null}

							<Typography variant="body1">
								{activeStep === 0
									? router.locale === "kn-IN"
										? "ಹೊಸ ಸೇವೆಯನ್ನು ರಚಿಸಿ"
										: "Create New Service"
									: activeStep === 1
										? router.locale === "kn-IN"
											? "ಸೇವೆಯ ಸ್ಥಳ"
											: "Service Location"
										: activeStep === 2
											? router.locale === "kn-IN"
												? "ಸೇವೆಯ ವಿವರಗಳು"
												: "Service Details"
											: activeStep === 3
												? router.locale === "kn-IN"
													? "ಚಿತ್ರಗಳನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಿ"
													: "Upload Images"
												: router.locale === "kn-IN"
													? "ಸೇವೆಯ ಪೂರ್ವದೃಶ್ಯ"
													: "Service Preview "}
							</Typography>
						</IconTitleRow>

						<IconButton
							aria-label="close"
							onClick={() => {
								activeStep === 0 ? handleModalClose() : setAlertModal(true);
								/* Post Service Button (Intro Page) */
								(pathname && pathname.query && pathname.query.agent_post_service_button === "true") ||
								(pathname && pathname.query && pathname.query.builder_post_service_button === "true") ||
								(pathname && pathname.query && pathname.query.landowner_post_service_button === "true") ||
								(pathname && pathname.query && pathname.query.professional_post_service_button === "true")
									? router.push((process.env.PRODUCTION_URL || " ") + "/intro")
									: "";
							}}
						>
							{loading ? null : <Close />}
						</IconButton>
					</TextContainer>

					<ProgressContainer>
						<CustomLinearProgress
							variant="determinate"
							value={(activeStep / 4) * 100}
						/>
					</ProgressContainer>
				</CustomDialogTitle>

				<CustomDialogContent
					sx={{
						justifyContent: activeStep === 4 ? "center" : "space-between",
						padding: activeStep === 2 ? "1rem 1rem 0rem 1rem" : "1rem 1rem 1rem 1rem",
					}}
				>
					{stepperContent(activeStep)}

					{activeStep === 2 ? (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
								width: "100%",
							}}
						>
							<Typography
								sx={{
									fontSize: "0.875rem",
									fontWeight: 400,
									lineHeight: "150%",
									color: theme.palette.mode === "dark" ? "#FFFFFF" : "#9E9E9E",
								}}
							>
								{router.locale === "kn-IN" ? "* ಗುರುತಿಸಲಾದ ಕ್ಷೇತ್ರಗಳು ಕಡ್ಡಾಯ" : "Fields marked with * are mandatory"}
							</Typography>
						</div>
					) : null}
				</CustomDialogContent>

				<CustomDiv
					sx={{
						padding: "0.5rem",
						borderRadius: smDevice ? "8px 8px 0px 0px" : "0px",
						boxShadow: smDevice
							? "rgba(0, 0, 0, 0.2) 0px 3px 3px 2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px"
							: "none",
					}}
				>
					{!loadNext ? (
						<TextContainer
							sx={{
								flexDirection: "row",
								width: "100%",
								justifyContent: smDevice ? "space-between" : "flex-end",
								alignItems: "center",
								gap: "1rem",
							}}
						>
							{/* {activeStep === 0 ? (
									<TextContainer sx={{ width: "8.75rem" }}></TextContainer>
								) : (
									<BeegruButton
										size="medium"
										flavor="primary"
										variant="text"
										onClick={handleBack}
										disabled={activeStep === 0 || loadNext}
										sx={{ width: "8.75rem" }}
									>
										{router.locale === "kn-IN" ? "ಹಿಂದೆ" : "Back"}
									</BeegruButton>
								)} */}

							{/* <StepDiv>
									<CustomTypography>{activeStep + 1}/5</CustomTypography>
								</StepDiv> */}

							<BeegruButton
								size="medium"
								flavor="primary"
								variant="contained"
								fullWidth={smDevice}
								onClick={activeStep === 4 ? handleClickCreateNewService : handleNext}
								disabled={stepOneComplete || stepThreeComplete}
								sx={{ minWidth: "8.75rem" }}
							>
								{activeStep === 3
									? router.locale === "kn-IN"
										? "ಪೂರ್ವದೃಶ್ಯ"
										: "Preview"
									: activeStep === 4
										? router.locale === "kn-IN"
											? "ಪ್ರಕಟಿಸಿ"
											: "Publish"
										: router.locale === "kn-IN"
											? "ಮುಂದೆ"
											: "Next"}
							</BeegruButton>
						</TextContainer>
					) : (
						<TextContainer sx={{ flexDirection: "row", width: "100%", justifyContent: "flex-end", gap: "1rem" }}>
							<Typography
								variant="body2"
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
									gap: "0.25rem",
									color: "primary.main",
								}}
							>
								{router.locale === "kn-IN" ? "ಸೇವೆಯನ್ನು ರಚಿಸಲಾಗುತ್ತಿದೆ" : "Creating service"}
								<Circle>.</Circle>
								<Circle1>.</Circle1>
								<Circle2>.</Circle2>
							</Typography>
							<CircularProgress size={24} />
						</TextContainer>
					)}
				</CustomDiv>
			</CustomDialog>
		</React.Fragment>
	);
};

export default CreateServiceWithUploadTool;
