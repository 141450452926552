/* Common Imports */

import { styled, useMediaQuery } from "@mui/system";
import React from "react";

/* Redux Imports */

import { ObjectId } from "mongoose";
import StateInterface from "../../../redux-magic/state-interface";
import {
	createNewPropertyWithUploadToolThunk,
	setCreateNePropertyMinBalconiesThunk,
	setCreateNewePropertyPossessionDateThunk,
	setCreateNewPropertyAgeThunk,
	setCreateNewPropertyAvailabiltyThunk,
	setCreateNewPropertyDefaultLocationThunk,
	setCreateNewPropertyFloorNoThunk,
	setCreateNewPropertyFurnishingThunk,
	setCreateNewPropertyLandAreaThunk,
	setCreateNewPropertyLandAreaUnitThunk,
	setCreateNewPropertyLandFacingThunk,
	setCreateNewPropertyMaxBalconiesThunk,
	setCreateNewPropertyMaxBathroomsThunk,
	setCreateNewPropertyMaxBedroomsThunk,
	setCreateNewPropertyMaxCarpetAreaThunk,
	setCreateNewPropertyMaxFloorsThunk,
	setCreateNewPropertyMaxPriceThunk,
	setCreateNewPropertyMaxSuperBuiltUpAreaThunk,
	setCreateNewPropertyMinBathroomsThunk,
	setCreateNewPropertyMinBedroomsThunk,
	setCreateNewPropertyMinCarpetAreaThunk,
	setCreateNewPropertyMinPriceThunk,
	setCreateNewPropertyMinSuperBuiltUpAreaThunk,
	setCreateNewPropertyNoOfSeatsThunk,
	setCreateNewPropertyNoOfUnitsThunk,
	setCreateNewPropertyOverviewThunk,
	setCreateNewPropertyPlotDimensionsThunk,
	setCreateNewPropertySecurityDepositThunk,
	setCreateNewPropertySubTypeThunk,
	setCreateNewPropertySuperBuiltUpAreaUnitThunk,
	setCreateNewPropertyTransactionTypeThunk,
	setCreateNewPropertyTypeThunk,
	setCreateNewPropertyUnitFacingThunk,
	setCreateNewPropertyUploadImagesThunk,
	setPropertyImagesUploadCompletedThunk,
} from "../../../redux-magic/store";
import { DbUserPage } from "../../../redux-magic/sub-interfaces/user-interface";

/* Component Imports */

import {
	Alert,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormHelperText,
	IconButton,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	Slide,
	Snackbar,
	TextField,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import BeegruButton from "../../common-components/buttons/BeegruButton";
import PropertyConfig from "./sub-components/CreatePropertySubComponents/PropertyConfig";
import PropertyDetails from "./sub-components/CreatePropertySubComponents/PropertyDetails";
import PropertyLocation from "./sub-components/CreatePropertySubComponents/PropertyLocation";
import PropertyOverview from "./sub-components/CreatePropertySubComponents/PropertyOverview";
import PropertyPreview from "./sub-components/CreatePropertySubComponents/PropertyPreview";
import UploadImages from "./sub-components/CreatePropertySubComponents/UploadImages";

/* Icon Imports */

import { ArrowBack, Close, InfoOutlined } from "@mui/icons-material";

/* Library Function Imports */

import { TransitionProps } from "@mui/material/transitions";
import { useRouter } from "next/router";
import { security_deposit } from "../../../lib/data/search-filters";
import { property_images } from "../../../redux-magic/sub-interfaces/new-property-data-interface";

const Circle = styled(Typography)(({ theme }) => ({
	"@keyframes fadeIn": {
		"0%": {
			opacity: 0,
			// content: `${text[0]}`,
		},
		"25%": {
			opacity: 1,
		},
		"90%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0,
		},
	},
	animation: "fadeIn 2s infinite",
}));
const Circle1 = styled(Typography)(({ theme }) => ({
	"@keyframes fadeIn1": {
		"0%": {
			opacity: 0,
			// content: `${text[2]}`,
		},
		"50%": {
			opacity: 1,
			// content: `${text[0]}`,
		},
		"90%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0,
		},
	},
	animation: "fadeIn1 2s infinite",
}));
const Circle2 = styled(Typography)(({ theme }) => ({
	"@keyframes fadeIn2": {
		"0%": {
			opacity: 0,
			// content: `${text[0]}`,
		},

		"75%": {
			opacity: 1,
			// content: `${text[3]}`,
		},
		"90%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0,
		},
	},
	animation: "fadeIn2 2s infinite",
}));

const CustomDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialog-paper": {
		[theme.breakpoints.down("sm")]: {
			borderRadius: "0rem",
			minHeight: "100%",
		},
		minHeight: "700px",
		maxHeight: "100%",
		borderRadius: "1rem",
		background: theme.palette.background.paper,
	},
}));

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	fontSize: "1.25rem",
	fontWeight: 500,
	lineHeight: "106%",
	background: theme.palette.background.paper,
	gap: "0rem",
	padding: "1rem ",
	borderRadius: "1rem 1rem 0rem 0rem",
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
	padding: "1rem",
	background: theme.palette.background.paper,
	borderRadius: "0rem 0rem 1rem 1rem",
	overflow: "scroll",
}));

const CustomDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

// const StepDiv = styled("div")(({ theme }) => ({
// 	display: "flex",
// 	flexDirection: "row",
// 	justifyContent: "center",
// 	alignItems: "center",
// 	borderRadius: "2.5rem",
// 	background: theme.palette.mode == "dark" ? "#FFFFFF" : "var(--Text-New-Secondary, #666)",
// 	padding: "0rem 0.5rem",
// 	gap: "0.5rem",
// 	height: "1.5rem",
// 	width: "2.25rem",
// }));

// const CustomTypography = styled(Typography)(({ theme }) => ({
// 	fontSize: "0.75rem",
// 	fontWeight: 400,
// 	color: theme.palette.mode == "dark" ? "#212121" : "#ffffff",
// 	padding: "0rem",
// }));

const PropertyNameContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	gap: "2rem",
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	gap: "0.25rem",
}));

const Snack = styled(Snackbar)(({ theme }) => ({
	// [theme.breakpoints.down("md")]: {
	// 	bottom: "5rem",
	// 	borderRadius: "0.5rem",
	// },
}));

const ProgressContainer = styled("div")(({ theme }) => ({
	width: "100%",
	padding: "0.75rem 0rem 0rem 0rem ",
}));

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
	width: "100%",
	height: "0.25rem",
	borderRadius: "1rem",
}));

const IconTitleRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0rem",
	height: "auto",
}));

/* Dialog Transition */

const DialogTransition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>,
) {
	return (
		<Slide
			direction="up"
			ref={ref}
			{...props}
		/>
	);
});

interface security_deposit {
	min: number;
	max: number;
	negotiable: boolean;
	currency: string;
}

interface Area {
	label: string;
	enableLabel: boolean;
	land_area: {
		min: { ft: number; mt: number; acre: number };
		max: { ft: number; mt: number; acre: number };
	};
	super_built_up_area: {
		min: { ft: number; mt: number; acre: number };
		max: { ft: number; mt: number; acre: number };
	};
	carpet_area: {
		min: { ft: number; mt: number; acre: number };
		max: { ft: number; mt: number; acre: number };
	};
	karab_area: {
		min: { ft: number; mt: number; acre: number };
		max: { ft: number; mt: number; acre: number };
	};
}

interface BHK {
	min: number;
	max: number;
	label: string;
	enableLabel: boolean;
}

type RequestBody = {
	is_business_profile: boolean;
	business_profile_id: string | ObjectId;
};

const CreatePropertyWithUploadTool = ({
	open,
	handleClose,
	dispatch,
	new_listing_url,
	session,
	newPropertyData,
	pathname,
}: {
	open: boolean;
	handleClose: any;
	dispatch: Function;
	new_listing_url: StateInterface["new_listing_url"];
	session: StateInterface["redux_session"];
	newPropertyData: StateInterface["new_property_data"];
	pathname?: any;
}) => {
	const theme = useTheme();
	const router = React.useRef(useRouter()).current;

	/* User business profiles */

	let businessProfiles: DbUserPage | null = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.pages
					? session.user.dbUser.pages[0]
					: null
				: null
			: null
		: null;

	const smDevice: boolean = useMediaQuery(theme.breakpoints.down("sm"));

	const [activeStep, setActiveStep] = React.useState(0);

	const [propertyTitle, setPropertyTitle] = React.useState<string>("");

	const [businessProfileName, setBusinessProfileName] = React.useState<string>("");

	const [businessProfileType, setBusinessProfileType] = React.useState<string>("");

	const [createBusinessProfile, setcreateBusinessProfile] = React.useState<boolean>(false);

	const [loading, setLoading] = React.useState(false);

	const [newListing, setNewListing] = React.useState<boolean>(false);

	const [loadNext, setLoadNext] = React.useState<boolean>(false);

	const [alertModal, setAlertModal] = React.useState<boolean>(false);

	const [openSnackbar, setOpenSnackbar] = React.useState(false);

	const [propertyNameError, setPropertyNameError] = React.useState<boolean>(false);

	const [businessProfileNameError, setBusinessProfileNameError] = React.useState<boolean>(false);

	const [businessProfileTypeError, setBusinessProfileTypeError] = React.useState<boolean>(false);

	const [transactionTypeError, setTransactionTypeError] = React.useState<boolean>(false);

	const [propertyCategoryError, setPropertyCategoryError] = React.useState<boolean>(false);

	const [propertyTypeError, setPropertyTypeError] = React.useState<boolean>(false);

	const [priceMinError, setPriceMinError] = React.useState<boolean>(false);

	const [priceMaxError, setPriceMaxError] = React.useState<boolean>(false);

	const [availabilityError, setAvailabilityError] = React.useState<boolean>(false);

	const [possessionDateError, setPossessionDateError] = React.useState<boolean>(false);

	const [propertyBHKminError, setPropertyBHKminError] = React.useState<boolean>(false);

	const [propertyBHKmaxError, setPropertyBHKmaxError] = React.useState<boolean>(false);

	const [propertyNoOfSeatsError, setPropertyNoOfSeatsError] = React.useState<boolean>(false);

	const [propertyNoOfUnitsError, setPropertyNoOfUnitsError] = React.useState<boolean>(false);

	const [propertyPlotDimensionsError, setPropertyPlotDimensionsError] = React.useState<boolean>(false);

	const [propertyLandAreaError, setPropertyLandAreaError] = React.useState<boolean>(false);

	const [propertySuperBuiltUpMinAreaError, setPropertySuperBuiltUpMinAreaError] = React.useState<boolean>(false);

	const [propertySuperBuiltUpMaxAreaError, setPropertySuperBuiltUpMaxAreaError] = React.useState<boolean>(false);

	// lets check if the 0th step is complete
	const stepZeroComplete: boolean =
		(activeStep != 0 && createBusinessProfile && (businessProfileName === "" || businessProfileType === "")) ||
		(businessProfiles === null && (businessProfileName === "" || businessProfileType === "")) ||
		propertyTitle === "" ||
		loading;

	// lets check if the 1st step is complete
	// Extract current location values
	const currentLatitude = newPropertyData?.search_metrics.location_coordinates[0];
	const currentLongitude = newPropertyData?.search_metrics.location_coordinates[1];

	// Extract previous location values (assuming location_data is available in scope)
	const previousLatitude = 1;
	const previousLongitude = 1;

	// Check if location value has changed
	const didLocationChange = currentLatitude !== previousLatitude || currentLongitude !== previousLongitude;

	const stepOneComplete: boolean = activeStep === 1 && !didLocationChange;

	const stepFiveCompleted: boolean =
		activeStep === 5 && newPropertyData?.images.length > 0 && !newPropertyData?.image_upload_completed;
	// lets create functions to handle the stepper
	const handleNext = () => {
		if (activeStep === 0) {
			if (
				(createBusinessProfile && (businessProfileName === "" || businessProfileType === "")) ||
				(businessProfiles === null && (businessProfileName === "" || businessProfileType === "")) ||
				propertyTitle === "" ||
				loading
			) {
				if (createBusinessProfile && businessProfileName === "") {
					setBusinessProfileNameError(true);
				}
				if (createBusinessProfile && businessProfileType === "") {
					setBusinessProfileTypeError(true);
				}
				if (propertyTitle === "") {
					setPropertyNameError(true);
				}
			} else {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		} else if (activeStep === 1) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		} else if (activeStep === 2) {
			if (
				newPropertyData?.transaction_type !== "ofb" &&
				(newPropertyData?.transaction_type === "" ||
					newPropertyData?.property_category === "" ||
					newPropertyData?.property_type?.length === 0 ||
					newPropertyData?.price?.min === 0 ||
					newPropertyData?.price?.min === null ||
					newPropertyData?.price?.max === 0 ||
					newPropertyData?.price?.max === null ||
					newPropertyData?.price?.min > newPropertyData?.price?.max ||
					(!newPropertyData?.property_type?.includes("Land (Residential)") &&
						!newPropertyData?.property_type?.includes("Land (Commercial)") &&
						!newPropertyData?.property_type?.includes("Land (Agricultural)") &&
						!newPropertyData?.property_type?.includes("Land (Industrial)") &&
						!newPropertyData?.property_type?.includes("Mining") &&
						newPropertyData?.availability?.length === 0) ||
					((newPropertyData?.availability?.includes("Under construction") ||
						newPropertyData?.availability?.includes("under_construction") ||
						newPropertyData?.availability?.includes("Select date") ||
						newPropertyData?.availability?.includes("select_date")) &&
						newPropertyData?.possession_date === ""))
			) {
				if (newPropertyData && newPropertyData.transaction_type === "") {
					setTransactionTypeError(true);
				}
				if (newPropertyData && newPropertyData.property_category === "") {
					setPropertyCategoryError(true);
				}
				if (newPropertyData && newPropertyData.property_type.length === 0) {
					setPropertyTypeError(true);
				}
				if (newPropertyData && newPropertyData.price.min === 0) {
					setPriceMinError(true);
				}
				if (newPropertyData && newPropertyData.price.max === 0) {
					setPriceMaxError(true);
				}
				if (
					!newPropertyData?.property_type?.includes("Land (Residential)") &&
					!newPropertyData?.property_type?.includes("Land (Commercial)") &&
					!newPropertyData?.property_type?.includes("Land (Agricultural)") &&
					!newPropertyData?.property_type?.includes("Land (Industrial)") &&
					!newPropertyData?.property_type?.includes("Mining") &&
					newPropertyData?.availability?.length === 0
				) {
					setAvailabilityError(true);
				}
				if (
					(newPropertyData?.availability?.includes("Under construction") ||
						newPropertyData?.availability?.includes("under_construction") ||
						newPropertyData?.availability?.includes("Select date") ||
						newPropertyData?.availability?.includes("select_date")) &&
					newPropertyData?.possession_date === ""
				) {
					setPossessionDateError(true);
				}
			} else {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		} else if (activeStep === 3) {
			if (
				(!newPropertyData?.property_type.includes("Plot") &&
					!newPropertyData?.property_type.includes("Farm Plot") &&
					!newPropertyData?.property_type.includes("Plot (Commercial)") &&
					!newPropertyData?.property_type.includes("Land (Residential)") &&
					!newPropertyData?.property_type.includes("Land (Commercial)") &&
					!newPropertyData?.property_type.includes("Land (Agricultural)") &&
					!newPropertyData?.property_type.includes("Land (Industrial)") &&
					!newPropertyData?.property_type.includes("Studio Apartment") &&
					!newPropertyData?.property_type.includes("Building") &&
					!newPropertyData?.property_type.includes("Shop") &&
					!newPropertyData?.property_type.includes("Office Space") &&
					!newPropertyData?.property_type.includes("Co-working") &&
					!newPropertyData?.property_type.includes("Warehouse") &&
					!newPropertyData?.property_type.includes("Factory") &&
					!newPropertyData?.property_type.includes("Mining") &&
					(newPropertyData?.bhk.min === 0 ||
						newPropertyData?.bhk.min === undefined ||
						newPropertyData?.bhk.max === 0 ||
						newPropertyData?.bhk.max === null ||
						newPropertyData?.bhk.min > newPropertyData?.bhk?.max)) ||
				/* Bathrooms */

				newPropertyData?.bathroom?.min > newPropertyData?.bathroom?.max ||
				/* No. of seats */

				(newPropertyData?.property_type.includes("Co-working") &&
					(newPropertyData?.no_of_seats.max === 0 || newPropertyData?.no_of_seats.max === null)) ||
				/* No. of units */

				(newPropertyData?.transaction_type == "buy" &&
					(newPropertyData?.property_type.includes("Apartment") ||
						newPropertyData?.property_type.includes("Plot") ||
						newPropertyData?.property_type.includes("Farm Plot") ||
						newPropertyData?.property_type.includes("Plot (Commercial)") ||
						newPropertyData?.property_type.includes("Villa") ||
						newPropertyData?.property_type.includes("Row House") ||
						newPropertyData?.property_type.includes("Builder Floor Apartment") ||
						newPropertyData?.property_type.includes("Studio Apartment") ||
						newPropertyData?.property_type.includes("Penthouse")) &&
					(newPropertyData?.no_of_units.max === 0 || newPropertyData?.no_of_units.max === null)) ||
				/* Plot Dimensions */

				((newPropertyData?.property_type.includes("Plot") ||
					newPropertyData?.property_type.includes("Farm Plot") ||
					newPropertyData?.property_type.includes("Plot (Commercial)")) &&
					newPropertyData?.plot_dimensions.length === 0) ||
				/* Land Area */

				((newPropertyData?.transaction_type == "buy" ||
					(newPropertyData?.transaction_type == "rent" &&
						(newPropertyData?.property_type.includes("Land (Residential)") ||
							newPropertyData?.property_type.includes("Land (Commercial)") ||
							newPropertyData?.property_type.includes("Land (Agricultural)") ||
							newPropertyData?.property_type.includes("Land (Industrial)") ||
							newPropertyData.property_type.includes("Mining")))) &&
					(newPropertyData?.area.land_area.max.acre === 0 || newPropertyData?.area.land_area.max.acre === null)) ||
				/* Super Built Up Area */

				(((newPropertyData?.transaction_type == "buy" &&
					newPropertyData?.property_type.includes("Independent House")) ||
					(newPropertyData?.transaction_type == "buy" && newPropertyData?.property_type.includes("Co-living/PG")) ||
					(newPropertyData?.transaction_type == "buy" && newPropertyData?.property_type.includes("Building")) ||
					(newPropertyData?.transaction_type == "buy" && newPropertyData?.property_type.includes("Shop")) ||
					(newPropertyData?.transaction_type == "buy" && newPropertyData?.property_type.includes("Office Space")) ||
					(newPropertyData?.transaction_type == "buy" && newPropertyData?.property_type.includes("Co-working")) ||
					(newPropertyData?.transaction_type == "buy" && newPropertyData?.property_type.includes("Warehouse")) ||
					(newPropertyData?.transaction_type == "buy" && newPropertyData?.property_type.includes("Factory")) ||
					(newPropertyData?.transaction_type == "rent" &&
						newPropertyData?.property_type.includes("Independent House")) ||
					(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Apartment")) ||
					(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Villa")) ||
					(newPropertyData?.transaction_type == "rent" &&
						newPropertyData?.property_type.includes("Builder Floor Apartment")) ||
					(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Penthouse")) ||
					(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Row House")) ||
					(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Plot")) ||
					(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Farm Plot")) ||
					(newPropertyData?.transaction_type == "rent" &&
						newPropertyData?.property_type.includes("Plot (Commercial)")) ||
					(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Farm House")) ||
					(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Warehouse")) ||
					(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Factory")) ||
					(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Building")) ||
					(newPropertyData?.transaction_type == "rent" && newPropertyData?.property_type.includes("Shop")) ||
					(newPropertyData?.transaction_type == "resale" &&
						newPropertyData?.property_type.includes("Independent House")) ||
					(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Co-living/PG")) ||
					(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Building")) ||
					(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Shop")) ||
					(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Office Space")) ||
					(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Co-working")) ||
					(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Plot")) ||
					(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Farm Plot")) ||
					(newPropertyData?.transaction_type == "resale" &&
						newPropertyData?.property_type.includes("Plot (Commercial)")) ||
					(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Farm House")) ||
					(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Warehouse")) ||
					(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Apartment")) ||
					(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Villa")) ||
					(newPropertyData?.transaction_type == "resale" &&
						newPropertyData?.property_type.includes("Builder Floor Apartment")) ||
					(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Penthouse")) ||
					(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Row House")) ||
					(newPropertyData?.transaction_type == "resale" && newPropertyData?.property_type.includes("Factory"))) &&
					(newPropertyData?.area.super_built_up_area.min.ft === 0 ||
						newPropertyData?.area.super_built_up_area.min.ft === null ||
						newPropertyData?.area.super_built_up_area.max.ft === 0 ||
						newPropertyData?.area.super_built_up_area.max.ft === null ||
						newPropertyData?.area.super_built_up_area.min.ft > newPropertyData?.area.super_built_up_area.max.ft))
			) {
				if (
					!newPropertyData?.property_type.includes("Plot") &&
					!newPropertyData?.property_type.includes("Farm Plot") &&
					!newPropertyData?.property_type.includes("Plot (Commercial)") &&
					!newPropertyData?.property_type.includes("Land (Residential)") &&
					!newPropertyData?.property_type.includes("Land (Commercial)") &&
					!newPropertyData?.property_type.includes("Land (Agricultural)") &&
					!newPropertyData?.property_type.includes("Land (Industrial)") &&
					!newPropertyData?.property_type.includes("Studio Apartment") &&
					!newPropertyData?.property_type.includes("Building") &&
					!newPropertyData?.property_type.includes("Shop") &&
					!newPropertyData?.property_type.includes("Office Space") &&
					!newPropertyData?.property_type.includes("Co-working") &&
					!newPropertyData?.property_type.includes("Warehouse") &&
					!newPropertyData?.property_type.includes("Factory") &&
					!newPropertyData?.property_type.includes("Mining") &&
					(newPropertyData?.bhk.min === 0 ||
						newPropertyData?.bhk.min === undefined ||
						newPropertyData?.bhk.max === 0 ||
						newPropertyData?.bhk.max === null)
				) {
					if (newPropertyData?.bhk.min === 0 || newPropertyData?.bhk.min === undefined) {
						setPropertyBHKminError(true);
					}
					if (newPropertyData?.bhk.max === 0 || newPropertyData?.bhk.max === null) {
						setPropertyBHKmaxError(true);
					}
					setPropertyBHKmaxError(true);
				}
				if (
					newPropertyData?.property_type.includes("Co-working") &&
					(newPropertyData?.no_of_seats.max === 0 || newPropertyData?.no_of_seats.max === null)
				) {
					setPropertyNoOfSeatsError(true);
				}
				if (
					newPropertyData?.transaction_type == "buy" &&
					(newPropertyData?.property_type.includes("Apartment") ||
						newPropertyData?.property_type.includes("Plot") ||
						newPropertyData?.property_type.includes("Farm Plot") ||
						newPropertyData?.property_type.includes("Plot (Commercial)") ||
						newPropertyData?.property_type.includes("Villa") ||
						newPropertyData?.property_type.includes("Row House") ||
						newPropertyData?.property_type.includes("Builder Floor Apartment") ||
						newPropertyData?.property_type.includes("Studio Apartment") ||
						newPropertyData?.property_type.includes("Penthouse")) &&
					(newPropertyData?.no_of_units.max === 0 || newPropertyData?.no_of_units.max === null)
				) {
					setPropertyNoOfUnitsError(true);
				}
				if (
					(newPropertyData?.property_type.includes("Plot") ||
						newPropertyData?.property_type.includes("Farm Plot") ||
						newPropertyData?.property_type.includes("Plot (Commercial)")) &&
					newPropertyData?.plot_dimensions.length === 0
				) {
					setPropertyPlotDimensionsError(true);
				}

				if (
					(newPropertyData.transaction_type == "buy" &&
						(newPropertyData.area.land_area.max.acre === 0 || newPropertyData.area.land_area.max.acre === null)) ||
					(newPropertyData?.transaction_type == "rent" &&
						(newPropertyData?.property_type.includes("Land (Residential)") ||
							newPropertyData?.property_type.includes("Land (Commercial)") ||
							newPropertyData?.property_type.includes("Land (Agricultural)") ||
							newPropertyData?.property_type.includes("Land (Industrial)") ||
							newPropertyData.property_type.includes("Mining")) &&
						(newPropertyData?.area.land_area.max.acre === 0 || newPropertyData?.area.land_area.max.acre === null))
				) {
					setPropertyLandAreaError(true);
				}

				if (
					((newPropertyData.transaction_type == "buy" && newPropertyData.property_type.includes("Independent House")) ||
						(newPropertyData.transaction_type == "buy" && newPropertyData.property_type.includes("Apartment")) ||
						(newPropertyData.transaction_type == "buy" && newPropertyData.property_type.includes("Co-living/PG")) ||
						(newPropertyData.transaction_type == "buy" && newPropertyData.property_type.includes("Building")) ||
						(newPropertyData.transaction_type == "buy" && newPropertyData.property_type.includes("Shop")) ||
						(newPropertyData.transaction_type == "buy" && newPropertyData.property_type.includes("Office Space")) ||
						(newPropertyData.transaction_type == "buy" && newPropertyData.property_type.includes("Co-working")) ||
						(newPropertyData.transaction_type == "buy" && newPropertyData.property_type.includes("Warehouse")) ||
						(newPropertyData.transaction_type == "buy" && newPropertyData.property_type.includes("Factory")) ||
						(newPropertyData.transaction_type == "rent" &&
							newPropertyData.property_type.includes("Independent House")) ||
						(newPropertyData.transaction_type == "rent" && newPropertyData.property_type.includes("Apartment")) ||
						(newPropertyData.transaction_type == "rent" && newPropertyData.property_type.includes("Villa")) ||
						(newPropertyData.transaction_type == "rent" &&
							newPropertyData.property_type.includes("Builder Floor Apartment")) ||
						(newPropertyData.transaction_type == "rent" && newPropertyData.property_type.includes("Penthouse")) ||
						(newPropertyData.transaction_type == "rent" && newPropertyData.property_type.includes("Row House")) ||
						(newPropertyData.transaction_type == "rent" && newPropertyData.property_type.includes("Plot")) ||
						(newPropertyData.transaction_type == "rent" && newPropertyData.property_type.includes("Farm Plot")) ||
						(newPropertyData.transaction_type == "rent" &&
							newPropertyData.property_type.includes("Plot (Commercial)")) ||
						(newPropertyData.transaction_type == "rent" && newPropertyData.property_type.includes("Farm House")) ||
						(newPropertyData.transaction_type == "rent" && newPropertyData.property_type.includes("Warehouse")) ||
						(newPropertyData.transaction_type == "rent" && newPropertyData.property_type.includes("Factory")) ||
						(newPropertyData.transaction_type == "rent" && newPropertyData.property_type.includes("Building")) ||
						(newPropertyData.transaction_type == "rent" && newPropertyData.property_type.includes("Shop")) ||
						(newPropertyData.transaction_type == "resale" &&
							newPropertyData.property_type.includes("Independent House")) ||
						(newPropertyData.transaction_type == "resale" && newPropertyData.property_type.includes("Co-living/PG")) ||
						(newPropertyData.transaction_type == "resale" && newPropertyData.property_type.includes("Building")) ||
						(newPropertyData.transaction_type == "resale" && newPropertyData.property_type.includes("Shop")) ||
						(newPropertyData.transaction_type == "resale" && newPropertyData.property_type.includes("Office Space")) ||
						(newPropertyData.transaction_type == "resale" && newPropertyData.property_type.includes("Co-working")) ||
						(newPropertyData.transaction_type == "resale" && newPropertyData.property_type.includes("Plot")) ||
						(newPropertyData.transaction_type == "resale" && newPropertyData.property_type.includes("Farm Plot")) ||
						(newPropertyData.transaction_type == "resale" &&
							newPropertyData.property_type.includes("Plot (Commercial)")) ||
						(newPropertyData.transaction_type == "resale" && newPropertyData.property_type.includes("Farm House")) ||
						(newPropertyData.transaction_type == "resale" && newPropertyData.property_type.includes("Warehouse")) ||
						(newPropertyData.transaction_type == "resale" && newPropertyData.property_type.includes("Apartment")) ||
						(newPropertyData.transaction_type == "resale" && newPropertyData.property_type.includes("Villa")) ||
						(newPropertyData.transaction_type == "resale" &&
							newPropertyData.property_type.includes("Builder Floor Apartment")) ||
						(newPropertyData.transaction_type == "resale" && newPropertyData.property_type.includes("Penthouse")) ||
						(newPropertyData.transaction_type == "resale" && newPropertyData.property_type.includes("Row House")) ||
						(newPropertyData.transaction_type == "resale" && newPropertyData.property_type.includes("Factory"))) &&
					(newPropertyData?.area.super_built_up_area.min.ft === 0 ||
						newPropertyData?.area.super_built_up_area.min.ft === null ||
						newPropertyData?.area.super_built_up_area.max.ft === 0 ||
						newPropertyData?.area.super_built_up_area.max.ft === null ||
						(newPropertyData?.area.super_built_up_area.min.ft !== 0 &&
							newPropertyData?.area.super_built_up_area.max.ft !== 0 &&
							newPropertyData?.area.super_built_up_area.min.ft > newPropertyData?.area.super_built_up_area.max.ft) ||
						isNaN(newPropertyData?.area.super_built_up_area.min.ft) ||
						isNaN(newPropertyData?.area.super_built_up_area.max.ft))
				) {
					if (
						newPropertyData?.area.super_built_up_area.min.ft === 0 ||
						newPropertyData?.area.super_built_up_area.min.ft === null
					) {
						setPropertySuperBuiltUpMinAreaError(true);
					}
					if (
						newPropertyData?.area.super_built_up_area.max.ft === 0 ||
						newPropertyData?.area.super_built_up_area.max.ft === null
					) {
						setPropertySuperBuiltUpMaxAreaError(true);
					}
					setPropertySuperBuiltUpMaxAreaError(true);
				}
			} else {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		} else if (activeStep === 4) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		} else if (activeStep === 5) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	/* Create new property function */
	const propertyImageObject = newPropertyData?.images.map((image: property_images) => {
		return {
			file_id: image.file_id,
			mime_type: image.mime_type,
			category: image.category,
			caption: image.caption,
			priority: image.priority,
		};
	});

	const handleClickCreateNewProperty = async () => {
		setLoading(true);
		setNewListing(true), setLoadNext(true);
		await dispatch(
			createNewPropertyWithUploadToolThunk({
				createNewBusinessProfile: createBusinessProfile,
				businessProfileId: createBusinessProfile ? "" : businessProfiles?.page_id || "",
				creatorIsBusinessProfile: true,
				businessProfileName: createBusinessProfile ? businessProfileName : businessProfiles?.page_title || "",
				businessProfileType: createBusinessProfile
					? businessProfileType.toLowerCase()
					: businessProfiles?.page_type || "",
				userId: session?.user?.dbUser?._id || "",
				phoneNumber: session?.user?.dbUser?.phone || "",
				countryCode: session?.user?.dbUser?.country_code || "",
				propertyName: propertyTitle,
				propertyLocationName: newPropertyData?.location.title,
				propertyLocationCoordinates: newPropertyData?.search_metrics.location_coordinates,
				propertyTransactionType: newPropertyData?.transaction_type,
				propertyCategory: newPropertyData?.property_category,
				propertyType: newPropertyData?.property_type,
				propertySubtype: newPropertyData?.property_subtype,
				propertyMinPrice: newPropertyData?.price.min,
				propertyMaxPrice: newPropertyData?.price.max,
				propertySecurityDeposit: newPropertyData?.security_deposit.max,
				propertyStatus: newPropertyData?.availability[0]?.toLowerCase().replace(/\s+/g, "_"),
				propertyPossessionDate: newPropertyData?.possession_date,
				propertyAge: newPropertyData?.property_age,
				propertyFacing: newPropertyData?.unit_facing,
				propertyFurnishing: newPropertyData?.furnished,
				propertyMinBedrooms: newPropertyData?.bhk.min,
				propertyMaxBedrooms: newPropertyData?.bhk.max,
				propertyMinBathrooms: newPropertyData?.bathroom.min,
				propertyMaxBathroom: newPropertyData?.bathroom.max,
				propertyNoOfBalconies: newPropertyData?.balconies.max,
				propertyNoOfSeats: newPropertyData?.no_of_seats.max,
				propertyNoOfUnits: newPropertyData?.no_of_units.max,
				propertyNoOfFloors: newPropertyData?.floors.max,
				propertyPlotDimensions: newPropertyData?.plot_dimensions,
				propertyLandAreaUnit: newPropertyData?.area.land_area.land_area_unit,
				propertyLandArea: newPropertyData?.area.land_area.max.acre,
				propertySuperBuiltUpAreaUnit: newPropertyData?.area.super_built_up_area.super_built_up_area_unit,
				propertyMinSuperBuiltUpArea: newPropertyData?.area.super_built_up_area.min.ft,
				propertyMaxSuperBuiltUpArea: newPropertyData?.area.super_built_up_area.max.ft,
				propertyIsCarpetAreaPercentage: true,
				propertyCarpetAreaPercentage: "0",
				propertyCarpetAreaUnit: newPropertyData?.area.carpet_area.carpet_area_unit,
				propertyMinCarpetArea: newPropertyData?.area.carpet_area.min.ft,
				propertyMaxCarpetArea: newPropertyData?.area.carpet_area.max.ft,
				propertyImages: propertyImageObject,
				propertyOverview: newPropertyData?.description,
				sessionId: session ? (session.session_id ? session.session_id : "") : "",
				locale: (router.locale as string) || "en-IN",
			}),
		);

		(pathname && pathname.query && pathname.query.agent_post_property_button === "true") ||
		(pathname && pathname.query && pathname.query.builder_post_property_button === "true") ||
		(pathname && pathname.query && pathname.query.landowner_post_property_button === "true")
			? router.push((process.env.PRODUCTION_URL || " ") + "/intro")
			: "";
	};

	const currentPage: String = newPropertyData ? newPropertyData.currentPage : "";
	const propertyCreator: string = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.pages
					? session.user.dbUser.pages.length > 0
						? session.user.dbUser.pages[0].page_slug
						: ""
					: ""
				: ""
			: ""
		: "";

	React.useEffect(() => {
		const businessType: string = createBusinessProfile
			? businessProfileType.toLowerCase()
			: businessProfiles?.page_type || "";
		if (
			new_listing_url !== undefined &&
			new_listing_url !== "" &&
			newListing &&
			(currentPage === "navbar" || createBusinessProfile || currentPage !== propertyCreator)
		) {
			// handleModalClose();
			window.location.replace(`/${businessType}s/${new_listing_url}?screen=listings`);
		} else if (
			new_listing_url !== undefined &&
			new_listing_url !== "" &&
			newListing &&
			currentPage === propertyCreator
		) {
			handleModalClose();
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [new_listing_url]);

	React.useEffect(() => {
		if (newPropertyData?.snack_property_value) {
			setOpenSnackbar(true);
		}
	}, [newPropertyData?.snack_property_value]);

	React.useEffect(() => {
		const businessProfilesCount = session
			? session.user
				? session.user.dbUser
					? session.user.dbUser.business_profiles_count
						? session.user.dbUser.business_profiles_count
						: 0
					: 0
				: 0
			: 0;

		if (businessProfilesCount === 0) {
			setcreateBusinessProfile(true);
		} else {
			setcreateBusinessProfile(false);
		}
	}, [session]);

	const handleModalClose = () => {
		setcreateBusinessProfile(false);
		setBusinessProfileName("");
		setBusinessProfileType("");
		setPropertyTitle("");
		setLoading(false);
		setLoadNext(false);
		handleClose();
		setActiveStep(0);
		dispatch(
			setCreateNewPropertyDefaultLocationThunk({
				location_coordinates: [1, 1],
				address: "",
			}),
		);
		dispatch(setCreateNewPropertyTransactionTypeThunk(""));
		dispatch(setCreateNewPropertyTypeThunk([""]));
		dispatch(setCreateNewPropertySubTypeThunk(""));
		dispatch(setCreateNewPropertyMinPriceThunk(0));
		dispatch(setCreateNewPropertyMaxPriceThunk(0));
		dispatch(setCreateNewPropertySecurityDepositThunk(0));
		dispatch(setCreateNewPropertyAvailabiltyThunk([""]));
		dispatch(setCreateNewePropertyPossessionDateThunk(""));
		dispatch(setCreateNewPropertyUnitFacingThunk([""]));
		dispatch(setCreateNewPropertyLandFacingThunk([""]));
		dispatch(setCreateNewPropertyAgeThunk(0));
		dispatch(setCreateNewPropertyPlotDimensionsThunk([]));
		dispatch(setCreateNewPropertyFurnishingThunk([""]));
		dispatch(setCreateNewPropertyLandAreaThunk(0));
		dispatch(setCreateNewPropertyLandAreaUnitThunk("acre"));
		dispatch(setCreateNewPropertyMaxBalconiesThunk(0));
		dispatch(setCreateNewPropertyMaxBathroomsThunk(0));
		dispatch(setCreateNewPropertyMaxBedroomsThunk(0));
		dispatch(setCreateNewPropertyMaxCarpetAreaThunk(0));
		dispatch(setCreateNewPropertyMaxFloorsThunk(0));
		dispatch(setCreateNewPropertyMaxSuperBuiltUpAreaThunk(0));
		dispatch(setCreateNePropertyMinBalconiesThunk(0));
		dispatch(setCreateNewPropertyMinBathroomsThunk(0));
		dispatch(setCreateNewPropertyMinBedroomsThunk(0));
		dispatch(setCreateNewPropertyMinCarpetAreaThunk(0));
		dispatch(setCreateNewPropertyMinSuperBuiltUpAreaThunk(0));
		dispatch(setCreateNewPropertyNoOfUnitsThunk(0));
		dispatch(setCreateNewPropertyNoOfSeatsThunk(0));
		dispatch(setCreateNewPropertyFloorNoThunk(0));
		dispatch(setCreateNewPropertySuperBuiltUpAreaUnitThunk("sqft"));
		dispatch(setCreateNewPropertyOverviewThunk(""));
		dispatch(setCreateNewPropertyUploadImagesThunk([]));
		dispatch(setPropertyImagesUploadCompletedThunk(false));
		// dispatch(setCreatePropertySelectedImagesUrlThunk([]));
		setAlertModal(false);
		setBusinessProfileNameError(false);
		setBusinessProfileTypeError(false);
		setTransactionTypeError(false);
		setPropertyNameError(false);
		setPropertyCategoryError(false);
		setPropertyTypeError(false);
		setPriceMinError(false);
		setPriceMaxError(false);
		setAvailabilityError(false);
		setPossessionDateError(false);
		setPropertyBHKminError(false);
		setPropertyBHKmaxError(false);
		setPropertyNoOfSeatsError(false);
		setPropertyNoOfUnitsError(false);
		setPropertyPlotDimensionsError(false);
		setPropertyLandAreaError(false);
		setPropertySuperBuiltUpMinAreaError(false);
		setPropertySuperBuiltUpMaxAreaError(false);
	};

	const localLang = {
		createNewBP:
			router.locale === "ar-AE"
				? "لنقم بإنشاء ملف عمل جديد لنشر عقارك."
				: router.locale === "kn-IN"
					? "ನಿಮ್ಮ  ಆಸ್ತಿಯನ್ನು ಪೋಸ್ಟ್ ಮಾಡಲು ಹೊಸ ವ್ಯವಹಾರ ಪ್ರೊಫೈಲ್ ರಚಿಸೋಣ"
					: "Let's create a new business profile to post your property",
		createNewBPSubTest:
			router.locale === "ar-AE"
				? "بمجرد إنشائه، لا يمكنك تغيير اسم العقار"
				: router.locale === "kn-IN"
					? "ಒಮ್ಮೆ ರಚಿಸಿದ ನಂತರ, ನೀವು ಆಸ್ತಿಯ ಹೆಸರನ್ನು ಬದಲಾಯಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ"
					: "Once created, you cannot change the property name",
		businessName:
			router.locale === "ar-AE" ? "اسم العمل*" : router.locale === "kn-IN" ? "ವ್ಯವಹಾರದ ಹೆಸರು*" : "Business name*",
		businessNameHelperText:
			router.locale === "ar-AE"
				? "اسم العمل لا يمكن أن يكون فارغًا."
				: router.locale === "kn-IN"
					? "ವ್ಯವಹಾರದ ಹೆಸರು ಖಾಲಿಯಾಗಿರಬಾರದು"
					: "Business name cannot be empty",
		businessProfileType:
			router.locale === "ar-AE"
				? "نوع ملف العمل*"
				: router.locale === "kn-IN"
					? "ವ್ಯವಹಾರ ಪ್ರೊಫೈಲ್ ಪ್ರಕಾರ*"
					: "Business profile type*",
		agent: router.locale === "ar-AE" ? "وكلاء العقارات" : router.locale === "kn-IN" ? "ಏಜೆಂಟ್" : "Agent",
		developper: router.locale === "ar-AE" ? "المطور" : router.locale === "kn-IN" ? "ಡೆವೆಲಪರ್" : "Builder",
		landOwner: router.locale === "ar-AE" ? "مالك الأرض" : router.locale === "kn-IN" ? "ಭೂಮಾಲೀಕ" : "Owner",
		BPTypeCannotBeEmpty:
			router.locale === "ar-AE"
				? "نوع ملف العمل لا يمكن أن يكون فارغًا."
				: router.locale === "kn-IN"
					? "ವ್ಯವಹಾರ ಪ್ರೊಫೈಲ್ ಪ್ರಕಾರ ಖಾಲಿಯಾಗಿರಬಾರದು"
					: "Business profile type cannot be empty",
		enterNamePfTHeProperty:
			router.locale === "ar-AE"
				? "أدخل اسم العقار الخاص بك"
				: router.locale === "kn-IN"
					? "ನಿಮ್ಮ ಆಸ್ತಿಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ"
					: "Enter the name of your property",
		propertyNameCannotBeChanged:
			router.locale === "ar-AE"
				? "بمجرد الإنشاء، لا يمكنك تغيير اسم العقار."
				: router.locale === "kn-IN"
					? "ಒಮ್ಮೆ ರಚಿಸಿದ ನಂತರ, ನೀವು ಆಸ್ತಿಯ ಹೆಸರನ್ನು ಬದಲಾಯಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ"
					: "Once created, you cannot change the property name",
		propertyName:
			router.locale === "ar-AE" ? "اسم العقار*" : router.locale === "kn-IN" ? "ಆಸ್ತಿಯ ಹೆಸರು*" : "Property name*",
		enterPropName:
			router.locale === "ar-AE"
				? "أدخل اسم العقار"
				: router.locale === "kn-IN"
					? "ಆಸ್ತಿಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ*"
					: "Enter property name",
		propNameCannotBeEmpty:
			router.locale === "ar-AE"
				? "لا يمكن أن يكون اسم العقار فارغًا"
				: router.locale === "kn-IN"
					? "ಆಸ್ತಿಯ ಹೆಸರು ಖಾಲಿಯಾಗಿರಬಾರದು"
					: "Property name cannot be empty",
		postingAs:
			router.locale === "ar-AE" ? "النشر كـ :" : router.locale === "kn-IN" ? " ಪೋಸ್ಟ್ ಮಾಡಲಾಗುತ್ತಿದೆ :" : "Posting as :",
		closePropertUpload:
			router.locale === "ar-AE"
				? "هل ترغب في إلغاء تحميل العقار؟"
				: router.locale === "kn-IN"
					? "ಸ್ಥಳದ ಅಪ್ಲೋಡ್ ಮುಚ್ಚಲಾ?"
					: "Cancel property upload?",
		unsavedDataWillBeLost:
			router.locale === "ar-AE"
				? "جميع البيانات غير المحفوظة ستفقد. هل أنت متأكد؟"
				: router.locale === "kn-IN"
					? "ಅಸುರಕ್ಷಿತ ಡೇಟಾ ನಷ್ಟವಾಗುತ್ತದೆ. ನಿಮಗೆ ಖಚಿತವೇ?"
					: "All the unsaved data will be lost. Are you sure ?",
		yes: router.locale === "ar-AE" ? "نعم" : router.locale === "kn-IN" ? "ಹೌದು" : "Yes",
		no: router.locale === "ar-AE" ? "لا" : router.locale === "kn-IN" ? "ಬೇಡ" : "No",
		creatingProperty:
			router.locale === "ar-AE"
				? "جاري إنشاء العقار"
				: router.locale === "kn-IN"
					? "ಆಸ್ತಿ ರಚಿಸುತ್ತಿದೆ"
					: "Creating property",
		createNewProperty:
			router.locale === "ar-AE"
				? "إنشاء عقار جديد"
				: router.locale === "kn-IN"
					? "ಹೊಸ ಆಸ್ತಿ ರಚಿಸಿ"
					: "Create new property",
		propertyLocation:
			router.locale === "ar-AE" ? "موقع العقار" : router.locale === "kn-IN" ? "ಆಸ್ತಿಯ ಸ್ಥಳ" : "Property location",
		propertyDetails:
			router.locale === "ar-AE" ? "تفاصيل العقار" : router.locale === "kn-IN" ? "ಆಸ್ತಿಯ ವಿವರಗಳು" : "Property details",
		propertyConfiguration:
			router.locale === "ar-AE"
				? "تكوين العقار"
				: router.locale === "kn-IN"
					? "ಆಸ್ತಿಯ ಸಂರಚನೆ"
					: "Property configuration",
		addOverview:
			router.locale === "ar-AE" ? "أضف نظرة عامة" : router.locale === "kn-IN" ? "ಸಾರಾಂಶ ಸೇರಿಸಿ" : "Add overview",
		uploadImages:
			router.locale === "ar-AE"
				? "رفع الصور"
				: router.locale === "kn-IN"
					? "ಚಿತ್ರಗಳನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಿ"
					: "Upload images",
		propertyPreview:
			router.locale === "ar-AE" ? "معاينة العقار" : router.locale === "kn-IN" ? "ಆಸ್ತಿಯ ಮುನ್ನೋಟ" : "Property preview",
		fieldsCannotBeEmpty:
			router.locale === "ar-AE"
				? "الحقول المعلمة بـ * هي حقول إلزامية."
				: router.locale === "kn-IN"
					? "* ಗುರುತು ಮಾಡಿದ ಕ್ಷೇತ್ರಗಳು ಕಡ್ಡಾಯವಾಗಿವೆ"
					: "Fields marked with * are mandatory",
		back: router.locale === "ar-AE" ? "رجوع" : router.locale === "kn-IN" ? "ಹಿಂದೆ" : "Back",
		preview: router.locale === "ar-AE" ? "معاينة" : router.locale === "kn-IN" ? "ಮುನ್ನೋಟ" : "Preview",
		publish: router.locale === "ar-AE" ? "نشر" : router.locale === "kn-IN" ? "ಪ್ರಕಟಿಸಿ" : "Publish",
		next: router.locale === "ar-AE" ? "التالي" : router.locale === "kn-IN" ? "ಮುಂದೆ" : "Next",
		business_name_placeholder:
			router.locale === "ar-AE"
				? "مثال: إعمار "
				: router.locale === "kn-IN"
					? "ಉದಾ: ಪ್ರೆಸ್ಟೀಜ್ ಗ್ರೂಪ್"
					: "Eg: Prestige Group",
		property_name_placeholder:
			router.locale === "ar-AE"
				? "مثال: فيلا الجميرا"
				: router.locale === "kn-IN"
					? "ಉದಾ: ಪ್ರೆಸ್ಟೀಜ್ ಟ್ರಾನ್ಕ್ವಿಲಿಟಿ"
					: "Eg: Prestige Tranquility",
	};

	/* Intercept back click/popstate and prevent default */

	// React.useEffect(() => {
	// 	const handlePopState = (event: PopStateEvent) => {
	// 		event.preventDefault();
	// 		if (activeStep === 0) {
	// 			setAlertModal(true);
	// 		} else {
	// 			handleBack();
	// 		}
	// 	};

	// 	const pushStateWithCustomHandler = () => {
	// 		// Push a new state to the history stack to ensure popstate is triggered
	// 		window.history.pushState({}, "");
	// 	};

	// 	// Add event listeners
	// 	window.addEventListener("popstate", handlePopState);
	// 	pushStateWithCustomHandler(); // Push an initial state to the history stack

	// 	return () => {
	// 		// Cleanup event listeners
	// 		window.removeEventListener("popstate", handlePopState);
	// 	};
	// }, []);

	const stepperContent = (step: number) => {
		switch (step) {
			case 0:
				return (
					<CustomDiv
						sx={{
							flexDirection: "column",
							gap: "1rem",
							paddingTop: "1rem",
							direction: router.locale === "ar-AE" ? "rtl" : "ltr",
						}}
					>
						<PropertyNameContainer>
							{businessProfiles === undefined ? (
								<TextContainer sx={{ gap: "0rem" }}>
									<TextContainer sx={{ flexDirection: "row", justifyContent: "flrx-start", alignItems: "center" }}>
										<Typography variant="body1">{localLang.createNewBP} </Typography>
										<Tooltip title={localLang.createNewBPSubTest}>
											<InfoOutlined
												sx={{
													color: theme.palette.mode == "dark" ? "#FFFFFF" : "#0000008A",
													height: "1.25rem",
													width: "1.25rem",
												}}
											/>
										</Tooltip>
									</TextContainer>

									<TextField
										label={localLang.businessName}
										required={true}
										disabled={loading}
										value={businessProfileName}
										placeholder={localLang.business_name_placeholder}
										autoComplete="off"
										variant="outlined"
										onChange={(event: any) => {
											setBusinessProfileName(event.target.value);
											setBusinessProfileNameError(false);
										}}
										sx={{
											width: "100%",
											marginTop: "1.5rem",
											"& .MuiOutlinedInput-root": {
												borderRadius: "0.75rem",
											},
										}}
										error={businessProfileNameError}
										helperText={businessProfileNameError ? localLang.businessNameHelperText : ""}
									/>

									<FormControl
										variant="outlined"
										fullWidth
										sx={{
											marginTop: "1rem",
											borderRadius: "0.75rem",
											"& .MuiOutlinedInput-root": {
												borderRadius: "0.75rem",
											},
										}}
										error={businessProfileTypeError}
									>
										<InputLabel
											id="profile-type"
											sx={{ padding: "0rem" }}
										>
											{localLang.businessProfileType}
										</InputLabel>
										<Select
											labelId="profile-type"
											label={localLang.businessProfileType}
											required={true}
											disabled={loading}
											value={businessProfileType}
											sx={{
												width: "100%",
												borderRadius: "0.75rem",
												"& .MuiOutlinedInput-root": {
													borderRadius: "0.75rem",
												},
											}}
											onChange={(e) => {
												setBusinessProfileType(e.target.value);
											}}
										>
											<MenuItem value="agent">{localLang.agent}</MenuItem>
											<MenuItem value="developer">{localLang.developper}</MenuItem>
											<MenuItem value="landowner">{localLang.landOwner}</MenuItem>
										</Select>
										<FormHelperText>{businessProfileTypeError ? localLang.BPTypeCannotBeEmpty : ""}</FormHelperText>
									</FormControl>
								</TextContainer>
							) : null}

							<TextContainer sx={{ gap: "1rem" }}>
								<TextContainer sx={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
									<Typography sx={{ fontSize: "1rem", fontWeight: 400, lineHeight: "150%" }}>
										{localLang.enterNamePfTHeProperty}
									</Typography>
									{/* <Tooltip title={localLang.propertyNameCannotBeChanged}>
										<InfoOutlined
											sx={{
												color: theme.palette.mode == "dark" ? "#FFFFFF" : "#0000008A",
												height: "1.25rem",
												width: "1.25rem",
											}}
										/>
									</Tooltip> */}
								</TextContainer>
								<TextField
									label={localLang.propertyName}
									placeholder={localLang.property_name_placeholder}
									required={true}
									disabled={
										loading ||
										(businessProfiles === undefined && (businessProfileName === "" || businessProfileType === ""))
									}
									value={propertyTitle}
									autoComplete="off"
									variant="outlined"
									onChange={(event: any) => {
										setPropertyNameError(false);
										setPropertyTitle(event.target.value);
									}}
									sx={{
										width: "100%",
										"& .MuiOutlinedInput-root": {
											borderRadius: "0.75rem",
										},
									}}
									error={propertyNameError}
									helperText={propertyNameError ? localLang.propNameCannotBeEmpty : ""}
								/>
								{/* {businessProfiles === undefined ? null : (
									<Typography
										sx={{
											fontSize: "1rem",
											fontWeight: 400,
											lineHeight: "150%",
										}}
									>
										{localLang.postingAs}{" "}
										{businessProfiles === undefined
											? businessProfileName
											: businessProfiles
												? businessProfiles.page_title
													? businessProfiles.page_title
													: ""
												: ""}
									</Typography>
								)} */}
							</TextContainer>
						</PropertyNameContainer>
					</CustomDiv>
				);
			case 1:
				return (
					<PropertyLocation
						dispatch={dispatch}
						newPropertyDataLocationCoordinates={newPropertyData?.search_metrics?.location_coordinates}
						showMap={newPropertyData ? (newPropertyData.location.title.length > 0 ? true : false) : false}
						locationAddress={newPropertyData?.location.title}
					/>
				);
			case 2:
				return (
					<PropertyDetails
						property_transaction_type={newPropertyData?.transaction_type}
						property_category_type={newPropertyData?.property_category}
						property_type={newPropertyData?.property_type}
						property_subtype={newPropertyData?.property_subtype}
						property_min_price={newPropertyData?.price?.min}
						property_max_price={newPropertyData?.price?.max}
						property_security_deposit={newPropertyData?.security_deposit as security_deposit}
						property_availability={newPropertyData?.availability}
						property_possession_date={newPropertyData?.possession_date}
						property_unit_facing={
							newPropertyData?.unit_facing
								? newPropertyData?.unit_facing.map(
										(element: string) => element.charAt(0).toUpperCase() + element.slice(1).replace(/_/g, " "),
									)
								: []
						}
						property_land_facing={
							newPropertyData?.land_facing
								? newPropertyData?.land_facing.map(
										(element: string) => element.charAt(0).toUpperCase() + element.slice(1).replace(/_/g, " "),
									)
								: []
						}
						property_age={newPropertyData?.property_age}
						dispatch={dispatch}
						transactionTypeError={transactionTypeError}
						setTransactionTypeError={setTransactionTypeError}
						propertyCategoryError={propertyCategoryError}
						setPropertyCategoryError={setPropertyCategoryError}
						propertyTypeError={propertyTypeError}
						setPropertyTypeError={setPropertyTypeError}
						priceMinError={priceMinError}
						setPriceMinError={setPriceMinError}
						priceMaxError={priceMaxError}
						setPriceMaxError={setPriceMaxError}
						availabilityError={availabilityError}
						setAvailabilityError={setAvailabilityError}
						possessionDateError={possessionDateError}
						setPossessionDateError={setPossessionDateError}
					/>
				);
			case 3:
				return (
					<PropertyConfig
						propertyData={newPropertyData}
						property_furnishing_status={newPropertyData?.furnished as string[]}
						property_plot_dimensions={newPropertyData?.plot_dimensions ? newPropertyData?.plot_dimensions : ["30x40"]}
						property_area={newPropertyData?.area as Area}
						property_bhk={newPropertyData?.bhk as BHK}
						property_bathroom={newPropertyData?.bathroom as BHK}
						property_balconies={newPropertyData?.balconies as BHK}
						property_floors={newPropertyData?.floors as BHK}
						property_floor_no={newPropertyData?.floor_no as BHK}
						property_no_of_units={newPropertyData?.no_of_units as BHK}
						property_no_of_seats={newPropertyData?.no_of_seats as BHK}
						dispatch={dispatch}
						session={session ? session : null}
						propertyBHKminError={propertyBHKminError}
						setPropertyBHKminError={setPropertyBHKminError}
						propertyBHKmaxError={propertyBHKmaxError}
						setPropertyBHKmaxError={setPropertyBHKmaxError}
						propertyNoOfUnitsError={propertyNoOfUnitsError}
						setPropertyNoOfUnitsError={setPropertyNoOfUnitsError}
						propertyNoOfSeatsError={propertyNoOfSeatsError}
						setPropertyNoOfSeatsError={setPropertyNoOfSeatsError}
						propertyPlotDimensionsError={propertyPlotDimensionsError}
						setPropertyPlotDimensionsError={setPropertyPlotDimensionsError}
						propertyLandAreaError={propertyLandAreaError}
						setPropertyLandAreaError={setPropertyLandAreaError}
						propertySuperBuiltUpMinAreaError={propertySuperBuiltUpMinAreaError}
						setPropertySuperBuiltUpMinAreaError={setPropertySuperBuiltUpMinAreaError}
						propertySuperBuiltUpMaxAreaError={propertySuperBuiltUpMaxAreaError}
						setPropertySuperBuiltUpMaxAreaError={setPropertySuperBuiltUpMaxAreaError}
					/>
				);
			case 4:
				return (
					<PropertyOverview
						overview_data={newPropertyData?.description}
						dispatch={dispatch}
					/>
				);
			case 5:
				return (
					<UploadImages
						newPropertyData={newPropertyData}
						dispatch={dispatch}
						session={session ? session : null}
						businessProfiles={businessProfiles as DbUserPage}
						isBusinessProfile={true}
						isNewBusinessProfile={createBusinessProfile}
					/>
				);
			case 6:
				return (
					<PropertyPreview
						property_title={propertyTitle ? propertyTitle : ""}
						property_location={newPropertyData?.location.title ? newPropertyData?.location.title : ""}
						property_overview={newPropertyData?.description ? newPropertyData?.description : ""}
						property_transaction_type={newPropertyData?.transaction_type ? newPropertyData?.transaction_type : ""}
						property_category={newPropertyData?.property_category ? newPropertyData?.property_category : ""}
						property_type={newPropertyData?.property_type ? newPropertyData?.property_type : []}
						property_min_price={newPropertyData?.price?.min ? newPropertyData?.price?.min : 0}
						property_max_price={newPropertyData?.price?.max ? newPropertyData?.price?.max : 0}
						property_security_deposit={
							newPropertyData?.security_deposit.min ? newPropertyData?.security_deposit.min : 0
						}
						property_availability={newPropertyData?.availability ? newPropertyData?.availability : []}
						property_possession_date={newPropertyData?.possession_date ? newPropertyData?.possession_date : ""}
						property_unit_facing={
							newPropertyData?.unit_facing
								? newPropertyData?.unit_facing.map(
										(element: string) => element.charAt(0).toUpperCase() + element.slice(1).replace(/_/g, " "),
									)
								: []
						}
						property_age={newPropertyData?.property_age ? newPropertyData?.property_age : 0}
						property_furnishing_status={newPropertyData?.furnished as string[]}
						property_plot_dimensions={newPropertyData?.plot_dimensions ? newPropertyData?.plot_dimensions : []}
						property_area={newPropertyData?.area as Area}
						property_bhk={newPropertyData?.bhk as BHK}
						property_bathroom={newPropertyData?.bathroom as BHK}
						property_balconies={newPropertyData?.balconies as BHK}
						property_floors={newPropertyData?.floors as BHK}
						property_no_of_units={newPropertyData?.no_of_units as BHK}
						property_no_of_seats={newPropertyData?.no_of_seats as BHK}
					/>
				);
		}
	};

	return (
		<React.Fragment>
			{/* alert dialog */}
			<Snack
				open={openSnackbar}
				autoHideDuration={3000}
				onClose={() => setOpenSnackbar(false)}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert
					onClose={() => setOpenSnackbar(false)}
					severity={newPropertyData?.snack_text === "Error while creating Property" ? "error" : "success"}
					sx={{ width: "100%", borderRadius: "0.75rem" }}
					variant="filled"
				>
					{newPropertyData?.snack_text}
				</Alert>
			</Snack>

			<Dialog
				open={alertModal}
				maxWidth="sm"
				fullWidth={true}
				onClose={() => {
					setAlertModal(false);
				}}
			>
				<DialogTitle sx={{ fontSize: "1.25rem", fontWeight: 500, lineHeight: "160%" }}>
					{localLang.closePropertUpload}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>{localLang.unsavedDataWillBeLost}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<BeegruButton
						onClick={handleModalClose}
						color="primary"
						variant="outlined"
					>
						{localLang.yes}
					</BeegruButton>
					<BeegruButton
						onClick={() => {
							setAlertModal(false);
						}}
						color="primary"
						variant="contained"
					>
						{localLang.no}
					</BeegruButton>
				</DialogActions>
			</Dialog>

			<CustomDialog
				open={open}
				maxWidth="xs"
				fullWidth={true}
				fullScreen={smDevice}
				TransitionComponent={DialogTransition}
			>
				<CustomDialogTitle>
					<TextContainer sx={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
						<IconTitleRow>
							{!loading && activeStep > 0 ? (
								<IconButton
									aria-label="back"
									sx={{
										paddingLeft: "0rem",
									}}
									onClick={handleBack}
								>
									<ArrowBack fontSize="small" />
								</IconButton>
							) : null}

							<Typography variant="body1">
								{activeStep === 0
									? localLang.createNewProperty
									: activeStep === 1
										? localLang.propertyLocation
										: activeStep === 2
											? localLang.propertyDetails
											: activeStep === 3
												? localLang.propertyConfiguration
												: activeStep === 4
													? localLang.addOverview
													: activeStep === 5
														? localLang.uploadImages
														: localLang.propertyPreview}
							</Typography>
						</IconTitleRow>

						<IconButton
							aria-label="close"
							onClick={() => {
								activeStep === 0 ? handleModalClose() : setAlertModal(true);
								/* Post property Button (intro page) */

								(pathname && pathname.query && pathname.query.agent_post_property_button === "true") ||
								(pathname && pathname.query && pathname.query.builder_post_property_button === "true") ||
								(pathname && pathname.query && pathname.query.landowner_post_property_button === "true")
									? router.push((process.env.PRODUCTION_URL || " ") + "/intro")
									: "";
							}}
						>
							{loading ? null : <Close fontSize="small" />}
						</IconButton>
					</TextContainer>

					<ProgressContainer>
						<CustomLinearProgress
							variant="determinate"
							value={(activeStep / 6) * 100}
						/>
					</ProgressContainer>
				</CustomDialogTitle>

				<CustomDialogContent
					sx={{
						padding: activeStep === 2 || activeStep === 3 ? "1rem 1rem 0rem 1rem" : "1rem 1rem 1rem 1rem",
						background: activeStep === 6 ? theme.palette.background.default : theme.palette.background.paper,
					}}
				>
					{stepperContent(activeStep)}

					{activeStep === 2 || activeStep === 3 ? (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
								width: "100%",
							}}
						>
							<Typography
								sx={{
									fontSize: "0.875rem",
									fontWeight: 400,
									lineHeight: "150%",
									color: theme.palette.mode === "dark" ? "#FFFFFF" : "#9E9E9E",
								}}
							>
								{localLang.fieldsCannotBeEmpty}
							</Typography>
						</div>
					) : null}
				</CustomDialogContent>

				<CustomDiv
					sx={{
						padding: "0.5rem",
						borderRadius: smDevice ? "8px 8px 0px 0px" : "0px",
						boxShadow: smDevice
							? "rgba(0, 0, 0, 0.2) 0px 3px 3px 2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px"
							: "none",
					}}
				>
					{!loadNext ? (
						<TextContainer
							sx={{
								flexDirection: "row",
								width: "100%",
								justifyContent: smDevice ? "space-between" : "flex-end",
								alignItems: "center",
								gap: "1rem",
							}}
						>
							{/* {activeStep === 0 ? (
								<TextContainer sx={{ width: "8.75rem" }}></TextContainer>
							) : (
								<BeegruButton
									size="medium"
									flavor="primary"
									variant="text"
									onClick={handleBack}
									disabled={activeStep === 0 || loadNext}
									sx={{ width: "8.75rem" }}
								>
									{localLang.back}
								</BeegruButton>
							)} */}

							{/* <StepDiv>
								<CustomTypography>{activeStep + 1}/7</CustomTypography>
							</StepDiv> */}

							<BeegruButton
								size="medium"
								flavor="primary"
								variant="contained"
								fullWidth={smDevice}
								onClick={activeStep === 6 ? handleClickCreateNewProperty : handleNext}
								disabled={stepOneComplete || stepFiveCompleted}
								sx={{ minWidth: "8.75rem" }}
							>
								{activeStep === 5 ? localLang.preview : activeStep === 6 ? localLang.publish : localLang.next}
							</BeegruButton>
						</TextContainer>
					) : (
						<TextContainer sx={{ flexDirection: "row", width: "100%", justifyContent: "flex-end", gap: "1rem" }}>
							<Typography
								variant="body2"
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
									gap: "0.25rem",
									color: "primary.main",
								}}
							>
								{localLang.creatingProperty}
								<Circle>.</Circle>
								<Circle1>.</Circle1>
								<Circle2>.</Circle2>
							</Typography>
							<CircularProgress size={24} />
						</TextContainer>
					)}
				</CustomDiv>
			</CustomDialog>
		</React.Fragment>
	);
};

export default CreatePropertyWithUploadTool;
