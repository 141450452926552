/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import { ObjectId } from "mongodb";
// import WhatsAppIcon from "../../public/images/icons/social/whatsapp-icon.svg";
import StateInterface from "../../redux-magic/state-interface";
import { StoreLeadsThunk } from "../../redux-magic/store";
import {
	BusinessProfileInterface,
	PropertyInterface,
	UserInterface,
} from "../../redux-magic/sub-interfaces/sub-interfaces";

/* Interfaces */

interface adminInterface {
	_id: string | ObjectId;
	admins: Array<{}>;
}

/* Component Imports */

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Alert, BottomNavigation, LinearProgress, Link, Snackbar, Typography, useTheme } from "@mui/material";
import { signIn } from "next-auth/react";
import BeegruButton from "../common-components/buttons/BeegruButton";

/* Modal Imports */

import PublishButtonModal from "../modals/PropertyModal/PublishUnpublishModal";

/* Library Function Imports */

import { LocalPhoneOutlined } from "@mui/icons-material";

import { useRouter } from "next/router";
import CalculatePropertyCompletionPercentage from "../../lib/CalculatePropertyCompletionPercentage";

/* Styled Components */

const CustomBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-around",
	alignItems: "center",
	height: "4rem",
	position: "fixed",
	bottom: 0,
	right: 0,
	left: 0,
	zIndex: 5,
	background: theme.palette.mode == "dark" ? "#212121" : "#FFFFFF",
	opacity: 1,
	boxShadow:
		"rgba(0, 0, 0, 0.2) 0px 3px 3px 2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
	[theme.breakpoints.up("md")]: {
		display: "none",
	},
	"& .Mui-selected": {
		"& .MuiBottomNavigationAction-label": {
			fontSize: "0.75rem",
			lineHeight: "20px",
		},
	},
}));

const ButtonsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	gap: "0.25rem",
	padding: "0rem 0.25rem 0rem 0.25rem",
}));

const Snack = styled(Snackbar)(({ theme }) => ({}));

interface LocalPropertyInterface extends Omit<PropertyInterface, "created_by"> {
	created_by: {
		is_business_profile: boolean;
		business_profile_id: string | ObjectId | BusinessProfileInterface;
		user_id: string | ObjectId | UserInterface;
	};
}

const BottomNavProperty = ({
	propertyData,
	session,
	dispatch,
	profile_context,
	newPropertyData,
	newServiceData,
}: {
	session?: any;
	dispatch: Function;
	profile_context: StateInterface["profile_context"];
	propertyData?: any;
	newPropertyData: StateInterface["new_property_data"];
	newServiceData: StateInterface["new_service_data"];
}) => {
	const theme = useTheme();

	const router = React.useRef(useRouter()).current;

	const [publishmodal, setPublishmodal] = React.useState(false);

	const [updateButtonText, setUpdateButtonText] = React.useState<string>(propertyData.status);

	const [snackopen, setSnackOpen] = React.useState<boolean>(false);

	const creator_id: ObjectId | string = propertyData.created_by
		? propertyData.created_by.is_business_profile
			? propertyData.created_by.business_profile_id._id
			: propertyData.created_by.user_id
		: "";

	const profile_context_id: ObjectId | string = profile_context
		? profile_context.is_business_profile
			? profile_context.business_profile_id
			: profile_context.user_id
		: "";

	const user_id: ObjectId | string = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser._id
					? session.user.dbUser._id
					: ""
				: ""
			: ""
		: "";

	const logged_user_access: string = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser
					? session.user.dbUser.access_level
						? session.user.dbUser.access_level
						: ""
					: ""
				: ""
			: ""
		: "";

	const adminAccess: adminInterface | string | ObjectId =
		propertyData.created_by.business_profile_id !== ""
			? propertyData.created_by.business_profile_id._id
			: { _id: "", admins: [] };

	const isEditor = propertyData.is_ofb
		? false
		: (adminAccess as adminInterface)?.admins?.find((admin: any) => admin.user_id === session?.user?.dbUser._id) !==
			undefined;

	// Let's fetch the business profiles pgone number
	const businessPhone = (propertyData?.created_by?.business_profile_id as BusinessProfileInterface)?.phone
		?.phone_number;

	// Let's fetch the user's phone number
	const userPhone = (propertyData?.created_by?.user_id as UserInterface)?.phone;

	// Let's fetch the country code of the creator
	const creatorCountryCode = propertyData?.created_by
		? propertyData?.created_by.is_business_profile
			? propertyData?.created_by.business_profile_id !== ""
				? (propertyData?.created_by.business_profile_id as BusinessProfileInterface).phone.country_code ||
					(propertyData?.created_by.user_id as UserInterface).country_code
				: propertyData?.created_by.user_id !== ""
					? (propertyData?.created_by.user_id as UserInterface).country_code
					: ""
			: propertyData?.created_by.user_id !== ""
				? (propertyData?.created_by.user_id as UserInterface).country_code
				: ""
		: "";

	// Combine the country code with the business phone number or user phone number
	const phone: string = creatorCountryCode + (businessPhone !== "" ? businessPhone : userPhone);

	const localLang = {
		snackValue:
			router.locale === "ar-AE"
				? "سنتواصل معك قريبًا!"
				: router.locale === "kn-IN"
					? "ನಾವು ಶೀಘ್ರದಲ್ಲಿ ನಿಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸುತ್ತೇವೆ!"
					: "We'll get back to you soon!",
		getACallback:
			router.locale === "ar-AE"
				? "احصل على مكالمة رجعية"
				: router.locale === "kn-IN"
					? "ಕಾಲ್‌ಬ್ಯಾಕ್ ಪಡೆಯಿರಿ"
					: "Get callback",
		callNow: router.locale === "ar-AE" ? "اتصل الآن" : router.locale === "kn-IN" ? "ಈಗ ಕರೆ ಮಾಡಿ" : "Call",
		viewNumber:
			router.locale === "ar-AE" ? "عرض الرقم" : router.locale === "kn-IN" ? "ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ವೀಕ್ಷಿಸಿ" : "View Number",
	};

	/* Button text */

	React.useEffect(() => {
		setUpdateButtonText(
			propertyData.status === "publish_requested"
				? "Publish requested"
				: propertyData.status === "published" && propertyData.published === true
					? "Unpublish"
					: propertyData.status === "working_in_progress"
						? "Property under review"
						: propertyData.status === "pending_qc"
							? "Property under review"
							: propertyData.status === "edited"
								? "Publish"
								: "Publish",
		);
	}, [propertyData.status, propertyData.published]);

	/* Get a callback */

	const GetCall = async (call_now: boolean) => {
		call_now ? null : setSnackOpen(true);
		await dispatch(
			StoreLeadsThunk({
				name: session
					? session.user
						? session.user.dbUser
							? session.user.dbUser.first_name + " " + session.user.dbUser.last_name
							: ""
						: ""
					: "",
				phone: {
					country_code: session
						? session.user
							? session.user.dbUser
								? session.user.dbUser.country_code
								: "+91"
							: "+91"
						: "+91",
					phone_number: session ? (session.user ? (session.user.dbUser ? session.user.dbUser.phone : "") : "") : "",
				},
				agreed_to_tc: true,
				source: "/properties/" + propertyData?.slug,
				listing_slug: propertyData?.slug,
				listing_type: "property",
				listing_id: propertyData?._id,
				is_guest: session ? false : true,
				listing_title: propertyData?.title,
				listing_owner: {
					is_business_profile: propertyData?.created_by?.is_business_profile,
					user_id: propertyData?.created_by?.user_id,
					business_profile_id: propertyData?.created_by?.business_profile_id._id,
				},
				user_id: session ? (session.user ? (session.user.dbUser ? session.user.dbUser._id : "") : "") : "",
				is_lead_from_boost: propertyData
					? propertyData.boosted_information
						? propertyData.boosted_information.active
						: false
					: false,
			}),
		);
	};

	const handleCloseSnack = () => {
		setSnackOpen(false);
	};

	return (
		<React.Fragment>
			<Snack
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={snackopen}
				autoHideDuration={3000}
				onClose={handleCloseSnack}
			>
				<Alert
					onClose={handleCloseSnack}
					severity="success"
					sx={{ width: "100%", borderRadius: "0.75rem" }}
					variant="filled"
				>
					{localLang.snackValue}
				</Alert>
			</Snack>
			{/* Publish Modal */}

			<PublishButtonModal
				propertyData={propertyData as LocalPropertyInterface}
				open={publishmodal}
				handleClose={() => {
					setPublishmodal(false);
				}}
				dispatch={dispatch}
				profile_context={profile_context}
				isEditor={isEditor}
				session={session}
			/>

			{/* Bottom Navigation */}

			<CustomBottomNavigation showLabels>
				{creator_id ? (
					creator_id === profile_context_id ||
					isEditor ||
					(logged_user_access.includes("admin") &&
						logged_user_access.includes("property") &&
						propertyData.assigned_to.includes(user_id)) ||
					logged_user_access.includes("super_admin") ? (
						<ButtonsContainer sx={{ flexDirection: "column" }}>
							{/* <Typography
								variant="caption"
								sx={{ color: "text.secondary" }}
							>
								{!propertyData.published
									? CalculatePropertyCompletionPercentage(propertyData as PropertyInterface) < 25
										? "Please fill the mandatory fields"
										: CalculatePropertyCompletionPercentage(propertyData as PropertyInterface) < 75
											? "Just a few more fields to go"
											: CalculatePropertyCompletionPercentage(propertyData as PropertyInterface) < 100
												? "Your property is almost ready to publish"
												: "Tip: Properties with full details get better reach"
									: "Your property is published"}
							</Typography> */}

							<BeegruButton
								flavor="primary"
								variant={updateButtonText === "Unpublish" ? "outlined" : "contained"}
								sx={{
									width: "95%",
									margin: "0rem 0.5rem 0rem 0.5rem",
									padding: "0rem",
								}}
								onClick={() => {
									setPublishmodal(true);
								}}
								disabled={
									(!propertyData.published &&
										CalculatePropertyCompletionPercentage(propertyData as PropertyInterface) < 100) ||
									propertyData.status === "publish_requested" ||
									propertyData.status === "working_in_progress" ||
									propertyData.status === "pending_qc"
								}
							>
								<Typography
									variant="body1"
									sx={{
										position: "absolute",
										width: "100%",
										height: "2.3rem",
										zIndex: 1,
										textAlign: "center",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										color: updateButtonText === "Unpublish" ? theme.palette.primary.main : "#FFFFFF",
									}}
								>
									{CalculatePropertyCompletionPercentage(propertyData as PropertyInterface) < 100 &&
									!propertyData.published
										? CalculatePropertyCompletionPercentage(propertyData as PropertyInterface) + "%"
										: updateButtonText
											? updateButtonText
											: "Publish"}
								</Typography>
								<LinearProgress
									variant="determinate"
									sx={{
										width: "100%",
										height: "2.5rem",
										borderRadius: "12px",
										"& .MuiLinearProgress-bar": {
											backgroundColor:
												updateButtonText === "Unpublish" ? theme.palette.background.paper : theme.palette.primary.main,
										},
									}}
									value={
										!propertyData.published
											? CalculatePropertyCompletionPercentage(propertyData as PropertyInterface)
											: 100
									}
								/>
							</BeegruButton>
						</ButtonsContainer>
					) : (
						<ButtonsContainer>
							{/* Get callback */}

							<BeegruButton
								flavor="success"
								variant={propertyData.show_contact_details ? "outlined" : "contained"}
								sx={{
									width: "45%",
									fontSize: "0.75rem",
									flexGrow: 1,
									/* galaxy S8+ - 360 */
									[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
										fontSize: "0.875rem",
									},
								}}
								onClick={() => (session ? GetCall(false) : signIn())}
							>
								{localLang.getACallback}
							</BeegruButton>

							{/* Call Now */}

							{propertyData.show_contact_details === true ? (
								<BeegruButton
									flavor="success"
									variant="contained"
									sx={{
										width: "40%",
										fontSize: "0.75rem",
										[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
											fontSize: "0.875rem",
										},
									}}
									href={"tel:" + phone}
								>
									<LocalPhoneOutlined
										sx={{
											margin: "0rem 0.5rem 0rem 0rem",
											fontSize: "1rem",
											[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
												fontSize: "1.25rem",
											},
										}}
									/>
									{localLang.callNow}
								</BeegruButton>
							) : null}

							{/* WhatsApp */}
							{propertyData.show_contact_details === true && (
								<div
									style={{
										background: "#24D366",
										borderRadius: "0.75rem",
										height: "2.5rem",
										width: "2.5rem",
										[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
											fontSize: "0.875rem",
										},
									}}
								>
									<Link
										href={`https://wa.me/${phone.replace(" ", "").replace("+", "")}?text=${encodeURIComponent("Hi, I'm interested in your listing \"")}${encodeURIComponent(propertyData.title)}${'" in '}${encodeURIComponent(propertyData.location.title)}${encodeURIComponent(" that I found on Beegru\n\n")}${encodeURIComponent((process.env.PRODUCTION_URL || "") + "properties/" + propertyData.slug + "?utm_source=whatsapp&utm_source_id=bottom_nav_whatsapp_button")}${encodeURIComponent("\n\nIs it still available?")}`}
										rel="noopener"
										target="_self"
										referrerPolicy="no-referrer"
										aria-label="Share via Whatsapp"
										sx={{
											textDecoration: "none",
											color: "inherit",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											margin: "0.25rem 0.5rem 0.25rem 0.5rem",
										}}
									>
										<WhatsAppIcon
											style={{
												color: "#FFFFFF",
												width: "1.5rem",
												height: "1.5rem",
												margin: "0.25rem 0rem 0.25rem 0rem",
											}}
										/>
									</Link>
								</div>
							)}
						</ButtonsContainer>
					)
				) : null}
			</CustomBottomNavigation>
		</React.Fragment>
	);
};

export default BottomNavProperty;
