/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Component Imports */

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { IconButton, Link, Typography, useTheme } from "@mui/material";
import { useRouter } from "next/router";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-start",
	flexWrap: "wrap",
	width: "100%",
	height: "100%",
	margin: "0.25rem 0rem 1rem 0rem",
}));

const AboutUsSection = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "35%",
	[theme.breakpoints.down("lg")]: {
		margin: "0rem 0rem 1rem 0rem",
		width: "100%",
	},
}));

const CompanySection = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "15%",
	[theme.breakpoints.down("lg")]: {
		width: "20%",
	},
	[theme.breakpoints.down("sm")]: {
		display: "none",
	},
}));

const OurServicesSection = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "10%",
	[theme.breakpoints.down("lg")]: {
		width: "20%",
	},
	[theme.breakpoints.down("sm")]: {
		display: "none",
	},
}));

const GetInTouchSection = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "27%",
	[theme.breakpoints.down("lg")]: {
		width: "45%",
	},
	[theme.breakpoints.down("sm")]: {
		display: "none",
		// flexDirection: "row",
		// justifyContent: "space-between",
		// margin: "1rem 0rem 0rem 0rem",
		// width: "100%",
	},
}));

const Title = styled(Typography)(({ theme }) => ({
	margin: "0rem 0rem 0.5rem 0rem",
	fontWeight: 500,
	lineHeight: "1.25rem",
	width: "100%",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1.25rem",
	fontWeight: 400,
	color: theme.palette.mode === "dark" ? "#fff" : "#666666",
}));

const List = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	gap: "0.5rem",
}));

const CustomLink = styled(Link)(({ theme }) => ({
	fontSize: "0.625rem",
	lineHeight: "1.45rem",
	fontWeight: 400,
	color: theme.palette.mode === "dark" ? "#fff" : "#666666",
	textDecoration: "none",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
		lineHeight: "1.25rem",
	},
	"@media (pointer: fine)": {
		"&:hover": {
			// textDecoration: "underline",
			color: theme.palette.primary.main,
		},
	},
}));

const ServiceSectionMobile = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	margin: "0rem 0rem 0.5rem 0rem",
	[theme.breakpoints.up("sm")]: {
		display: "none",
	},
}));

const CompanySectionMobile = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	[theme.breakpoints.up("sm")]: {
		display: "none",
	},
}));

const Separator = styled("div")(({ theme }) => ({
	width: "0.063rem",
	height: "0.875rem",
	backgroundColor: "#0000001F",
}));

const CompanyList = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	flexWrap: "wrap",
	gap: "0.25rem",
}));

const ServiceList = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const FooterLinks = (props: any) => {
	const router = useRouter();

	const theme = useTheme();

	const [windowWidth, setWindowWidth] = React.useState(0);

	React.useEffect(() => {
		if (typeof window !== "undefined") {
			setWindowWidth(window.innerWidth);

			const handleResize = () => {
				setWindowWidth(window.innerWidth);
			};

			window.addEventListener("resize", handleResize);

			return () => {
				window.removeEventListener("resize", handleResize);
			};
		}
	}, []);

	return (
		<React.Fragment>
			<Container>
				{/* About us */}

				<AboutUsSection>
					{/* <Title variant="body1">Beegru gets properties</Title> */}
					<Text variant="body2">
						{router.locale === "ar-AE"
							? "بيجرو تحصل على العقارات"
							: router.locale === "kn-IN"
								? "ನಿಮಗೆ ಸೂಕ್ತವಾದ ಆಸ್ತಿಗಳು ಬೀಗ್ರು ಬಳಿಯಿವೆ "
								: "Beegru gets properties"}
					</Text>
				</AboutUsSection>

				<ServiceSectionMobile>
					<Title
						variant="body1"
						sx={{
							fontWeight: router.locale === "ar-AE" ? 600 : 500,
							fontSize: "0.75rem",
						}}
					>
						{router.locale === "ar-AE" ? "خدماتنا" : router.locale === "kn-IN" ? "ನಮ್ಮ ಸೇವೆಗಳು" : "Our services"}
					</Title>
					<ServiceList>
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_intro_agents"
							}
							href={"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/intro?type=agent")}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE" ? "وكلاء العقارات" : router.locale === "kn-IN" ? "ಏಜೆಂಟ್ಸ್" : "Agents"}
						</CustomLink>
						<Separator />
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_intro_agents"
							}
							href={
								"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/intro?type=property-seeker")
							}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE"
								? "باحثو العقارات"
								: router.locale === "kn-IN"
									? "ಆಸ್ತಿ ಹುಡುಕಾಟ"
									: "Property seekers"}
						</CustomLink>
						<Separator />
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_intro_landowner"
							}
							href={"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/intro?type=landowner")}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE" ? "مالكو الأراضي" : router.locale === "kn-IN" ? "ಭೂ ಮಾಲೀಕರು" : "Owners"}
						</CustomLink>
						<Separator />
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_intro_developer"
							}
							href={"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/intro?type=developer")}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE" ? "المطورون" : router.locale === "kn-IN" ? "ಡೆವೆಲೊಪರ್ಸ್" : "Builders"}
						</CustomLink>
						<Separator />
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_intro_professional"
							}
							href={"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/intro?type=professional")}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE"
								? "محترف الخدمات"
								: router.locale === "kn-IN"
									? "ಸೇವಾ ಪೂರೈಕೆದಾರರು"
									: "Service providers"}
						</CustomLink>
					</ServiceList>
				</ServiceSectionMobile>

				<CompanySectionMobile>
					<Title
						variant="body1"
						sx={{
							fontWeight: router.locale === "ar-AE" ? 600 : 500,
							fontSize: "0.75rem",
						}}
					>
						{router.locale === "ar-AE" ? "شركة" : router.locale === "kn-IN" ? "ಕಂಪನಿ" : "Company"}
					</Title>
					<CompanyList>
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_contact_us"
							}
							href={"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/contact-us")}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE" ? "من نحن" : router.locale === "kn-IN" ? "ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ" : "Contact us"}
						</CustomLink>
						<Separator />
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_faqs"
							}
							href={"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/faqs")}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE" ? "الأسئلة الشائعة" : router.locale === "kn-IN" ? "ಪ್ರಶ್ನೆಗಳು" : "FAQs"}
						</CustomLink>
						<Separator />
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_privacy"
							}
							href={"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/privacy")}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE"
								? "سياسة الخصوصية"
								: router.locale === "kn-IN"
									? "ಗೌಪ್ಯತೆ ನೀತಿ"
									: "Privacy policy"}
						</CustomLink>
						<Separator />
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_payment_policy"
							}
							href={"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/terms-of-use")}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE"
								? "شروط الاستخدام"
								: router.locale === "kn-IN"
									? "ಬಳಕೆಯ ನಿಯಮಗಳು"
									: "Terms of use"}
						</CustomLink>

						<Separator className="hide-md-lg" />
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_shipping_returns_policy"
							}
							href={
								"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/shipping-and-returns-policy")
							}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
							className="hide-md-lg"
						>
							{router.locale === "ar-AE"
								? ""
								: router.locale === "kn-IN"
									? "ಶಿಪ್ಪಿಂಗ್ ಮತ್ತು ರಿಟರ್ನ್ ನೀತಿ"
									: "Shipping and Returns policy"}
						</CustomLink>
						<Separator className="hide-md-lg" />
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_refund_cancellation_policy"
							}
							href={
								"/loading.html?callbackUrl=" +
								encodeURIComponent("/" + router.locale + "/refund-and-cancellation-policy")
							}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
							className="hide-md-lg"
						>
							{router.locale === "ar-AE"
								? "سياسة الاسترجاع والإلغاء"
								: router.locale === "kn-IN"
									? "ಮರುಪಾವತಿ ಮತ್ತು ರದ್ದತಿ ನೀತಿ"
									: "Refund and cancellation policy"}
						</CustomLink>
						<Separator className="hide-md-lg" />
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_payment_policy"
							}
							href={"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/payment-policy")}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
							className="hide-md-lg"
						>
							{router.locale === "ar-AE" ? "سياسة الدفع" : router.locale === "kn-IN" ? "ಪಾವತಿ ನೀತಿ" : "Payment policy"}
						</CustomLink>
					</CompanyList>
				</CompanySectionMobile>

				{/* Company */}

				<CompanySection>
					<Title
						variant="body1"
						sx={{
							fontWeight: router.locale === "ar-AE" ? 600 : 500,
						}}
					>
						{router.locale === "ar-AE" ? "شركة" : router.locale === "kn-IN" ? "ಕಂಪನಿ" : "Company"}
					</Title>
					<List>
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_faqs"
							}
							href={"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/faqs")}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE" ? "الأسئلة الشائعة" : router.locale === "kn-IN" ? "ಪ್ರಶ್ನೆಗಳು" : "FAQs"}
						</CustomLink>
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_terms_of_use"
							}
							href={"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/terms-of-use")}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE"
								? "شروط الاستخدام"
								: router.locale === "kn-IN"
									? "ಬಳಕೆಯ ನಿಯಮಗಳು"
									: "Terms of use"}
						</CustomLink>
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_privacy"
							}
							href={"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/privacy")}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE"
								? "سياسة الخصوصية"
								: router.locale === "kn-IN"
									? "ಗೌಪ್ಯತೆ ನೀತಿ"
									: "Privacy policy"}
						</CustomLink>
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_payment_policy"
							}
							href={"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/payment-policy")}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE" ? "سياسة الدفع" : router.locale === "kn-IN" ? "ಪಾವತಿ ನೀತಿ" : "Payment policy"}
						</CustomLink>
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_refund_cancellation_policy"
							}
							href={
								"/loading.html?callbackUrl=" +
								encodeURIComponent("/" + router.locale + "/refund-and-cancellation-policy")
							}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE"
								? "سياسة الاسترجاع والإلغاء"
								: router.locale === "kn-IN"
									? "ಮರುಪಾವತಿ ಮತ್ತು ರದ್ದತಿ ನೀತಿ"
									: "Refund and cancellation policy"}
						</CustomLink>
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_shipping_returns_policy"
							}
							href={
								"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/shipping-and-returns-policy")
							}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE"
								? ""
								: router.locale === "kn-IN"
									? "ಶಿಪ್ಪಿಂಗ್ ಮತ್ತು ರಿಟರ್ನ್ ನೀತಿ"
									: "Shipping and Returns policy"}
						</CustomLink>
					</List>
				</CompanySection>

				{/* Our services */}

				<OurServicesSection>
					<Title
						variant="body1"
						sx={{
							fontWeight: router.locale === "ar-AE" ? 600 : 500,
						}}
					>
						{router.locale === "ar-AE" ? "خدماتنا" : router.locale === "kn-IN" ? "ನಮ್ಮ ಸೇವೆಗಳು" : "Our services"}
					</Title>
					<List>
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_intro_agents"
							}
							href={"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/intro?type=agent")}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE" ? "وكلاء العقارات" : router.locale === "kn-IN" ? "ಏಜೆಂಟ್ಸ್" : "Agents"}
						</CustomLink>
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_intro_property_seeker"
							}
							href={
								"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/intro?type=property-seeker")
							}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE"
								? "باحثو العقارات"
								: router.locale === "kn-IN"
									? "ಆಸ್ತಿ ಹುಡುಕಾಟ"
									: "Property seekers"}
						</CustomLink>
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_intro_landowner"
							}
							href={"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/intro?type=landowner")}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE" ? "مالكو الأراضي" : router.locale === "kn-IN" ? "ಭೂ ಮಾಲೀಕರು" : "Landowners"}
						</CustomLink>
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_intro_developer"
							}
							href={"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/intro?type=developer")}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE" ? "المطورون" : router.locale === "kn-IN" ? "ಡೆವೆಲೊಪರ್ಸ್" : "Developers"}
						</CustomLink>
						<CustomLink
							id={
								(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
								"_footer_intro_professional"
							}
							href={"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/intro?type=professional")}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE"
								? "محترف الخدمات"
								: router.locale === "kn-IN"
									? "ಸೇವಾ ಪೂರೈಕೆದಾರರು"
									: "Service providers"}
						</CustomLink>
					</List>
				</OurServicesSection>

				{/* Get in touch */}

				<GetInTouchSection>
					<CustomLink
						id={
							(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
							"_footer_contact_us"
						}
						href={"/loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/contact-us")}
						rel="noopener"
						target="_self"
						referrerPolicy="no-referrer"
					>
						<Title
							variant="body1"
							sx={{
								fontWeight: router.locale === "ar-AE" ? 600 : 500,
							}}
						>
							{router.locale === "ar-AE" ? "تواصل معنا" : router.locale === "kn-IN" ? "ಸಂಪರ್ಕದಲ್ಲಿರಿ" : "Get in touch"}
						</Title>
					</CustomLink>

					{windowWidth < 540 ? (
						<List sx={{ flexDirection: "row", justifyContent: "flex-end", gap: "1.25rem", width: "auto" }}>
							<IconButton>
								<CustomLink
									id={
										(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
										"_footer_location"
									}
									href="https://maps.app.goo.gl/57TYegsdMNkeyGje9"
									rel="noopener"
									target="_self"
									referrerPolicy="no-referrer"
								>
									<LocationOnOutlinedIcon
										sx={{
											color: theme.palette.primary.main,
										}}
									/>
								</CustomLink>
							</IconButton>
							<IconButton>
								<CustomLink
									id={
										(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
										"_footer_phone"
									}
									href="tel:+917829371999"
									rel="noopener"
									target="_self"
									referrerPolicy="no-referrer"
								>
									<PhoneOutlinedIcon
										sx={{
											color: theme.palette.primary.main,
										}}
									/>
								</CustomLink>
							</IconButton>
							<IconButton>
								<CustomLink
									id={
										(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
										"_footer_email"
									}
									href="mailto:support@beegru.com"
									rel="noopener"
									target="_self"
									referrerPolicy="no-referrer"
								>
									<MailOutlineOutlinedIcon
										sx={{
											color: theme.palette.primary.main,
										}}
									/>
								</CustomLink>
							</IconButton>
						</List>
					) : (
						<List>
							<Text variant="body2">
								{router.locale === "ar-AE"
									? "1090i، الطابق الرابع، الطريق الثامن عشر، القطاع الثالث، حي إتش إس آر، بنغالور، كارناتاكا 560102"
									: router.locale === "kn-IN"
										? "1090i, 4 ನೇ ಮಹಡಿ, 18 ನೇ ಅಡ್ಡ ರಸ್ತೆ, ಸೆಕ್ಟರ್ 3, ಹೆಚ್‌ಎಸ್‌ಆರ್ ಲೇಔಟ್ ಬೆಂಗಳೂರು, ಕರ್ನಾಟಕ 560 102"
										: "1090i, 4th Floor, 18th Cross Road, 3rd Sector, HSR Layout, Bengaluru, Karnataka 560 102"}
							</Text>
							<CustomLink
								id={
									(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
									"_footer_phone_number"
								}
								href="tel:+917829371999"
								rel="noopener"
								target="_self"
								referrerPolicy="no-referrer"
								sx={{
									direction: "ltr",
								}}
							>
								{router.locale === "ar-AE" ? "+91 78293 71999" : "+91 78293 71999"}
							</CustomLink>
							<CustomLink
								id={
									(router.asPath === "/" ? "home" : router.asPath.split("/").filter(Boolean)[0] || "unknown_page") +
									"_footer_email"
								}
								href="mailto:support@beegru.com"
								rel="noopener"
								target="_self"
								referrerPolicy="no-referrer"
							>
								support@beegru.com
							</CustomLink>
						</List>
					)}
				</GetInTouchSection>
			</Container>
		</React.Fragment>
	);
};

export default FooterLinks;
