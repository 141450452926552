/* Common imports */
import React from "react";

/* MUI Imports */
import { Checkbox, Divider, FormControlLabel, Paper, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";

/* google maps library */
import { useLoadScript } from "@react-google-maps/api";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import dynamic from "next/dynamic";

import { useRouter } from "next/router";
import usePlacesAutocomplete from "use-places-autocomplete";
import { setCreateNewPropertyDefaultLocationThunk } from "../../../../../redux-magic/thunks";
import Search from "./SearchPropertyLocation";

const LocationPicker = dynamic<{
	containerElement: React.ReactNode;
	mapElement: React.ReactNode;
	onChange: Function;
	defaultPosition: object;
	zoom?: number;
	radius: number;
	circleOptions?: object;
	//@ts-ignore
}>(() => import("@beegru/react-location-picker"), {
	ssr: false,
});

mapboxgl.accessToken = process.env.MAPBOXGL_ACCESS_TOKEN || "";

interface GeolocationCoordinates {
	latitude: number;
	longitude: number;
	altitude: number | null;
	accuracy: number;
	altitudeAccuracy: number | null;
	heading: number | null;
	speed: number | null;
}
interface GeolocationPosition {
	coords: GeolocationCoordinates;
}
interface GMRGCPlusCode {
	compound_code: string;
	global_code: string;
}
interface GMRGCResultsAddressComponent {
	long_name: string;
	short_name: string;
	types: Array<string>;
}
interface GMRGCResultsGeometryLocation {
	lat: number;
	lng: number;
}
interface GMRGCResultsGeometryBoundsAndGeometryViewport {
	northeast: GMRGCResultsGeometryLocation;
	southwest: GMRGCResultsGeometryLocation;
}
interface GMRGCResultsGeometry {
	bounds: GMRGCResultsGeometryBoundsAndGeometryViewport;
	location: GMRGCResultsGeometryLocation;
	location_type: string;
}
interface GMRGCResults {
	address_components: Array<GMRGCResultsAddressComponent>;
	formatted_address: string;
	geometry: GMRGCResultsGeometry;
	viewport: GMRGCResultsGeometryBoundsAndGeometryViewport;
	place_id: string;
	types: Array<string>;
}
interface GoogleMapsReverseGeocodingApiResponse {
	plus_code: GMRGCPlusCode;
	results: Array<GMRGCResults>;
	status: string;
}

type MapContainerProps = {
	showMap: boolean;
};

const MapboxContainer = styled(Paper)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	borderRadius: "8px",
	padding: "1rem 0rem 0rem 0rem",
	width: "100%",
	background: "transparent",
	boxShadow: "none",
	gap: "0.5rem",
}));

const MapContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "showMap",
})<MapContainerProps>(({ theme, showMap }) => ({
	width: "100%",
	display: showMap ? undefined : "none",
	overflow: "hidden",
	margin: "1rem 0rem 0rem 0rem",
}));

const Map = styled("div")(({ theme }) => ({
	height: "20rem",
	overflow: "hidden",
	borderRadius: "1rem",
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	padding: "0rem 0rem 0rem 0rem",
	width: "100%",
}));

const SearchContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "0.688rem",
}));

const librariesToLoad: ("places" | "drawing" | "geometry" | "localContext" | "visualization")[] = [
	"places",
	"drawing",
	"geometry",
];

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: 400,
	lineHeight: "150%",
}));

const PropertyLocation = ({
	newPropertyDataLocationCoordinates,
	dispatch,
	showMap,
	locationAddress,
}: {
	dispatch: Function;
	newPropertyDataLocationCoordinates: Array<number>;
	showMap: boolean;
	locationAddress: string;
}) => {
	const router = React.useRef(useRouter()).current;

	const [fromList, setFromList] = React.useState(null);

	const [change, setChange] = React.useState<{ lat: number; lng: number }>({
		lat: newPropertyDataLocationCoordinates[0],
		lng: newPropertyDataLocationCoordinates[1],
	});

	const [address, setAddress] = React.useState<string>("");

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY_NO_REFERRER_RESTRICTIONS || "",
		libraries: librariesToLoad as any,
	});

	const mapRef = React.useRef(null);
	const markerRef = React.useRef(null);

	const [customTitleError, setCustomTitleError] = React.useState("");
	const [customTitleCheck, setCustomTitleCheck] = React.useState(false);
	const [customTitle, setCustomTitle] = React.useState<string>("");
	const handleChangeCustomTitleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCustomTitleCheck(event.target.checked);
		setChecked(event.target.checked);
		// setHelperText("Pin your location on the map");
	};
	const {
		value,
		suggestions: { data },
		setValue,
	} = usePlacesAutocomplete({
		initOnMount: false,
		debounce: 500,
	});

	const [locaionText, setLocationText] = React.useState<string>("");
	const [helperText, setHelperText] = React.useState<string>("");
	const [selected_pin, setSelectedPin] = React.useState<boolean>(false);

	const handleLocationChange = async ({
		position,
		address,
	}: {
		position: { lat: number; lng: number };
		address: string;
	}) => {
		setChange({ lat: position.lat, lng: position.lng });
		setHelperText("");
		setSelectedPin(true);
	};

	React.useEffect(() => {
		const executor: Function = async () => {
			const googleMapsReverseGeocodingApiResponse: GoogleMapsReverseGeocodingApiResponse = await (
				await fetch(
					"https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
						change.lat +
						"," +
						change.lng +
						"&key=" +
						(process.env.GOOGLE_MAPS_API_KEY_NO_REFERRER_RESTRICTIONS || ""),
					{ cache: "no-store" },
				)
			).json();
			const userCurrentAddress: string | undefined =
				googleMapsReverseGeocodingApiResponse?.results[0]?.address_components?.find(
					(address_component: GMRGCResultsAddressComponent) =>
						address_component.types.includes("sublocality_level_1") ||
						address_component.types.includes("sublocality_level_2") ||
						address_component.types.includes("sublocality_level_3"),
				)?.long_name;
			const userCurrentAreas: Array<string> | undefined = googleMapsReverseGeocodingApiResponse?.results.map(
				(result) => {
					return result.address_components?.find(
						(address_component: GMRGCResultsAddressComponent) =>
							address_component.types.includes("sublocality_level_1") ||
							address_component.types.includes("postal_town") ||
							address_component.types.includes("locality"),
					)?.long_name as string;
				},
			);

			let userCurrentArea: string | undefined;

			let resultPos: number = 0;

			for (let i = 0; i < userCurrentAreas.length; i++) {
				if (userCurrentAreas[i] !== "Bengaluru") {
					userCurrentArea = userCurrentAreas[i];
					resultPos = i;
					break;
				}
			}
			const label = userCurrentAddress ? userCurrentAddress : userCurrentArea ? userCurrentArea : "";
			setAddress(label);

			const userCurrentAddressPin: string | undefined = googleMapsReverseGeocodingApiResponse?.results[
				resultPos
			]?.address_components?.find(
				(address_component: GMRGCResultsAddressComponent) =>
					address_component.types.includes("postal_code") || address_component.types.includes("pincode"),
			)?.long_name;

			setValue(userCurrentAddressPin || "");
		};
		executor();
	}, [change, setValue]);

	const [checked, setChecked] = React.useState(false);

	React.useEffect(() => {
		if (selected_pin)
			dispatch(
				setCreateNewPropertyDefaultLocationThunk({
					location_coordinates: [change.lat, change.lng],
					address: checked ? customTitle : address,
				}),
			);
	}, [change.lat, change.lng, dispatch, address, checked, customTitle, selected_pin]);

	const localLang = {
		please_enter_custom_title:
			router.locale === "kn-IN"
				? "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಸ್ವಂತ ಶೀರ್ಷಿಕೆಯನ್ನು ನಮೂದಿಸಿ"
				: router.locale === "ar-AE"
					? "يرجى إدخال العنوان المخصص للموقع"
					: "Please enter the location's custom title",

		enter_custom_title:
			router.locale === "kn-IN"
				? "ನಿಮ್ಮ ಸ್ವಂತ ಶೀರ್ಷಿಕೆಯನ್ನು ನಮೂದಿಸಿ"
				: router.locale === "ar-AE"
					? "أدخل العنوان المخصص"
					: "Enter custom title",

		enterLocation:
			router.locale === "kn-IN"
				? "ನಿಮ್ಮ ಆಸ್ತಿಯ ಸ್ಥಳವನ್ನು ನಮೂದಿಸಿ"
				: router.locale === "ar-AE"
					? "أدخل موقع الممتلكات الخاصة بك"
					: "Enter the location for your property",

		helperText:
			helperText !== ""
				? router.locale === "kn-IN"
					? "ನಿಮ್ಮ ಸ್ಥಳವನ್ನು ನಕ್ಷೆಯಲ್ಲಿ ಪಿನ್ ಮಾಡಿ"
					: router.locale === "ar-AE"
						? "قم بتثبيت موقعك على الخريطة"
						: "Pin your location on the map"
				: router.locale === "kn-IN"
					? "ನಿಮ್ಮ ಸ್ಥಳವನ್ನು ನಮೂದಿಸಿ"
					: router.locale === "ar-AE"
						? "أدخل موقعك"
						: "Enter your location",

		enterYourLocationManually:
			router.locale === "kn-IN"
				? "ನಿಮ್ಮ ಸ್ಥಳವನ್ನು ನಮೂದಿಸಿ"
				: router.locale === "ar-AE"
					? "أدخل موقعك"
					: "Enter your location manually",

		enterPropertyLocation:
			router.locale === "kn-IN"
				? "ಆಸ್ತಿಯ ಸ್ಥಳವನ್ನು ನಮೂದಿಸಿ"
				: router.locale === "ar-AE"
					? "أدخل موقع العقار"
					: "Enter property location",

		pleaseEnterYourLocationsCustomTitle:
			router.locale === "kn-IN"
				? "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಸ್ಥಳದ ಕಸ್ಟಮ್ ಶೀರ್ಷಿಕೆಯನ್ನು ನಮೂದಿಸಿ"
				: router.locale === "ar-AE"
					? "يرجى إدخال العنوان المخصص لموقعك"
					: "Please enter your location's custom title",
	};

	return (
		<MapboxContainer id="map">
			<Heading>{localLang.enterPropertyLocation}</Heading>
			<TextContainer>
				<SearchContainer>
					<Search
						change={checked ? customTitle : locationAddress}
						fromList={fromList}
						setFromList={setFromList}
						dispatch={dispatch}
					/>
				</SearchContainer>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
						gap: "8px",
						width: "100%",
						margin: "0.5rem 0rem 0.5rem 0rem",
					}}
				>
					<Divider style={{ flex: 1 }} />
					<span style={{ padding: "0 8px", fontSize: "1rem", color: "#333" }}>
						{router.locale === "ar-AE" ? "أو" : "OR"}
					</span>
					<Divider style={{ flex: 1 }} />
				</div>
				{checked ? (
					<SearchContainer>
						<TextField
							id="outlined-basic"
							label={localLang.enter_custom_title}
							variant="outlined"
							value={customTitle}
							error={customTitleError !== ""}
							helperText={customTitleError}
							onChange={(e) => {
								if (e.target.value) {
									setCustomTitleError("");
								} else {
									setCustomTitleError(localLang.pleaseEnterYourLocationsCustomTitle);
								}
								setCustomTitle(e.target.value);
							}}
							sx={{
								width: "100%",
								margin: "1rem 0rem 0rem 0rem",
							}}
							placeholder={localLang.enter_custom_title}
						/>
					</SearchContainer>
				) : null}

				<FormControlLabel
					control={
						<Checkbox
							checked={checked}
							onChange={handleChangeCustomTitleCheckbox}
						/>
					}
					label={localLang.enterYourLocationManually}
					sx={{ margin: "0rem 0rem 1rem 0rem" }}
				/>
				{isLoaded && (showMap || checked) && (
					<LocationPicker
						containerElement={<MapContainer showMap={true} />}
						mapElement={<Map />}
						defaultPosition={{
							lat: newPropertyDataLocationCoordinates[0],
							lng: newPropertyDataLocationCoordinates[1],
						}}
						radius={10}
						onChange={handleLocationChange}
					/>
				)}
			</TextContainer>
		</MapboxContainer>
	);
};

export default PropertyLocation;
