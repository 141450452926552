import NumberToCurrencyPipe from "./NumberToCurrencyPipe";

const preparePriceString = (price: number) => {
	/* Absolute Price */

	let NewPriceWoUnit: number | string = Math.abs(price);

	let NewPrice: number | string = "";

	if (NewPriceWoUnit >= 1000 && NewPriceWoUnit < 100000) {
		/* Showing price in thousands if price is >= 1 thousand and < 1 lakh */

		NewPriceWoUnit = (NewPriceWoUnit / 1000).toFixed(2);

		/* If decimals have 0 value then remove them */
		if (NewPriceWoUnit.split(".")[1] === "00") {
			NewPriceWoUnit = NewPriceWoUnit.split(".")[0];
		}

		NewPrice = NewPriceWoUnit + "K";
	} else if (NewPriceWoUnit >= 100000 && NewPriceWoUnit < 10000000) {
		/* Showing price in lakhs if price is >= 1 lakh and < 1 crore */

		NewPriceWoUnit = (NewPriceWoUnit / 100000).toFixed(2);

		/* If decimals have 0 value then remove them */
		if (NewPriceWoUnit.split(".")[1] === "00") {
			NewPriceWoUnit = NewPriceWoUnit.split(".")[0];
		}

		NewPrice = NewPriceWoUnit + "L";
	} else if (NewPriceWoUnit >= 10000000) {
		/* Showing price in crores if price is >= 1 crore */

		NewPriceWoUnit = (NewPriceWoUnit / 10000000).toFixed(2);

		/* If decimals have 0 value then remove them */
		if (NewPriceWoUnit.split(".")[1] === "00") {
			NewPriceWoUnit = NewPriceWoUnit.split(".")[0];
		}

		NewPrice = NewPriceWoUnit + "Cr";
	} else {
		NewPrice = NumberToCurrencyPipe(NewPriceWoUnit);
	}

	return NewPrice;
};

export default preparePriceString;
