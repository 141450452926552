/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";
import { PublishPropertyThunk } from "../../../redux-magic/store";

/* Component Imports */

import {
	// IconButton,
	Alert,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { ObjectId } from "mongodb";
import PropertyPreview from "../../../components/modals/PropertyModal/sub-components/CreatePropertySubComponents/PropertyPreview";
import { Area, BHK, PropertyInterface } from "../../../redux-magic/sub-interfaces/property-interface";
import { BusinessProfileInterface, UserInterface } from "../../../redux-magic/sub-interfaces/sub-interfaces";
import BeegruButton from "../../common-components/buttons/BeegruButton";

/* Icon Imports */

// import CloseIcon from "@mui/icons-material/Close";

/* Styled Components */

const CustomDialog = styled(Dialog)(({ theme }) => ({
	padding: "1rem 1rem 1rem 1rem",
	borderRadius: "1rem",
	[theme.breakpoints.down("sm")]: {
		top: "5rem",
		bottom: "5rem",
	},
}));

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	padding: "0.5rem 1rem 0.5rem 1rem",
	fontSize: "1.25rem",
	fontWeight: 400,
	background: theme.palette.background.default,
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	padding: "1rem 1rem 1rem 1rem",
	background: theme.palette.background.default,
	[theme.breakpoints.down("sm")]: { padding: "0rem 0rem 0rem 0rem" },
}));

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-end",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
	padding: "1rem 1rem 1rem 0rem",
	background: theme.palette.background.default,
}));

const Snack = styled(Snackbar)(({ theme }) => ({
	[theme.breakpoints.up("sm")]: {
		marginTop: "2.5rem",
	},
}));

interface LocalPropertyInterface extends Omit<PropertyInterface, "created_by"> {
	created_by: {
		is_business_profile: boolean;
		business_profile_id: string | ObjectId | BusinessProfileInterface;
		user_id: string | ObjectId | UserInterface;
	};
}

const PublishButtonModal = ({
	propertyData,
	open,
	handleClose,
	dispatch,
	profile_context,
	isEditor,
	session,
}: {
	propertyData: LocalPropertyInterface;
	open: boolean;
	handleClose: any;
	dispatch: Function;
	profile_context: StateInterface["profile_context"];
	isEditor: boolean;
	session: any;
}) => {
	const theme = useTheme();

	const profile_link = propertyData
		? propertyData.created_by.is_business_profile
			? process.env.PRODUCTION_URL +
				`${(propertyData.created_by.business_profile_id as BusinessProfileInterface).business_profile_type}s/${(propertyData.created_by.business_profile_id as BusinessProfileInterface).slug}`
			: process.env.PRODUCTION_URL + `users/${(propertyData.created_by.user_id as UserInterface)._id}`
		: "";

	const [openPublishSnack, setOpenPublishSnack] = React.useState<boolean>(false);

	const [openUnpublishSnack, setOpenUnpublishSnack] = React.useState<boolean>(false);

	const [load, setLoad] = React.useState<boolean>(false);

	// const [propertyImage, setPropertyImage] = React.useState(
	// 	propertyData.images.map((image) => ({
	// 		...image,
	// 		url: typeof image.url === "object" && "regular" in image.url ? image.url.regular : image.url,
	// 	})),
	// );

	// React.useEffect(() => {
	// 	setPropertyImage(
	// 		propertyData.images.map((image) => ({
	// 			...image,
	// 			url: typeof image.url === "object" && "regular" in image.url ? image.url.regular : image.url,
	// 		})),
	// 	);
	// }, [propertyData]);

	const [propertyImage, setPropertyImage] = React.useState<{ url: string }[]>([]);

	const normalizeImage = (image: any) => ({
		...image,
		url: typeof image.url === "object" && "regular" in image.url ? image.url.regular : image.url,
	});

	React.useEffect(() => {
		if (!propertyData?.images?.length) return;

		// Check if priority image exists
		const priorityImage = propertyData.images.find((img) => img.priority === 0);
		console.log("prioprity image", priorityImage);

		if (priorityImage) {
			setPropertyImage([normalizeImage(priorityImage)]);
		} else {
			// Normalize all images if no priority image
			const normalizedImages = propertyData.images.map(normalizeImage);
			setPropertyImage(normalizedImages);
		}
	}, [propertyData]);

	const handleCloseSnack = () => {
		setOpenPublishSnack(false);
		setOpenUnpublishSnack(false);
	};

	const Publish = async () => {
		setLoad(true);
		await dispatch(
			PublishPropertyThunk({
				sessionId: session ? (session.session_id ? session.session_id : "") : "",
				phoneNumber: session ? session.user.dbUser.phone : "",
				countryCode: session ? session.user.dbUser.country_code : "",
				property_id: propertyData ? propertyData._id : "",
				status: status === "unpublish" ? "untouched" : "publish_requested",
				creator_is_business_profile: isEditor
					? profile_context.is_business_profile
					: propertyData.created_by.is_business_profile,
				creator_user_id: isEditor ? profile_context.user_id : (propertyData.created_by.user_id as UserInterface)._id,
				creator_business_profile_id: isEditor
					? profile_context.business_profile_id
					: (propertyData.created_by.business_profile_id as BusinessProfileInterface)._id,
			}),
		);
		handleClose();
		if (propertyData.published) {
			setOpenUnpublishSnack(true);
		} else {
			setOpenPublishSnack(true);
		}
		setLoad(false);
		setTimeout(() => {
			window.open(profile_link, "_self");
		}, 1000);
	};

	const status: string = propertyData && propertyData.status === "published" ? "unpublish" : "publish";

	return (
		<React.Fragment>
			<Snack
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={openUnpublishSnack}
				autoHideDuration={3000}
				onClose={handleCloseSnack}
			>
				<Alert
					onClose={handleCloseSnack}
					severity="success"
					sx={{ width: "100%", borderRadius: "0.75rem" }}
					variant="filled"
				>
					Property has been unpublished!
				</Alert>
			</Snack>

			<Snack
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={openPublishSnack}
				autoHideDuration={3000}
				onClose={handleCloseSnack}
			>
				<Alert
					onClose={handleCloseSnack}
					severity="success"
					sx={{ width: "100%", borderRadius: "0.75rem" }}
					variant="filled"
				>
					Thank you! Team Beegru will review and publish your listing soon.
				</Alert>
			</Snack>

			<CustomDialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth={true}
				fullScreen={useMediaQuery(theme.breakpoints.down("sm")) ? (!propertyData.published ? true : false) : false}
				disableScrollLock={true}
			>
				<CustomDialogTitle>
					{!propertyData.published
						? propertyData.is_ofb
							? "OFB card preview"
							: "Property card preview"
						: "Are you sure you want to unpublish this property?"}
				</CustomDialogTitle>

				{!propertyData.published ? (
					<CustomDialogContent>
						<PropertyPreview
							property_title={propertyData?.title ? propertyData?.title : ""}
							property_location={propertyData?.location.title ? propertyData?.location.title : ""}
							property_overview={propertyData?.description ? propertyData?.description : ""}
							property_transaction_type={propertyData?.transaction_type ? propertyData?.transaction_type : ""}
							property_category={propertyData?.property_category ? propertyData?.property_category : ""}
							property_type={propertyData?.property_type ? propertyData?.property_type : []}
							property_min_price={propertyData?.price?.min ? propertyData?.price?.min : 0}
							property_max_price={propertyData?.price?.max ? propertyData?.price?.max : 0}
							property_security_deposit={propertyData?.security_deposit.min ? propertyData?.security_deposit.min : 0}
							property_availability={propertyData?.availability ? propertyData?.availability : []}
							property_possession_date={propertyData?.possession_date ? propertyData?.possession_date : ""}
							property_unit_facing={
								propertyData?.unit_facing
									? propertyData?.unit_facing.map(
											(element: string) => element.charAt(0).toUpperCase() + element.slice(1).replace(/_/g, " "),
										)
									: []
							}
							property_age={propertyData?.property_age ? propertyData?.property_age : 0}
							property_furnishing_status={propertyData?.furnished as string[]}
							property_plot_dimensions={propertyData?.plot_dimensions ? propertyData?.plot_dimensions : []}
							property_area={propertyData?.area as Area}
							property_bhk={propertyData?.bhk as BHK}
							property_bathroom={propertyData?.bathroom as BHK}
							property_balconies={propertyData?.balconies as BHK}
							property_floors={propertyData?.floors as BHK}
							property_no_of_units={propertyData?.no_of_units as BHK}
							property_no_of_seats={propertyData?.no_of_seats as BHK}
						/>
						{/* <PropertyCardPublishPreview
							profile_context={profile_context}
							session={session}
							dispatch={dispatch}
							loading={false}
							_id={""}
							slug={""}
							transaction_type={propertyData ? propertyData.transaction_type : ""}
							property_type={propertyData ? propertyData.property_type : [""]}
							title={propertyData ? propertyData.title : ""}
							images={propertyImage[0] ? (propertyImage[0].url as string) : ""}
							property_status={propertyData ? propertyData.availability : [""]}
							possession_date={propertyData ? (propertyData.possession_date as string) : ""}
							location={propertyData ? (propertyData.location ? propertyData.location.title : "") : ""}
							developer={propertyData ? (propertyData.builder ? propertyData.builder.name : "") : ""}
							price={propertyData?.price}
							price_per_unit={propertyData?.price_per_unit}
							area={propertyData?.area}
							bhk={propertyData?.bhk}
							floors={propertyData?.floors}
							no_of_seats={propertyData?.no_of_seats}
							saved_content={propertyData?.saved_content}
							boosting={propertyData?.boost_information}
							created_by={propertyData?.created_by}
							show_contact_details={propertyData?.show_contact_details}
							published_status={propertyData?.status}
							preview={true}
						/> */}
					</CustomDialogContent>
				) : null}

				<CustomDialogActions
					sx={{
						flexDirection: !propertyData.published ? "row" : "row-reverse",
						justifyContent: !propertyData.published ? "flex-end" : "flex-start",
					}}
				>
					<BeegruButton
						flavor="primary"
						variant={!propertyData.published ? "text" : "outlined"}
						onClick={handleClose}
						name="cancel"
						type="button"
					>
						{!propertyData.published ? "Cancel" : "No"}
					</BeegruButton>

					{load ? (
						<CircularProgress
							size={24}
							sx={{
								marginTop: "1rem",
							}}
						/>
					) : (
						<BeegruButton
							flavor="primary"
							variant={!propertyData.published ? "contained" : "text"}
							onClick={() => {
								Publish();
							}}
							name="publish"
							type="button"
						>
							{!propertyData.published ? "Publish" : "Yes"}
						</BeegruButton>
					)}
				</CustomDialogActions>
			</CustomDialog>
		</React.Fragment>
	);
};

export default PublishButtonModal;
